
/**
 * Trigger a GA4 event.
 * @param {string} eventName - The name of the event.
 * @param {Object} eventParams - The parameters for the event.
 */
const triggerEvent = (eventName, eventParams) => {
    if (window.gtag) {
      window.gtag('event', eventName, eventParams);
    } else {
      console.warn('GA4 is not initialized.');
    }
  };
 
  /**
   * Trigger an event when the user clicks on the signup button.
   */
  export const signupClicked = () => {
    triggerEvent('signup_clicked', {
      event_category: 'Signup',
      event_label: 'Signup button clicked',
    });
  };
  
  /**
   * Trigger an event when the user completes the signup process.
   */
  export const signupCompleted = () => {
    triggerEvent('signup_completed', {
      event_category: 'Signup',
      event_label: 'Signup completed',
    });
  };
  
/**
   * Trigger an event when the user completes the signup process.
   */
    export const pricePageVisited = () => {
        triggerEvent('price_page_visited', {
          event_category: 'Price',
          event_label: 'Price page visited',
        });
      };