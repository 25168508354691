import React, { useEffect, useState } from 'react';

const BlogNavigation = ({ sections, handleScroll }) => {
  const [activeId, setActiveId] = useState('');

  useEffect(() => {
    if (sections.length > 0) {
      const defaultActiveId = sections[0].id;
      setActiveId(defaultActiveId);
      handleScroll(defaultActiveId);
    }
  }, [sections, handleScroll]);

  // Function to handle clicks on navigation links
  const handleLinkClick = (sectionId) => {
    setActiveId(sectionId);
    handleScroll(sectionId);
  };

  return (
    <nav id="toc">
      <ul className='list-unstyled'>
        {sections.map((section, index) => (
          <li key={index} className='agile__li'>
            <a
              href={`#${section.id}`}
              className={section.id === activeId ? 'active' : ''}
              onClick={(e) => {
                e.preventDefault();
                handleLinkClick(section.id);
              }}
            >
              {section.title}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default BlogNavigation;

