import React, { useRef, useEffect } from "react";
import BlogNavigation from "../../../componets/Blogs/BlogNavigation";
import BlogContent from "../../../componets/Blogs/BlogContent";


export default function WhatIsAgile() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sections = [
    {
      id: "section1",
      title: "Introduction",
      content: (
        <>
          <p class="padding-top-19">
            Agile project management is a popular methodology used in software
            development and other industries to manage projects in an iterative
            and flexible manner. This approach emphasizes collaboration,
            continuous improvement, and delivering high-quality products that
            meet the needs of customers.
          </p>
          <p>
            It is based on the idea that projects should be broken down into
            smaller, manageable pieces, and that the team should be empowered to
            make decisions and adapt to changing requirements.
          </p>
        </>
      ),
    },
    {
      id: "section2",
      title: "Agile History",
      content: (
        <>
          <p class="padding-top-19">
            The concept of Agile project management can be traced back to the
            1990s, when a group of software developers began to question
            traditional project management methods. They found that these
            methods were too rigid and did not allow for the flexibility and
            adaptability that was needed to deliver high-quality software in a
            rapidly changing environment.
          </p>
        </>
      ),
    },
    {
      id: "section3",
      title: "Agile Manifesto",
      content: (
        <>
          <p class="padding-top-19">
            In 2001, a group of 17 software developers came together to create
            the Agile Manifesto, which outlines the values and principles that
            guide Agile project management. The manifesto emphasizes individuals
            and interactions, working software, customer collaboration, and
            responding to change.
          </p>
        </>
      ),
    },
    {
      id: "section4",
      title: "Principles of Agile",
      content: (
        <>
          <p class="padding-top-19">
            The Agile Manifesto outlines 12 principles that guide Agile project
            management. These principles include:
          </p>
          <ul class="backlog-details refinement">
            <li>
              Our highest priority is to satisfy the customer through early and
              continuous delivery of valuable software.
            </li>
            <li>Working software is the primary measure of progress.</li>
            <li>
              Deliver working software frequently, with a preference to the
              shorter timescale.
            </li>
            <li>
              Business people and developers must work together daily throughout
              the project.
            </li>
            <li>
              Build projects around motivated individuals. Give them the
              environment and support they need, and trust them to get the job
              done.
            </li>
            <li>
              The most efficient and effective method of conveying information
              to and within a development team is face-to-face conversation.
            </li>
            <li>Working software is the primary measure of progress.</li>
            <li>
              Agile processes promote sustainable development. The sponsors,
              developers, and users should be able to maintain a constant pace
              indefinitely.
            </li>
            <li>
              Continuous attention to technical excellence and good design
              enhances agility.
            </li>
            <li>
              Simplicity--the art of maximizing the amount of work not done--is
              essential.
            </li>
            <li>
              The best architectures, requirements, and designs emerge from
              self-organizing teams.
            </li>
            <li>
              At regular intervals, the team reflects on how to become more
              effective, then tunes and adjusts its behavior accordingly.
            </li>
          </ul>
        </>
      ),
    },
    {
      id: "section5",
      title: "Agile Frameworks (Scrum and Kanban)",
      content: (
        <>
          <p class="padding-top-19">
            Scrum and Kanban are the two most popular Agile frameworks used in
            software development.
          </p>
          <h4 class="mb-3 f-s-20">What is Scrum?</h4>
          <p>
            Scrum is a framework that emphasizes time-boxed sprints and
            cross-functional teams. It involves a set of roles, ceremonies, and
            artifacts that help teams to deliver working software in short
            iterations. The roles in Scrum include the Product Owner, Scrum
            Master, and Development Team. The ceremonies include Sprint
            Planning, Daily Stand-ups, Sprint Review, and Sprint Retrospective.
            The artifacts include the Product Backlog, Sprint Backlog, and
            Increment.
          </p>
          <h4 class="mb-3 f-s-20">What is Kanban?</h4>
          <p>
            Kanban, on the other hand, is a framework that emphasizes continuous
            flow and visualizing work. It is based on the principles of
            just-in-time production and the Toyota Production System. Kanban
            involves visualizing work on a board and limiting work in progress
            to improve flow and reduce waste. It does not have time-boxed
            iterations like Scrum, but instead focuses on continuous delivery of
            working software.
          </p>
        </>
      ),
    },

    {
      id: "section6",
      title: "Agile Workflow",
      content: (
        <>
          <p class="padding-top-19">
            The Agile workflow is an iterative process that involves planning,
            executing, and delivering working software in short iterations. The
            Agile workflow typically involves the following steps:
          </p>
          <ul class="backlog-details list_unstyle">
            <li>
              <b>Planning:</b> In this phase, the team works with the product
              owner to create a product backlog, which is a prioritized list of
              features and requirements for the project. The team also creates a
              sprint backlog, which is a list of tasks that need to be completed
              during the sprint.
            </li>
            <li>
              <b>Execution:</b> During the execution phase, the team works to
              complete the tasks in the sprint backlog. This involves daily
              stand-ups, where the team discusses progress and any obstacles
              they are facing. The team also conducts regular sprint reviews to
              demonstrate the working software to the product owner and other
              stakeholders.
            </li>
            <li>
              <b>Delivery:</b> At the end of the sprint, the team delivers the
              working software to the product owner. This involves a sprint
              retrospective, where the team reflects on what went well and what
              could be improved in the next sprint.
            </li>
          </ul>
        </>
      ),
    },
    {
      id: "section7",
      title: "Conclusion",
      content: (
        <>
          <p className="padding-top-19">
            In a nutshell, Agile project management methodology is a flexible
            and collaborative approach to deliver software that is adaptable to
            changing requirements and meets the needs of their customers.
            Whether you are a software developer, project manager, or business
            owner, Agile project management can help you deliver high-quality
            products in a timely and efficient manner.
          </p>
          <p>
            <a href="/" target="_blank">
              Scrum Slate
            </a>{" "}
            is a powerful Agile project management tool that provides teams with
            advanced features and tools to plan, develop, and deliver
            high-quality products using the Scrum and Kanban frameworks. With{" "}
            <a href="/" target="_blank">
              Scrum Slate
            </a>
            , teams can manage and prioritize their work in progress using
            features such as the Sprint planner, backlog manager, and progress
            board.
          </p>
          <p>
            Advanced Reports and Analytics can help you to determine team
            velocity, find bottlenecks, and improve overall productivity. You
            can generate detailed reports on sprint performance, team velocity,
            and work item status. These reports can help you to identify trends,
            track progress over time, and make informed decisions about how to
            optimize your team's workflow.
          </p>
        </>
      ),
    },
  ];

  const scrollRef = useRef();

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <div className="aboutus_page padding-b-blog">
        <div className="container__land">
          <div className="text-center top-text mt-5">
            <h1>What is Agile Project Management</h1>
          </div>
        </div>
      </div>

      <div className="agile_card_section mb-2">
        <div className="container__land">
          <div className="meta-cards row mb-5">
            <div className="col-md-3">
              <h4>Table of Content</h4>
              <hr />
              <BlogNavigation sections={sections} handleScroll={handleScroll} />
            </div>
            <div className="col-md-9 agile__content" ref={scrollRef}>
              {sections.map((section, index) => (
                <BlogContent
                  key={index}
                  id={section.id}
                  title={section.title}
                  content={section.content}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
