import { Tooltip } from "@mui/material";
import React, { useState } from "react";
import { getTimeAgo, handleCopyFilePath } from "../../Utils/helpers";
import Mp3ThumbnailPlayIcon from "../../assets/images/mediaPageIcon/Audio.svg";
import DeleteIcon from "../../assets/images/mediaPageIcon/Deletes.svg";
import PlayIcon from "../../assets/images/mediaPageIcon/Polygon 15.svg";
import ShareIcon from "../../assets/images/mediaPageIcon/Shares.svg";
import Mp4ThumbnailPlayIcon from "../../assets/images/mediaPageIcon/mp4PlayIcon.svg";
import TranscriptImg from "../../assets/images/transcribe.svg";
import {
  OpenMediaFileDocument,
  handleDeleteMediaFile
} from "../../componets/comman/CommonFunction/MediaFileCommonFunction";
import ConfirmDialog from "../../componets/shared/ConfirmDialog";
import MediaPlayerModalWindow from "../../componets/shared/MediaPlayerModalWindow";

function MediaFileCard(props) {
  const {
    setProgress,
    setFileTitle,
    fileTitle,
    isGlobal,
    handleOpenFileDrawer,
    recordedFiles,
    refreshMediaFiles,

  } = props;
  const [openMediaPlayer, setOpenMediaPlayer] = useState(false);
  const [fileUrl, setFileUrl] = useState();
  const [open, setOpen] = useState(false);
  const [transcript, setTranscript] = useState();
  const [isHovered, setIsHovered] = useState(null);
  const handleOpenDeleteFile = (file) => {
    setFileUrl(file);
    setOpen(true);
  };

  const handleDelete = () => {
    handleDeleteMediaFile(
      setOpen,
      setProgress,
      refreshMediaFiles,
      fileUrl,
      true
    );
  };

  const handleViewFile = (file, type) => {
    OpenMediaFileDocument(
      file,
      type,
      setFileTitle,
      setTranscript,
      setProgress,
      setOpenMediaPlayer,
      setFileUrl
    );
  };
  const handleMouseEnter = (id) => {
    setIsHovered(id);
  };

  const handleMouseLeave = () => {
    setIsHovered(null);
  };
  const FileCard = ({ file, key: i, isViewFile }) => {
    return (
      <div
        className={`col-md-${isGlobal ? "3" : isViewFile ? "4" : "6"
          } mb-3 file_box_col`}
        key={i}
      >
        <div className="file_box ">
          <div
            className={`file_thumbnail ${!file?.thumbnail || file?.name?.endsWith(".mp3")
              ? "thumbnailBg"
              : ""
              }`}
            style={{
              backgroundImage: `url('${file?.thumbnail}')`,
            }}
            onClick={() => handleViewFile(file, "audioVideo")}
          >
            <img
              src={
                file?.name?.endsWith(".mp4")
                  ? Mp4ThumbnailPlayIcon
                  : Mp3ThumbnailPlayIcon
              }
              alt="file"
            />
          </div>

          <div className="file_details p-10 w_unset">
            <p
              className="mb-0 file_name add_cursor textEllipse f-14 capitalize-first"
              onClick={() =>
                isGlobal ? handleOpenFileDrawer(file) : undefined
              }
            >
              {file?.file_title}
            </p>
            <div className="d-flex justify-content-between align-items-center mt-10px">
              <p className="file_time_ago mb-0 f-12">
                {getTimeAgo(file?.file_created_at || file?.created_at)}{" "}
              </p>
              <p className="mb-0 record-file-actions">
                <Tooltip
                  title={
                    file?.file_metadata?.text_track
                      ? ""
                      : "Transcript not available"
                  }
                  arrow
                  placement="top"
                >
                  <img
                    src={TranscriptImg}
                    className={`mr-10  transcribeIcon ${isHovered === `transcript-${file?.id || i}`
                      ? "IconHover"
                      : ""
                      }`}
                    alt="Transcript"
                    onMouseEnter={() =>
                      handleMouseEnter(`transcript-${file?.id || i}`)
                    }
                    onClick={() =>
                      file?.file_metadata?.text_track
                        ? handleViewFile(file, "text")
                        : undefined
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                </Tooltip>
                <img
                  src={PlayIcon}
                  onClick={() => handleViewFile(file, "audioVideo")}
                  className={`mr-10  ${isHovered === `play-${file?.id || i}` ? "IconHover" : ""
                    }`}
                  alt="play"
                  onMouseEnter={() => handleMouseEnter(`play-${file?.id || i}`)}
                  onMouseLeave={handleMouseLeave}
                />
                <img
                  src={ShareIcon}
                  onClick={() => handleCopyFilePath(file)}
                  className={`mr-10  ${isHovered === `share-${file?.id || i}` ? "IconHover" : ""
                    }`}
                  alt="share"
                  onMouseEnter={() =>
                    handleMouseEnter(`share-${file?.id || i}`)
                  }
                  onMouseLeave={handleMouseLeave}
                />
                {!isViewFile && (
                  <img
                    src={DeleteIcon}
                    onClick={() => handleOpenDeleteFile(file)}
                    className={`mr-10  ${isHovered === `delete-${file?.id || i}` ? "IconHover" : ""
                      }`}
                    alt="delete"
                    onMouseEnter={() =>
                      handleMouseEnter(`delete-${file?.id || i}`)
                    }
                    onMouseLeave={handleMouseLeave}
                  />
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="project_files row  mt-3">
        {Array.isArray(recordedFiles) ? (
          recordedFiles
            .slice()
            .reverse()
            .map((file, i) => <FileCard file={file} key={i + 1} />)
        ) : (
          <FileCard file={recordedFiles} isViewFile={true} />
        )}
      </div>
      {openMediaPlayer && (
        <MediaPlayerModalWindow
          openMediaPlayer={openMediaPlayer}
          fileTitle={fileTitle}
          transcript={transcript}
          fileUrl={fileUrl}
          setOpenMediaPlayer={setOpenMediaPlayer}
        />
      )}
      <ConfirmDialog
        open={open}
        setOpen={setOpen}
        dialogTitle="Are you sure?"
        handleConfirmYes={handleDelete}
      />
    </>
  );
}

export default MediaFileCard;
