import { Box, Grid, Popover } from "@mui/material";
// import { functiondisable, functionenable } from "../../helpers/helper";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect, useState } from "react";
import low from "../../assets/images/low-priotity.svg";
import hg from "../../assets/images/hg-priotity.svg";
import md from "../../assets/images/md-priotity.svg";
import none from "../../assets/images/none-priority.svg";
import { useDispatch } from "react-redux";
import { editTask } from "../../Redux/Slice/Task";
import { toast } from "react-toastify";

export default function TaskPriority(props) {
  const {
    setTaskPriority,
    taskPriority,
    handleUpdateTask,
    isViewTask,
    isSmallScreen,
    islist,
    taskDetail,
    setLoading,
    isTableView,
    isKanban,
    setProgress,
    refreshPage,
    isUserProfile,
    projectId
  } = props;
  const dispatch = useDispatch();
  const [listViewPriority, setListViewPriority] = useState();
  const [task, setTask] = useState();
  useEffect(() => {
    setTask(taskDetail);
    setListViewPriority(taskDetail?.task_priority);
  }, isKanban ? [] : [taskDetail]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    if (taskDetail?.userPermission?.can_i_edit === false) {
      toast.info("You don't have access to update task", { position: "bottom-right" });
      return;
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const options = [
    { value: "None" },
    { value: "Low" },
    { value: "Medium" },
    { value: "High" },
  ];
  const handleSelectOption = (value) => {
    if (taskPriority === value) {
      return;
    }
    if (islist || isKanban) {
      if (value !== task?.task_priority) {
        setTask({
          ...task,
          task_priority: value,
        });
        const taskId = taskDetail.task_id;
        setListViewPriority(value);
        const payload = new FormData();
        payload.append(`priority`, value);
        setProgress(60);
        dispatch(editTask({ taskId, payload }))
          .then((resp) => {
            if (refreshPage) {
              refreshPage(
                taskDetail.task_id,
                projectId,
                null,
                null,
                null,
                null,
                resp?.payload?.task,
                "added change inline"
              );
            }
            setProgress(100);
            toast.success("Task priority updated successfully!");
            // setLoading(false);
          })
          .catch((err) => {
            console.log(err, "err");
          });
      }
    } else {
      setTaskPriority(value);
      handleUpdateTask("priority", value);
    }
    handleClose();
  };
  const handleScroll = () => {
    handleClose();
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const priorityIcon = (priority) => {
    return (
      <img
        src={
          priority === "Low"
            ? low
            : priority === "High"
              ? hg
              : priority === "Medium"
                ? md
                : none
        }
        className="w-7px"
      />
    );
  };
  return (
    <React.Fragment>
      {islist ? (
        <div
          className={` ${isSmallScreen
            ? `col-md-2 w-half ${!isTableView ? "mt-14" : ""}`
            : isUserProfile
              ? "user_task_priority"
              : !isTableView
                ? "task_items_priority"
                : ""
            } ${taskDetail?.userPermission?.can_i_edit === false ? "not-allowed-disable" : isUserProfile ? "cursor_default" : 'add_cursor'}`}
          onClick={isUserProfile ? undefined : handleClick}
        >
          <div
            className={`task_items_list ${listViewPriority === "High"
              ? "hg-priority"
              : listViewPriority === "Medium"
                ? "md-priority"
                : listViewPriority === "Low"
                  ? "low-priority"
                  : listViewPriority === "None"
                    ? "none-priority"
                    : "none-priority"
              } `}
          >
            <p className={`pw-100  text-center ${isTableView ? "mb-0" : ""}`}>
              {listViewPriority !== null ? (
                <span>
                  <img
                    src={
                      listViewPriority === "Low"
                        ? low
                        : listViewPriority === "High"
                          ? hg
                          : listViewPriority === "Medium"
                            ? md
                            : none
                    }
                    alt=""
                    className="w-7px me-2 "
                  />
                  {listViewPriority}
                </span>
              ) : (
                <>
                  <img src={none} alt="" className="w-7px me-2 " />
                  None
                </>
              )}
            </p>
          </div>
        </div>
      ) : isKanban ? (
        <div className={`status ${taskDetail?.userPermission?.can_i_edit === false ? "not-allowed-disable" : ''}`} onClick={handleClick}>
          <div
            className={`${listViewPriority === "High"
              ? "hg-icon"
              : listViewPriority === "Medium"
                ? "md-icon"
                : listViewPriority === "Low"
                  ? "low-icon"
                  : listViewPriority === null ||
                    listViewPriority === "None Priority" ||
                    listViewPriority === "None"
                    ? "none-icon"
                    : ""
              } ${taskDetail?.userPermission?.can_i_edit === false ? "cursor-not-allowed" : ''}`}
          ></div>
          <span
            className={`${listViewPriority === "High"
              ? "hg-priority"
              : listViewPriority === "Medium"
                ? "md-priority"
                : listViewPriority === "Low"
                  ? "low-priority"
                  : listViewPriority === null ||
                    listViewPriority === "None Priority" ||
                    listViewPriority === "None"
                    ? "none-priority"
                    : ""
              } ${taskDetail?.userPermission?.can_i_edit === false ? "cursor-not-allowed" : ''}`}
          >
            {listViewPriority !== null ? `${listViewPriority}` : "None"}
          </span>
        </div>
      ) : (
        <p
          onClick={handleClick}
          className={`view-task-priority ${taskPriority === "High"
            ? "hg-priority"
            : taskPriority === "Medium"
              ? "md-priority"
              : taskPriority === "Low"
                ? "low-priority"
                : taskPriority === null
                  ? "none-priority"
                  : "none-priority"
            } ${taskDetail?.userPermission?.can_i_edit === false && 'not-allowed-disable'}`}
        >
          {priorityIcon(taskPriority)} &nbsp;{" "}
          {taskPriority ? ` ${taskPriority}` : "None"}
          <FontAwesomeIcon
            className="ms-2 f-10 priority-svg"
            icon={faChevronDown}
          />{" "}
        </p>
      )}
      <Popover
        anchorEl={anchorEl}
        className={`${isViewTask ? "select-filter" : ""}`}
        id="account-menu"
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            border: "1px solid #EAE9F2",
            padding: "10px",
            mt: 0.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              // content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 91,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Grid container item spacing={1} lg={12} md={12}>
          <Grid item lg={12} className="m-auto ">
            <Box>
              {options &&
                options.map((option) => (
                  <MenuItem
                    key={option.value}
                    className={`${option.value === "Low"
                      ? "low-priority-color "
                      : option.value === "Medium"
                        ? "md-priority-color "
                        : option.value === "High"
                          ? "hg-priority-color "
                          : "none-priority-color "
                      } 
                      ${option.value === taskPriority ||
                        (option.value === "None" &&
                          (taskPriority === "" ||
                            taskPriority === null ||
                            listViewPriority === "" ||
                            listViewPriority === null)) ||
                        option.value === listViewPriority
                        ? "add_bg"
                        : ""
                      }`}
                    onClick={() => handleSelectOption(option.value)}
                  >
                    {priorityIcon(option.value)}
                    &nbsp;
                    {option.value}
                  </MenuItem>
                ))}
            </Box>
          </Grid>
        </Grid>
      </Popover>
    </React.Fragment>
  );
}
