import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { api } from "../../customAxios";

export const allProjects = createAsyncThunk(
  "allProjects",
  async ({ search, isFavorite }) => {
    const response = await api.get(`/projects?keyword=${search}`);
    if (isFavorite) {
      const favoriteProjects = response.data.filter(
        (project) => project.favorite === 1
      );
      return favoriteProjects;
    } else {
      const favoriteProjects = response.data.filter(
        (project) => project.favorite === 1
      );
      const UnfavoriteProjects = response.data.filter(
        (project) => project.favorite !== 1
      );
      const projectArray = [...favoriteProjects, ...UnfavoriteProjects];
      return projectArray;
    }
  }
);

export const archivedProjects = createAsyncThunk(
  "archivedProject",
  async ({ search }) => {
    const response = await api.get(`/archive-projects?keyword=${search}`);
    return response.data;
  }
);
export const createProject = createAsyncThunk(
  "createProject",
  async (payload, thunkAPI) => {
    try {
      const { isArchiveProject, formData } = payload;
      const response = await api.post(`/projects`, formData);
      const newProjectData = response.data;
      if (isArchiveProject) {
        thunkAPI.dispatch(archivedProjects({ search: "" }));
      } else {
        thunkAPI.dispatch(allProjects({ search: "" }));
      }
      return newProjectData;
    } catch (error) {
      console.log("Create project error:", error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const editProject = createAsyncThunk("editProject", async (id) => {
  const response = await api.get(`/projects/${id}/edit`);
  return response.data;
});
export const projectLists = createAsyncThunk("projectLists", async (id) => {
  const response = await api.get(`project/${id}/task-lists`);
  return response.data;
});

export const updateProject = createAsyncThunk(
  "updateProject",
  async ({ projectId, updatedData }, { rejectWithValue }) => {
    try {
      const response = await api.post(`/projects/${projectId}`, updatedData);
      return response;
    } catch (error) {
      console.log("check update error 3", error);
      return rejectWithValue(error.response);
    }
  }
);

export const getUsers = createAsyncThunk("getUsers", async (id) => {
  const response = await api.get(`/user/team-members`);
  return response.data;
});

export const getRoles = createAsyncThunk("getRoles", async (id) => {
  const response = await api.get(`/all-roles`);
  return response.data;
});
export const deleteProject = createAsyncThunk(
  "projects/deleteProject",
  async (id) => {
    const response = await api.post(`/projects/delete/${id}`);
    return { response, id };
  }
);

export const archiveProject = createAsyncThunk("archiveProject", async (id) => {
  const response = await api.post(`/project/${id}/archive`);
  return { response, id };
});

export const unarchiveProject = createAsyncThunk(
  "unarchiveProject",
  async (id) => {
    const response = await api.post(`/project/${id}/unarchive`);
    return { response, id };
  }
);

const projectSlice = createSlice({
  name: "Projects",
  initialState: {
    data: null,
    isLoading: false,
    projectView: [],
    projects: [],
    users: [],
    roles: [],
    lists: [],
    errors: null,
  },
  reducers: {
    updatedProjectData(state, action) {
      const { data, projectId, users } = action.payload;
      const index = state.projects.findIndex(
        (project) => project.id === projectId
      );
      if (index !== -1) {
        state.projects[index] = {
          ...state.projects[index],
          name: data.name,
          description: data.desc,
          users: users,
        };
      }
    },
    refreshFavoriteProjects(state, action) {
      const { projectId, favoriteValue } = action.payload;
      const index = state.projects.findIndex(
        (project) => project.id === projectId
      );
      if (index !== -1) {
        const updatedProject = {
          ...state.projects[index],
          favorite: favoriteValue,
        };

        if (favoriteValue === 1) {
          // Remove the project from its current position
          state.projects.splice(index, 1);
          // Add it to the top
          state.projects.unshift(updatedProject);
        } else {
          // Just update the project in its current position
          state.projects[index] = updatedProject;
        }
      }
    },

    refreshProjectList(state, action) {
      const projectId = action.payload;
      const refreshData = state.projects.filter(
        (project) => project.id !== projectId
      );
      state.projects = refreshData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(allProjects.pending, (state, action) => {
      state.projects = [];
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(allProjects.fulfilled, (state, action) => {
      state.projects = action.payload;
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(allProjects.rejected, (state, action) => {
      state.projects = [];
      state.isLoading = false;
    });

    builder.addCase(archivedProjects.pending, (state, action) => {
      state.projects = [];
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(archivedProjects.fulfilled, (state, action) => {
      state.projects = action.payload;
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(archivedProjects.rejected, (state, action) => {
      state.projects = [];
      state.isLoading = false;
    });
    builder.addCase(createProject.fulfilled, (state, action) => {
      if (action?.payload?.message) {
        state.projects = action.payload;
        state.errors = null;
      } else {
        state.errors = action.payload;
      }
    });

    builder.addCase(editProject.fulfilled, (state, action) => {
      state.projectView = action.payload;
      state.errors = null;
    });
    builder.addCase(projectLists.fulfilled, (state, action) => {
      state.lists = action.payload;
      state.errors = null;
    });

    builder.addCase(updateProject.fulfilled, (state, action) => {});
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.users = action.payload;
    });

    builder.addCase(getRoles.fulfilled, (state, action) => {
      state.roles = action.payload;
    });
    builder.addCase(deleteProject.fulfilled, (state, action) => {
      const id = action.payload.id;
      const remainData = state.projects.filter((project) => project.id !== id);
      state.projects = remainData;
    });
    builder.addCase(archiveProject.fulfilled, (state, action) => {
      const id = action.payload.id;
      const remainData = state.projects.filter((project) => project.id !== id);
      state.projects = remainData;
    });
    builder.addCase(unarchiveProject.fulfilled, (state, action) => {
      const id = action.payload.id;
      const remainData = state.projects.filter((project) => project.id !== id);
      state.projects = remainData;
    });
  },
});
export const { updatedProjectData } = projectSlice.actions;
export const { refreshProjectList } = projectSlice.actions;
export const { refreshFavoriteProjects } = projectSlice.actions;
export default projectSlice.reducer;
