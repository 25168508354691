import { faCopy, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Typography } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import AiModalWindow from "../../../../componets/comman/AI/AiModalWindow";
import CustomTextEditor from "../../../../componets/comman/CustomTextEditor";
import FileViewBox from "../../../../componets/comman/Projects/FileLightBox";
import ViewTaskLoader from "../../../../componets/pageLoaders/projectLoaders/ViewTaskLoader";
import { api } from "../../../../customAxios";
import { handleCopyText } from "../../../../Utils/helpers";
import TaskAttachments from "../Tasks/TaskAttachments";
import TaskHistory from "../Tasks/TaskHistory";
import Defects from "./Defect";
import ResultComment from "./ResultComment";
import ViewTestCaseSidebar from "./ViewTestCaseSidebar";
import LinkItem from "../TestCases/LinkItem";

function TestCaseViewSideDrawer(props) {
  const { testCaseId, setProgress } = props;
  const [isTestCaseNameEditing, setIsTestCaseNameEditing] = useState(false);
  const [isDescription, setIsDescription] = useState(false);
  const { projectId } = useParams();
  const [projectLabels, setProjectLabels] = useState([]);
  const [testCaseDetail, setTestCaseDetail] = useState({});
  const [task_description, setTask_description] = useState("");
  const [prevTitle, setPrevTitle] = useState("");
  const [editTitle, setEditTitle] = useState(false);
  const [editParagraph, setEditParagraph] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingLoader, setIsLoadingLoader] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [projectUsers, setProjectUsers] = useState([]);
  const [isEditDescription, setIsEditDescription] = useState(false);
  const [taskHistory, setTaskHistory] = useState([]);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [attachmentFile, setAttachmentFile] = useState();
  const headingRef = useRef(null);
  const [state, setState] = useState({
    open: false,
    type: "",
    desc: "",
    newDesc: "",
  });

  useEffect(() => {
    if (isTestCaseNameEditing && headingRef.current) {
      headingRef.current.focus();
      document.execCommand("selectAll", false, null);
      document.getSelection().collapseToEnd();
    }
  }, [isTestCaseNameEditing]);
  useEffect(() => {
    setIsLoading(true);
    api
      .get(`/test-cases/${testCaseId}`)
      .then((response) => {
        setTestCaseDetail(response?.data?.testCase);
        setProjectLabels(response?.data?.testCase?.testCaseLabels);
        setProjectUsers(response?.data?.project?.users);
        setTask_description(testCaseDetail?.description);
        setPrevTitle(response?.data?.testCase?.name);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [testCaseId]);

  const handleUpdateTestCaseName = (e) => {
    const newHeading = e.target.textContent.trim();
    const newHeadingWords = newHeading.split(/\s+/);
    const prevTitleWords = prevTitle.split(/\s+/);
    const isDifferent = newHeadingWords.some((word, index) => {
      return word !== prevTitleWords[index];
    });

    if (newHeading === "" || !isDifferent) {
      setIsTestCaseNameEditing(false);
      e.target.textContent = prevTitle;
      return;
    }

    setTestCaseDetail((prevValues) => ({
      ...prevValues,
      name: newHeading,
    }));
    handleUpdateTestCase(newHeading);
  };

  const handleBlur = (e) => {
    handleUpdateTestCaseName(e);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleUpdateTestCaseName(e);
    }
  };

  const handleUpdateTestCase = (value) => {
    setProgress(30);
    var payload = {};
    if (isTestCaseNameEditing) {
      payload.name = value;
    } else if (isDescription) {
      setIsLoadingLoader(true);
      payload.desc = task_description?.task_description;
    }

    api
      .post(`/test-cases/${testCaseId}`, payload)
      .then((response) => {
        setProgress(60);
        if (isTestCaseNameEditing) {
          toast.success("Test case name changed successfully");
        } else if (isDescription) {
          toast.success("Test case description changed successfully");
        }
        const data = {
          testCaseId: response?.data?.id,
          projectId: projectId,
          updatedTestCase: response?.data,
          executionId: response?.data?.execution_id,
          action: "testCaseUpdate",
          updateName: true,
          listId: response?.data?.tc_lists_id,
        };
        navigate(location.pathname, { state: { data } });
        setTestCaseDetail((prevValues) => ({
          ...prevValues,
          description: isDescription
            ? task_description.task_description
            : task_description?.task_description || testCaseDetail?.task_desc,
          name: response?.data?.name,
        }));
        setPrevTitle(response?.data?.name);
        setEditParagraph(true);
        setEditTitle(false);
        setIsDescription(false);
        setIsTestCaseNameEditing(false);
      })
      .finally(() => {
        setIsLoadingLoader(false);
        setProgress(100);
      });
  };

  const closeTitle = () => {
    setIsTestCaseNameEditing(false);
  };
  const closeDescription = () => {
    setIsDescription(false);
  };

  const handleOpenEditor = () => {
    setIsDescription(true);
    setIsEditDescription(true);
  };

  const handleDoubleClick = () => {
    setPrevTitle(testCaseDetail?.name);
    setIsTestCaseNameEditing(true);
    setEditTitle(false);
  };

  const handleCloseDrawer = () => {
    const currentUrl = location.pathname;
    const newUrl = currentUrl.replace(`/panel/${testCaseId}/${projectId}`, "");
    navigate(newUrl);
    closeTitle();
    closeDescription();
  };

  const handleGetEditorState = (currentEditorState) => {
    setTask_description(currentEditorState);
  };

  const handleTaskHistory = () => {
    // setIsLoadingHistory(true);
    // api
    //   .get(`/task/${taskId}/history`)
    //   .then((response) => {
    //     const filteredHistory = response?.data?.activities?.filter(
    //       (history) => history.log_desc !== ""
    //     );
    //     setTaskHistory(filteredHistory);
    //     setIsLoadingHistory(false);
    //   })
    //   .catch((error) => {
    //     setIsLoadingHistory(false);
    //   });
  };
  const handleGetFile = (file) => {
    setAttachmentFile(file);
  };
  const [isOpenLightbox, setIsOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState([]);
  const handleCloseLightbox = () => {
    setIsOpenLightbox(false);
    setSelectedImage([]);
  };
  const handleImageClick = (file) => {
    const viewFile = {
      src: file,
      alt: file,
    };
    setSelectedImage([viewFile]);
    setIsOpenLightbox(true);
  };

  const handleHTMLContentClick = (event) => {
    event.preventDefault();
    const target = event.target;
    if (target.tagName === "IMG") {
      handleImageClick(target.src);
    }
  };
  const handleOpen = (type, desc) => {
    if (!desc) {
      toast.error("Please add description in task first.");
      return;
    }
    setState((preState) => ({
      ...preState,
      type: type,
      open: true,
      desc: desc,
    }));
  };
  return (
    <>
      <Drawer
        transitionDuration={{ enter: 500, exit: 500 }}
        variant="temporary"
        anchor="right"
        className="sub-task-drawer test-case-drawer"
        open={testCaseId ? true : false}
        onClose={handleCloseDrawer}
        style={{ zIndex: "1000" }}
      >
        <div className="sub-task">
          <Grid className="d-flex align-center ml-20 mt-20 close-drawer">
            <Typography
              onClick={handleCloseDrawer}
              onKeyPress={handleCloseDrawer}
              tabIndex={0}
              className="d-flex align-center cursor-hover"
            >
              <svg
                width="18px"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#808191"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            </Typography>
            <Typography
              className="f-20 font-weight-600"
              onClick={handleCloseDrawer}
            >
              Close
            </Typography>
          </Grid>
          {isLoading ? (
            <ViewTaskLoader isTestCase={true} />
          ) : (
            <div className="pt-2">
              <div className="container-fluid pr-0">
                <div
                  className="row responsive__row viewTaskDrwar"
                  style={{ width: "100%" }}
                >
                  <div className="resposive__col-8 col-md-8">
                    <div className="sub-task-content-top">
                      <div className="heading view-task">
                        <div
                          className="d-flex mt-2 hover_head"
                          onMouseOver={() => {
                            if (!isTestCaseNameEditing) setEditTitle(true);
                          }}
                          onMouseLeave={() => {
                            if (!isTestCaseNameEditing) setEditTitle(false);
                          }}
                        >
                          <div className={`view_task_icon`}>
                            {testCaseDetail.id}
                          </div>
                          <h4
                            ref={headingRef}
                            contentEditable={isTestCaseNameEditing}
                            suppressContentEditableWarning={true}
                            onBlur={handleBlur}
                            onKeyDown={handleKeyDown}
                            className={`capitalize-first w_96 ${isTestCaseNameEditing ? "editable_heading" : ""
                              }`}
                          >
                            {prevTitle}
                            {editTitle && (
                              <>
                                <FontAwesomeIcon
                                  icon={faPencil}
                                  color="gray"
                                  className="action_cursor_head ms-2 fs-16 top-96"
                                  onClick={handleDoubleClick}
                                />
                                <FontAwesomeIcon
                                  icon={faCopy}
                                  color="gray"
                                  className="action_cursor_head ms-2 fs-16 top-96 copy"
                                  onClick={() =>
                                    handleCopyText(testCaseDetail.name, "Task Name")
                                  }
                                />
                              </>
                            )}
                          </h4>
                        </div>
                        <div className="main-description">
                          {isDescription ? (
                            <div className="description_head">
                              <h4 className="text-capitalize desc_heading">
                                Description
                              </h4>
                              <div className="edit__wiki__box_2 p-0 text-task editorviewtask">
                                <CustomTextEditor
                                  handleGetEditorState={handleGetEditorState}
                                  description={testCaseDetail.description}
                                  testCaseId={testCaseId}
                                  isEditDescription={isEditDescription}
                                  placeholder={"Enter test case detail"}
                                  isUploadTaskView={true}
                                  projectUser={projectUsers}
                                  handleGetFile={handleGetFile}
                                  setProgress={setProgress}
                                />
                              </div>
                              <div className="description_control_btn ">
                                <button
                                  className="btn btn-cancel btn-sm"
                                  onClick={closeDescription}
                                >
                                  Cancel
                                </button>
                                <button
                                  disabled={isLoadingLoader}
                                  className={`btn btn-primary btn-sm ms-3 ${isLoadingLoader ? "pd-save-desc-load" : ""
                                    }`}
                                  onClick={handleUpdateTestCase}
                                >
                                  {isLoadingLoader ? (
                                    <ClipLoader
                                      color="#ffffff"
                                      loading={isLoadingLoader}
                                      size={20}
                                    />
                                  ) : (
                                    "Save"
                                  )}
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="text-div"
                              onMouseOver={() => setEditParagraph(true)}
                              onMouseLeave={() => setEditParagraph(true)}
                            >
                              <div className="d-flex align-items-center description_head hover_head">
                                <h4 className="text-capitalize desc_heading">
                                  Description
                                </h4>
                                {editParagraph && (
                                  <div className="d-flex align-items-center">
                                    <>
                                      <Dropdown className="desc-ai">
                                        <Dropdown.Toggle>
                                          {/* <img src={AI} /> */}
                                          {/* <div className="img"></div>&nbsp;<span></span> */}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            onClick={() =>
                                              handleOpen(
                                                "low",
                                                testCaseDetail.task_desc
                                              )
                                            }
                                          >
                                            Low Details
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() =>
                                              handleOpen(
                                                "medium",
                                                testCaseDetail.task_desc
                                              )
                                            }
                                          >
                                            Medium Details
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() =>
                                              handleOpen(
                                                "high",
                                                testCaseDetail.task_desc
                                              )
                                            }
                                          >
                                            High Details
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                      <FontAwesomeIcon
                                        icon={faPencil}
                                        color="gray"
                                        className="action_cursor fs-16"
                                        onClick={handleOpenEditor}
                                      />
                                    </>
                                  </div>
                                )}
                              </div>
                              <div
                                className={`desc-b add-cursor-to-image ${!testCaseDetail.description ? "pd-bottom" : ""
                                  }`}
                              >
                                {showMore ||
                                  testCaseDetail.description?.length <= 1000 ? (
                                  <span
                                    className={`font-weight-400 description-preview ${!testCaseDetail.description
                                      ? "no-description"
                                      : ""
                                      }`}
                                    style={{
                                      whiteSpace: "initial",
                                      color: "#000000",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        testCaseDetail.description &&
                                          testCaseDetail.description[8]
                                          ? testCaseDetail.description
                                          : "No description",
                                    }}
                                    onClick={handleHTMLContentClick}
                                  />
                                ) : (
                                  <span
                                    className={`font-weight-400 description-preview ${!testCaseDetail.task_desc
                                      ? "no-description"
                                      : ""
                                      }`}
                                    style={{ whiteSpace: "initial" }}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        testCaseDetail.description &&
                                          testCaseDetail.description[8]
                                          ? testCaseDetail.description.substr(
                                            0,
                                            1000
                                          ) + "..."
                                          : "No description",
                                    }}
                                    onClick={handleHTMLContentClick}
                                  />
                                )}

                                {(testCaseDetail.description
                                  ? testCaseDetail.description.length
                                  : 0) > 1000 && (
                                    <span
                                      tabIndex={0}
                                      onKeyPress={() => setShowMore(!showMore)}
                                      className="link-color cursor-hover text-decoration-none d-block f-14 font-weight-600"
                                      onClick={() => setShowMore(!showMore)}
                                    >
                                      {" "}
                                      {showMore ? "Show less" : "Show more"}
                                    </span>
                                  )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="m-content">
                      <TaskAttachments
                        testCaseDetail={testCaseDetail}
                        setTestCaseDetail={setTestCaseDetail}
                        isSubTask={true}
                        projectUser={projectUsers}
                        attachmentFile={attachmentFile}
                        setProgress={setProgress}
                      />
                      <LinkItem />
                    </div>
                  </div>
                  <div className="resposive__col-4 col-md-4">
                    <div className="heading view-task">
                      <div
                        className="d-flex mt-2 hover_head"
                        onMouseOver={() => {
                          if (!isTestCaseNameEditing) setEditTitle(true);
                        }}
                        onMouseLeave={() => {
                          if (!isTestCaseNameEditing) setEditTitle(false);
                        }}
                      >
                        <div className={`view_task_icon `}>
                          {testCaseDetail?.id}
                        </div>
                        <h4
                          ref={headingRef}
                          contentEditable={isTestCaseNameEditing}
                          suppressContentEditableWarning={true}
                          onBlur={handleBlur}
                          onKeyDown={handleKeyDown}
                          className={`capitalize-first w_96 ${isTestCaseNameEditing ? "editable_heading" : ""
                            }`}
                        >
                          {prevTitle}
                          {editTitle && (
                            <>
                              <FontAwesomeIcon
                                icon={faPencil}
                                color="gray"
                                className="action_cursor_head ms-2 fs-16 top-96"
                                onClick={handleDoubleClick}
                              />
                              <FontAwesomeIcon
                                icon={faCopy}
                                color="gray"
                                className="action_cursor_head ms-2 fs-16 top-96 copy"
                                onClick={() =>
                                  handleCopyText(testCaseDetail.name, "Task Name")
                                }
                              />
                            </>
                          )}
                        </h4>
                      </div>
                    </div>
                    <ViewTestCaseSidebar
                      testCaseDetail={testCaseDetail}
                      setTestCaseDetail={setTestCaseDetail}
                      projectUser={projectUsers}
                      projectLabels={projectLabels}
                      setProgress={setProgress}
                      isTestCase={true}
                    />

                    {state.open && (
                      <AiModalWindow
                        state={state}
                        setState={setState}
                        setTestCaseDetail={setTestCaseDetail}
                        testCaseDetail={testCaseDetail}
                      />
                    )}
                  </div>
                  <div className="col-md-12">
                    <div className="m-content">
                      <div className="mt-4 mb-5">
                        <div className="row">
                          <div className="col-md-12">
                            <nav className="nav_prob">
                              <div
                                className="nav nav-tabs"
                                id="nav-tab"
                                role="tablist"
                              >
                                <button
                                  className="nav-link active"
                                  id="nav-home-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#nav-home"
                                  type="button"
                                  role="tab"
                                  aria-controls="nav-home"
                                  aria-selected="true"
                                >
                                  <b>Results & Comments</b>
                                </button>
                                <button
                                  className="nav-link"
                                  id="nav-profile-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#nav-profile"
                                  type="button"
                                  role="tab"
                                  aria-controls="nav-profile"
                                  aria-selected="false"
                                  onClick={handleTaskHistory}
                                >
                                  <b>Defect</b>
                                </button>
                                <button
                                  className="nav-link mr-0"
                                  id="nav-contact-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#nav-contact"
                                  type="button"
                                  role="tab"
                                  aria-controls="nav-contact"
                                  aria-selected="false"
                                >
                                  <b>History</b>
                                </button>
                              </div>
                            </nav>
                            <div
                              className="tab-content mt-15"
                              id="nav-tabContent"
                            >
                              <div
                                className="tab-pane fade show active editorviewtask commenteditor"
                                id="nav-home"
                                role="tabpanel"
                                aria-labelledby="nav-home-tab"
                              >
                                <ResultComment results={testCaseDetail?.testCaseResult} projectUsers={projectUsers} />
                              </div>
                              <div
                                className="tab-pane fade"
                                id="nav-profile"
                                role="tabpanel"
                                aria-labelledby="nav-profile-tab"
                              >
                                <Defects />
                              </div>
                              <div
                                className="tab-pane fade mt-3"
                                id="nav-contact"
                                role="tabpanel"
                                aria-labelledby="nav-contact-tab"
                              >
                                <TaskHistory
                                  taskHistory={taskHistory}
                                  isLoadingHistory={isLoadingHistory}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <FileViewBox
                  images={selectedImage}
                  isOpen={isOpenLightbox}
                  onClose={handleCloseLightbox}
                />
              </div>
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
}
export default TestCaseViewSideDrawer;
