import CloseIcon from "@mui/icons-material/Close";
import { Box, Checkbox, ListItemText, MenuItem } from "@mui/material";
import React, { useState, useEffect } from "react";
import CustomAvatar from "./CustomAvatar";
import unassign from "../../../assets/images/Group 1129@2x.png";

function CustomPopOverMenu(props) {
  const { allUsers, handleChange, setSelectedUsers, selectedUsers, isMultiple, isAddUnassign } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  useEffect(() => {
    setSelectAllChecked(selectedUsers?.length === allUsers?.length && allUsers?.length > 0);
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleSelectAllChange = () => {
    if (selectedUsers?.length === allUsers?.length) {
      setSelectedUsers([]);
      handleChange([])
      setSelectAllChecked(false);
    } else {
      setSelectedUsers(allUsers?.map((user) => user.id));
      handleChange(allUsers)
      setSelectAllChecked(true);
    }
    setSearchTerm("");

  };
  const handleUserClick = (user) => {
    handleChange(user);
    setSearchTerm("");
  };
  const filteredUsers = allUsers?.filter((user) => {
    const userName = user.name || user.label || '';

    return userName.toLowerCase().includes(searchTerm.toLowerCase());
  });




  return (
    <>
      {isMultiple && (
        <Box className="f-14 select-all-user-box">
          <Checkbox
            checked={selectAllChecked}
            onChange={handleSelectAllChange}
          />
          {selectedUsers?.length === allUsers?.length ? 'Deselect All' : `Select All (${allUsers?.length})`}
        </Box>
      )}
      <Box className="user-search-box">
        <Box className="user-search-field-wrap">
          <input
            onChange={handleSearchChange}
            placeholder="Search"
            value={searchTerm}
            className="custom-user-search"
          />
          {searchTerm?.length > 0 && <CloseIcon onClick={() => setSearchTerm("")} className="add_cursor ms-auto" />}
        </Box>
      </Box>
      <Box className="custom-menu-box">
        {isAddUnassign && <MenuItem value={0}
          onClick={() => handleUserClick()}
        >
          <span className="me-2 unassigned_image unassigned-create-task">
            <img
              src={unassign}
              className="unassign-img"
              height={16}
            />
          </span>
          <ListItemText primary="Unassigned" />
        </MenuItem>}

        {filteredUsers &&
          filteredUsers.map((user, index) => (
            <MenuItem
              key={index}
              value={user.id}
              onClick={() => handleUserClick(user)}
            >
              {user.image ? (
                <div
                  className="size_cover userImages avatar__border me-2"
                  style={{
                    backgroundImage: `url('${user.image}')`,
                  }}
                ></div>
              ) : (
                <span className="me-2">
                  <CustomAvatar
                    name={user.name || ""}
                    backgroundColor={user?.profile_color || ""}
                  />
                </span>
              )}
              <ListItemText primary={user.name} />
              {isMultiple && <Checkbox
                checked={selectedUsers?.includes(user.id)}
                value={user.id}
              />}
            </MenuItem>
          ))}
      </Box>
    </>
  );
}

export default CustomPopOverMenu;
