import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrivatePortal from './pages/Private';
import PublicPortal from './pages/Public';
import FileView from './pages/Public/FileView/FileView';
import FileViewMux from './pages/Public/FileView/FileViewMux';
import FileViewMuxUpload from './pages/Public/FileView/FileViewMuxUpload';
import FileViewPlayback from './pages/Public/FileView/FileViewPlayback';

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function App() {
  const isAuthenticated = localStorage.getItem("token");
  const [progress, setProgress] = useState(0);
  return (
    <>
     <Elements stripe={stripePromise}>
      <Routes>
        <Route
          path="/*"
          element={
            <AuthGuardRedirect redirectTo="/user/dashboard">
                <PublicPortal />
            </AuthGuardRedirect>
          }
        />
        <Route
          path="user/*"
          element={
            <RequireAuth redirectTo="/signin">
              <PrivatePortal />
            </RequireAuth>
          }
        />
        <Route
          path="file-view"
          element={
            <React.Suspense fallback="">
              <FileView />
            </React.Suspense>
          }
        />
        <Route
          path="share/:id"
          element={
            <FileViewPlayback progress={progress} setProgress={setProgress} />
          }
        />
        <Route path="share-new/:id" element={<FileView />} />
        <Route
          path="file-view-new"
          element={
            <React.Suspense fallback="">
              <FileViewMux />
            </React.Suspense>
          }
        />
        <Route
          path="file-view-upload"
          element={
            <React.Suspense fallback="">
              <FileViewMuxUpload />
            </React.Suspense>
          }
        />
      </Routes>
      <ToastContainer 
        hideProgressBar
      />
      </Elements>

    </>
  );
}

export default App;

function RequireAuth({ children, redirectTo }) {
  const isAuthenticated = localStorage.getItem("token");
  if (!isAuthenticated) {
    const currentPath = window.location.pathname + window.location.search;
    const redirectPath = `${redirectTo}?redirectTo=${encodeURIComponent(currentPath)}`;
    return <Navigate to={redirectPath} />;
  }
  return isAuthenticated ? children : null;
}
function AuthGuardRedirect({ children, redirectTo }) {
  const isAuthenticated = localStorage.getItem("token");
  return isAuthenticated ? <Navigate to={redirectTo} /> : children;
}
