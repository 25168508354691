import React, { useRef, useEffect } from "react";
import BlogNavigation from "../../../componets/Blogs/BlogNavigation";
import BlogContent from "../../../componets/Blogs/BlogContent";

export default function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sections = [
    {
      id: "section1",
      title: "Introduction",
      content: (
        <>
          <p class="padding-top-19">
            Scrum Slate is operated by Riemann System.
          </p>
          <p>
            For any questions about this Privacy Policy, you can contact us at
            [Contact Information].
          </p>
        </>
      ),
    },
    {
      id: "section2",
      title: "Data Collection",
      content: (
        <>
          <p class="padding-top-19">
            <b>Account Information:</b> Name, email address, and password when
            you create an account.
          </p>
          <p>
            <b>Usage Information:</b> Details of how you use Scrum Slate,
            including tasks created, team interactions, and other app activity.
          </p>
          <p>
            <b>Device Information:</b> Device type, operating system, and IP
            address for analytics and security purposes.
          </p>
        </>
      ),
    },
    {
      id: "section3",
      title: "Data Usage",
      content: (
        <>
          <ul class="backlog-details refinement">
            <li>
              <b>Providing the App:</b> To operate, maintain, and enhance Scrum
              Slate.
            </li>
            <li>
              <b>Communication:</b> To respond to inquiries and provide customer
              support.
            </li>
            <li>
              <b>Customization:</b> To personalize your experience.
            </li>
            <li>
              <b>Analytics:</b> To analyze user behavior and improve our
              services.
            </li>
          </ul>
        </>
      ),
    },
    {
      id: "section4",
      title: "Data Sharing",
      content: (
        <>
          <p class="padding-top-19">
            We do not sell your data. However, we may share information:
          </p>
          <ul class="backlog-details refinement">
            <li>
              <b>With Third-Party Service Providers:</b> To perform functions
              such as data hosting, email communication, and analytics.
            </li>
            <li>
              <b>For Legal Reasons:</b> To comply with legal obligations or to
              protect our rights and safety.
            </li>
          </ul>
        </>
      ),
    },
    {
      id: "section5",
      title: "User Rights",
      content: (
        <>
          <p class="padding-top-19">
            You have certain rights regarding your data:
          </p>
          <ul class="backlog-details refinement">
            <li>
              <b>Access and Correction:</b> You can review and update your
              personal information.
            </li>
            <li>
              <b>Deletion:</b> You can request the deletion of your data.
            </li>
            <li>
              <b>Consent Withdrawal:</b> You can withdraw your consent for data
              processing at any time.
            </li>
          </ul>
        </>
      ),
    },

    {
      id: "section6",
      title: "Policy Updates",
      content: (
        <>
          <p class="padding-top-19">
            You have certain rights regarding your data:
          </p>
          <ul class="backlog-details refinement">
            <li>We may update this policy from time to time.</li>
            <li>
              We will notify you of changes by email or through Scrum Slate.
            </li>
          </ul>
        </>
      ),
    },
    {
      id: "section7",
      title: "Contact Information",
      content: (
        <>
          <p className="padding-top-19">
          For any questions or concerns about this Privacy Policy, please contact us at:
          </p>
          <p>
            041 7866300</p>
        </>
      ),
    },
  ];

  const scrollRef = useRef();

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <div className="aboutus_page padding-b-blog">
        <div className="container__land">
          <div className="text-center top-text mt-5">
            <h1>Privacy Policy</h1>
          </div>
        </div>
      </div>

      <div className="agile_card_section mb-2">
        <div className="container__land">
          <div className="meta-cards row mb-5">
            <div className="col-md-3">
              <h4>Table of Content</h4>
              <hr />
              <BlogNavigation sections={sections} handleScroll={handleScroll} />
            </div>
            <div className="col-md-9 agile__content" ref={scrollRef}>
              {sections.map((section, index) => (
                <BlogContent
                  key={index}
                  id={section.id}
                  title={section.title}
                  content={section.content}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
