import React, { useEffect } from 'react';
import { SlideshowLightbox } from 'lightbox.js-react';
import 'lightbox.js-react/dist/index.css';
function FileViewBox({ images, isOpen, onClose }) {
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible';
        }

        return () => {
            document.body.style.overflow = 'visible';
        };
    }, [isOpen]);

    return (
        <SlideshowLightbox
            images={images}
            open={isOpen}
            onClose={onClose}
            iconColor={"#fff"}
            fullScreen
            showThumbnailIcon={false}
            showControls={false}
            leftArrowClassname='prevControl'
            rightArrowClassname='nextControl'
            showMagnificationIcons={true}
            showFullScreenIcon={true}

        />
    );
}

export default FileViewBox;
