import { Popover } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CustomAvatar from "./Task/CustomAvatar";
import CustomPopOverMenu from "./Task/CustomPopOverMenu";

export default function TaskFollowers(props) {
  const { allUsers, taskDetail, handleUpdateTask, isViewTask, isTestCase } =
    props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (taskDetail?.userPermission?.can_i_edit === false) {
      toast.info("You don't have access to update task", {
        position: "bottom-right",
      });
      return;
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const arrayOfIds = taskDetail?.followers?.map((user) => user.id) || [];
  const [selectedFollowers, setSelectedFollowers] = useState(arrayOfIds);
  // console.log(selectedFollowers, "selectedFollowers");
  const handleSelectOption = (user) => {
    const isSelected = selectedFollowers?.includes(user.id);
    setSelectedFollowers((prevSelected) => {
      const newSelected = isSelected
        ? prevSelected.filter((followerId) => followerId !== user.id)
        : [...prevSelected, user.id];
      const filteredData = newSelected.filter((item) => item !== undefined);
      handleUpdateTask("followers", filteredData);
      return filteredData;
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverClose = (event) => {
    if (anchorEl && !anchorEl.contains(event.target)) {
      handleClose();
    }
  };

  useEffect(() => {
    window.addEventListener("click", handlePopoverClose);
    return () => {
      window.removeEventListener("click", handlePopoverClose);
    };
  }, []);
  return (
    <React.Fragment>
      {selectedFollowers && selectedFollowers.length !== 0 ? (
        <span className="newFeature selected-images-row " onClick={handleClick}>
          {selectedFollowers.map((followerId) => {
            const user = allUsers?.find((user) => user.id === followerId);
            console.log(user, "user");

            return (
              <span
                key={followerId}
                className="selected-image select-follower-bor"
              >
                {user?.image ? (
                  <div
                    className="selected_follower size_cover userImages"
                    title={user.name}
                    style={{
                      backgroundImage: `url('${user.image}')`,
                    }}
                  />
                ) : (
                  <CustomAvatar
                    name={user?.name}
                    backgroundColor={user?.profile_color}
                    isMarginAuto={true}
                  />
                )}
              </span>
            );
          })}
          <span className="add_follower">
            <i className="fa fa-plus "></i>
          </span>
        </span>
      ) : (
        <Button
          onClick={handleClick}
          className={`newFeature p-0 ${
            taskDetail?.userPermission?.can_i_edit === false &&
            "not-allowed-disable"
          }`}
          style={{ marginTop: "-4px" }}
        >
          + Add
        </Button>
      )}
      {/* {console.log("allUsers:", allUsers, selectedFollowers)} */}
      <Popover
        anchorEl={anchorEl}
        className={`select-filter ${
          isViewTask ? "view-task-follower_popover" : ""
        }`}
        id="account-menu"
        open={open}
        onClose={handleClose}
      >
        <CustomPopOverMenu
          setSelectedUsers={setSelectedFollowers}
          selectedUsers={selectedFollowers}
          allUsers={allUsers}
          handleChange={handleSelectOption}
          isMultiple={true}
        />
      </Popover>
    </React.Fragment>
  );
}
