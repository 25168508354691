import { faClose, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { handleCopyText } from "../../Utils/helpers";
import {
  MediaFilePlayer,
  formattedMediaFileTitle,
} from "../comman/CommonFunction/MediaFileCommonFunction";

function MediaPlayerModalWindow(props) {
  const {
    openMediaPlayer,
    fileTitle,
    transcript,
    fileUrl,
    setOpenMediaPlayer,
  } = props;
  const [copyIconTitle, setCopyIconTitle] = useState("Copy transcript");
  const [isHovered, setIsHovered] = useState(false);
  const handleCloseMediaModal = () => {
    setOpenMediaPlayer(false);
  };
  const handleCopyTranscript = () => {
    handleCopyText(transcript, "File Transcript");
    setCopyIconTitle("Copied");
    setTimeout(() => {
      setCopyIconTitle("Copy transcript");
    }, 2000);
  };

  return (
    <>
      <Modal
        size="lg"
        show={openMediaPlayer}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="pt-5 pl-5 add-member_modal"
        style={{ zIndex: "1500" }}
      >
        <div className="container">
          <div className="main-modal">
            <Modal.Header className="project-modal_header ">
              <Modal.Title className="d-flex align-items-center ">
                {/* {transcript && fileUrl?.toLowerCase().includes('.txt') ? "Transcript" : fileUrl?.toLowerCase().includes('.mp4') ? "Play video" : "Play audio"} */}
                {fileTitle && formattedMediaFileTitle(fileTitle)}
              </Modal.Title>{" "}
              <div className="d-flex align-items-center">
                {transcript && (
                  <Tooltip title={copyIconTitle} arrow placement="top">
                    <FontAwesomeIcon
                      icon={faCopy}
                      onClick={handleCopyTranscript}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                      className="cursor-pointer me-3"
                      style={{
                        color: isHovered ? "rgb(0 83 204)" : "rgb(147 149 151)",
                      }}
                    />
                  </Tooltip>
                )}
                <FontAwesomeIcon
                  icon={faClose}
                  onClick={handleCloseMediaModal}
                />
              </div>
            </Modal.Header>
            <Modal.Body className="project-modal_body">
              <div className="fields">
                {!transcript ? (
                  MediaFilePlayer(fileUrl, fileTitle)
                ) : (
                  <div>
                    <h5 className="color_unset">Transcript </h5>
                    {transcript}
                  </div>
                )}
              </div>
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default MediaPlayerModalWindow;
