import React from "react";

export default function FooterCopyRight() {
 
  return (
    <div className="footer-bottom footer-border mt-5">
      <p className="mt-4 mb-4 text-center">
        © Copyright 2024
      </p>
    </div>
  );
}
