import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../customAxios";
export const editTask = createAsyncThunk(
  "edit",
  async ({ taskId, payload }) => {
    const response = await api.post(`/tasks/${taskId}`, payload);
    return response.data;
  }
);

export const projectUsers = createAsyncThunk("projectUsers", async (id) => {
  const response = await api.get(`project/${id}/all-users`);
  return response.data;
});
const taskSlice = createSlice({
  name: "task",
  initialState: {
    projectUsers: [],
    viewTask: [],
    errors: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(editTask.fulfilled, (state, action) => {
        const update = action.payload;
        state.viewTask = { ...update };
        state.errors = null;
      })
      .addCase(projectUsers.fulfilled, (state, action) => {
        state.projectUsers = action.payload;
        state.errors = null;
      });
  },
});

export default taskSlice.reducer;
