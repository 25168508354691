import React, { useEffect } from "react";
import All from "../../../assets/images/projectD1.png";
import { useInView } from "react-intersection-observer";

export default function Team() {
  const { ref: parent1Ref, inView: parent1InView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const { ref: parent2Ref, inView: parent2InView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  const { ref: parent3Ref, inView: parent3InView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  useEffect(() => {
    if (parent1InView || parent2InView || parent3InView) {
      document.body.classList.add("hide-scrollbar");
    } else {
      document.body.classList.remove("hide-scrollbar");
    }

    return () => {
      document.body.classList.remove("hide-scrollbar");
    };
  }, [parent1InView, parent2InView, parent3InView]);
  return (
    <div>
      <section className="project project-team decrease__padding reports-div">
        <div className="container__fearter">
          <div className="text-center" ref={parent1Ref}>
            <h3 className="mian-text responsive_heading">Reports & Analytics</h3>
          </div>
          <div className="row mt-5 align-items-center">
            <div className="col-md-6 cads__width">
              <div className={`cads reporting_card ${parent1InView ? "animate__animated animate__fadeInLeft" : ""}`} style={{ visibility: parent1InView ? 'visible' : 'hidden', opacity: parent1InView ? 1 : 0 }}>
                <p className="paragraph responsive_small_heading">
                  <b>Burn up & Burn Down charts:</b>
                </p>
                <p className="pargraph-body responsive_text mt-2">
                  Track completed work versus planned, identify trends, and make
                  data-driven decisions to stay on course towards project goals.
                </p>
              </div>
              <br />
              <div className={`cads cads__center ${parent2InView ? "animate__animated animate__fadeInLeft" : ""}`} ref={parent2Ref} style={{ visibility: parent2InView ? 'visible' : 'hidden', opacity: parent2InView ? 1 : 0 }}>
                <p className="paragraph responsive_small_heading">
                  <b>Project Overview :</b>
                </p>
                <p className="pargraph-body responsive_text mt-2">
                  Gain a holistic view of project status, including task
                  progress, milestones achieved, and upcoming deadlines, for
                  informed decision-making and reporting purposes.
                </p>
              </div>
              <br />
              <div className={`cads ${parent3InView ? "animate__animated animate__fadeInLeft" : ""}`} ref={parent3Ref} style={{ visibility: parent3InView ? 'visible' : 'hidden', opacity: parent3InView ? 1 : 0 }}>
                <p className="paragraph responsive_small_heading">
                  <b>Sprints Reports:</b>
                </p>
                <p className="pargraph-body responsive_text mt-2">
                  Analyze sprint metrics like team velocity, burn-up, burn down
                  reports to see what went well and what needs to improve in
                  upcoming sprint.
                </p>
              </div>
            </div>
            <div className={`col-md-6 cads__colmn feature-reports ${parent3InView || parent1InView ? "animate__animated animate__fadeInRight" : ""}`} style={{ visibility: parent3InView || parent1InView ? 'visible' : 'hidden', opacity: parent3InView || parent1InView ? 1 : 0 }}>
              <div className="crop-container">
                <img src={All} alt="Cropped Image" className="cropped-image" />
                {/* <div className="image"></div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
