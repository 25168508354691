import moment from "moment";
import React, { useState } from "react";
import Camera from "../../../../assets/images/camera.png";
import Microphone from "../../../../assets/images/microphone.png";
import MediaFileCard from "../../../../componets/shared/MediaFileCard";
import MediaRecordingModalWindow from "../../../../componets/shared/MediaRecordingModalWindow";
import { refreshFiles } from "../../../../componets/comman/CommonFunction/MediaFileCommonFunction";
import { toast } from "react-toastify";

function TaskMediaRecorder(props) {
  const { taskDetail, setProgress } = props;
  const [openRecordModal, setOpenRecordModal] = useState(false);
  const [mediaType, setMediaType] = useState("audio");
  const [fileTitle, setFileTitle] = useState();
  const [mediaFiles, setMediaFiles] = useState(taskDetail?.media_files);
  const handleOpenAudioVideo = (type) => {
    const jsonString = localStorage.getItem('permissions');
    const jsonObject = JSON.parse(jsonString);
    if (jsonObject?.videos?.limit_value != jsonObject?.videos?.limit_count) {
      setFileTitle(
        `${taskDetail.task_name} - ${moment().format("MMMM Do YYYY")}`
      );
      setMediaType(type);
      setOpenRecordModal(true);
    } else {
      toast.info(`Your ${type}s limit has been exceeded.`)
    }
  };
  const refreshMediaFiles = (action, fileData) => {
    refreshFiles(action, fileData, "general", setMediaFiles, mediaFiles);

  };
  return (
    <div className="box" id="bx">
      <div className="box-head">
        <div className="text">
          <h5 className="mt-3 text-secondary ml-0">Record Message.</h5>
        </div>
      </div>
      <div className="box-body d-flex">
        <button
          className={`btn btn-cancel recording_btn ${taskDetail?.userPermission?.can_i_edit
            ? "cursor_pointer"
            : "not-allowed-disable sprint-action"
            }`}
          onClick={
            taskDetail?.userPermission?.can_i_edit
              ? () => handleOpenAudioVideo("audio")
              : null
          }
          style={{
            color: taskDetail?.userPermission?.can_i_edit ? "initial" : "gray",
            cursor: taskDetail?.userPermission?.can_i_edit
              ? "pointer"
              : "not-allowed",
          }}
        >
          <img alt="" src={Microphone} className="me-2" /> Voice Message
        </button>
        <button
          className={`btn btn-cancel ms-3 recording_btn ${taskDetail?.userPermission?.can_i_edit
            ? "cursor_pointer"
            : "not-allowed-disable sprint-action"
            }`}
          onClick={
            taskDetail?.userPermission?.can_i_edit
              ? () => handleOpenAudioVideo("video")
              : null
          }
          style={{
            color: taskDetail?.userPermission?.can_i_edit ? "initial" : "gray",
            cursor: taskDetail?.userPermission?.can_i_edit
              ? "pointer"
              : "not-allowed",
          }}
        // disabled={!taskDetail?.userPermission?.can_i_edit}
        >
          <img alt="" src={Camera} className="me-2" /> Record Screen
        </button>
      </div>

      <MediaFileCard
        setProgress={setProgress}
        setFileTitle={setFileTitle}
        fileTitle={fileTitle}
        setRecordedFiles={setMediaFiles}
        recordedFiles={mediaFiles}
        refreshMediaFiles={refreshMediaFiles}
      />
      {mediaFiles && mediaFiles.length <= 0 ? (
        <p className="text-muted fs-6 mt-4 mb-4">No recorded message.</p>
      ) : null}
      {openRecordModal && (
        <MediaRecordingModalWindow
          openRecordModal={openRecordModal}
          setOpenRecordModal={setOpenRecordModal}
          mediaType={mediaType}
          setFileTitle={setFileTitle}
          fileTitle={fileTitle}
          taskDetail={taskDetail}
          refreshMediaFiles={refreshMediaFiles}
        />
      )}
    </div>
  );
}

export default TaskMediaRecorder;
