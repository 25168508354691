import React from "react";

function FileViewLoader() {
  const CommentLoader = ({ key }) => {
    return (
      <div className="file_comment" key={key}>
        <div className="file_comment_user">
          <div className="div1 shimmerBG"></div>
        </div>
        <div className="file_comment_detail ms-2 w-100">
          <div className="d-flex align-items-center">
            {[...Array(2)].map((_, i) => (
              <span
                key={i}
                className={`file_comment_time ${
                  i === 1 ? "ms-3" : ""
                } shimmerBG br_6 h-14 w-15`}
              ></span>
            ))}
          </div>
          {[...Array(2)].map((_, i) => (
            <p
              key={i}
              className={`file_comment_text mb-0 ${
                i === 0 ? "mt-10px" : ""
              } shimmerBG  br_6 w-100 h-14`}
            ></p>
          ))}
        </div>
      </div>
    );
  };
  const CommentBoxLoader = () => {
    return (
      <div className="file-main-comment-box  h_unset fileViewBoxesCommonStyle br_10">
        <h5 className="shimmerBG br_6 w-fit_content">Comments</h5>
        <div className={`file-main-comment-box_body file-load-comment `}>
          {[...Array(5)].map((i) => (
            <CommentLoader key={i} />
          ))}
        </div>
      </div>
    );
  };
  return (
    <div className="file_view_main_container">
      <div className="file-view-content-box container__land">
        <div className="file-view-content file-load-content fileViewBoxesCommonStyle br_10">
          <div className="file-view-content-head">
            <h5 className="shimmerBG file_content_heading br_6">
              View file title
            </h5>
            <div className="d-flex">
              {[...Array(2)].map((e, i) => (
                <div
                  key={i}
                  className={`div1 shimmerBG ${i === 0 ? "me-3" : ""}`}
                ></div>
              ))}
            </div>
          </div>
          <div className="shimmerBG  h-90 h-500 br_6"></div>
        </div>
        <CommentBoxLoader />
      </div>
    </div>
  );
}

export default FileViewLoader;
