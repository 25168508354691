import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Green from "../../../assets/images/green.png";

export default function Pricing() {
  const [isNavbarVisible, setIsNavbarVisible] = useState(false);
  const inputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isNavbarVisible) {
      inputRef.current.focus();
    }
  }, [isNavbarVisible]);

  return (
    <div className="guestPricing">
      <section>

        <div class="pricing_main_page ">
          <div class="container__land">
            <div class="text-center top-text">
              <h1 class="ups">Pricing</h1>
              <p class=" mt-1">Home/Pricing</p>
              <br />
            </div>
          </div>
        </div>
        <section class="pricing mb-5 ">
          <div class="container__land">
            <div class="row">
              <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="card pricingBox">
                  <div class="card-body">
                    <h3 class="card-title text-center bold">Free</h3>
                    <div>
                      <ul>
                        <li>
                          <span class="imageBox">
                            <img src={Green} alt="" />
                          </span>
                          <span>5 Users</span>
                        </li>
                        <li>
                          <span class="imageBox">
                            <img src={Green} alt="" />
                          </span>
                          <span>25 Videos</span>
                        </li>
                        <li>
                          <span class="imageBox">
                            <img src={Green} alt="" />
                          </span>
                          <span>2 Projects</span>
                        </li>
                        <li>
                          <span class="imageBox">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="red"
                              class="bi bi-x" viewBox="0 0 16 16">
                              <path
                                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                            </svg>
                          </span>
                          <span>Test Cases</span>
                        </li>
                      </ul>
                    </div>

                  </div>
                  <div class="cardFooter">
                    <h3 class="card-title text-center bold">$0.00</h3>
                    <p class="card-text gray text-center">per user</p>
                    <p class="card-text  text-center">$0.00 monthly</p>
                    <button onClick={() => navigate("/signup")} class="btn btn-primary pricing-button">
                      Get Started
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="card pricingBox">
                  <div class="card-body">
                    <h3 class="card-title text-center bold">Basic</h3>
                    <div>
                      <ul>
                        <li>
                          <span class="imageBox">
                            <img src={Green} alt="" />
                          </span>
                          <span>10 Users</span>
                        </li>
                        <li>
                          <span class="imageBox">
                            <img src={Green} alt="" />
                          </span>
                          <span>50 Videos</span>
                        </li>
                        <li>
                          <span class="imageBox">
                            <img src={Green} alt="" />
                          </span>
                          <span>5 Projects</span>
                        </li>
                        <li>
                          <span class="imageBox">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="red"
                              class="bi bi-x" viewBox="0 0 16 16">
                              <path
                                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                            </svg>
                          </span>
                          <span>Test Cases</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="cardFooter">
                    <h3 class="card-title text-center bold">$4.99</h3>
                    <p class="card-text gray text-center">per user</p>
                    <p class="card-text  text-center">$4.99 monthly </p>
                    <button onClick={() => navigate("/signup")} type="button" class="btn btn-primary pricing-button">
                      Get Started
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="card pricingBox">
                  <div class="card-body">
                    <h3 class="card-title text-center bold">Pro</h3>
                    <div>
                      <ul>
                        <li>
                          <span class="imageBox">
                            <img src={Green} alt="" />
                          </span>
                          <span>Unlimited Users</span>
                        </li>
                        <li>
                          <span class="imageBox">
                            <img src={Green} alt="" />
                          </span>
                          <span>Unlimited Videos</span>
                        </li>
                        <li>
                          <span class="imageBox">
                            <img src={Green} alt="" />
                          </span>
                          <span>Unlimited Projects</span>
                        </li>
                        <li>
                          <span class="imageBox">
                            <img src={Green} alt="" />
                          </span>
                          <span>Test Cases</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="cardFooter">
                    <h3 class="card-title text-center bold">$9.99</h3>
                    <p class="card-text gray text-center">per user</p>
                    <p class="card-text  text-center">$9.99 monthly </p>
                    <button onClick={() => navigate("/signup")} type="button" class="btn btn-primary pricing-button">
                      Get Started
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="card pricingBox">
                  <div class="card-body">
                    <h3 class="card-title text-center bold">On-Premises</h3>
                    <div>
                      <ul>
                        <li>
                          <span class="imageBox">
                            <img src={Green} alt="" />
                          </span>
                          <span>Unlimited users</span>
                        </li>
                        <li>
                          <span class="imageBox">
                            <img src={Green} alt="" />
                          </span>
                          <span>Unlimited videos</span>
                        </li>
                        <li>
                          <span class="imageBox">
                            <img src={Green} alt="" />
                          </span>
                          <span>Unlimited Projects</span>
                        </li>
                        <li>
                          <span class="imageBox">
                            <img src={Green} alt="" />
                          </span>
                          <span>Test Cases</span>
                        </li>
                      </ul>
                    </div>

                  </div>
                  <div class="cardFooter">
                    <button onClick={() => navigate("/contactus")} class="btn btn-primary pricing-button">Contect Us</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <div className="pricing_table_section">
      </div>
    </div>
  );
}
