import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import CustomAvatar from "../Task/CustomAvatar";
import { hexToRGBA } from "../../../Utils/helpers";
const CustomAutoCompleteTag = ({
    index,
    option,
    onChange,
    value,
    getTagProps,
    isPriority,
    isUser,
    isSingle
}) => {
    const textStyle = (color) => {
        if (color) {
            return {
                color: color,
                backgroundColor: `rgba(${hexToRGBA(color).r}, ${hexToRGBA(color).g}, ${hexToRGBA(color).b
                    }, 0.1)`,
                padding: "5px",
                borderRadius: "6px",
                marginRight: "5px",
                display: "flex",
                alignItems: "center",
            };
        } else {
            return {};
        }
    };

    const TagDeselectIcon = ({ index, value, backgroundColor, onChange }) => {
        const [hoveredIndex, setHoveredIndex] = useState(null);

        const handleMouseEnter = () => {
            setHoveredIndex(index);
        };

        const handleMouseLeave = () => {
            setHoveredIndex(null);
        };
        const iconStyle = {
            background: backgroundColor || "#707070",
            transition: "background 0.3s ease",
        };
        return (
            <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className="add_cursor"
            >
                {hoveredIndex === index ? (
                    <CloseIcon
                        onClick={() => {
                            const newValue = [...value];
                            newValue.splice(index, 1);
                            onChange(newValue);
                        }}
                        className="deselect_label"
                        style={iconStyle}
                    />
                ) : (
                    <CheckIcon className="deselect_label" style={iconStyle} />
                )}
            </div>
        );
    };

    return (
        <div
            key={index}
            style={textStyle(
                isUser ? option.profile_color || "#707070" : option.color
            )}
            {...getTagProps({ index })}
        >
            {isPriority ? (
                <img src={option.imageSrc} alt="" />
            ) : isUser ? (
                option.image ? (
                    <img
                        src={option.image}
                        alt=""
                        className={`img_20 avatar__border br_6`}
                    />
                ) : (
                    <CustomAvatar
                        name={option.label || ""}
                        width={20}
                        height={20}
                        fontSize={10}
                        borderRadius={4}
                        backgroundColor={option.profile_color || ""}
                    />
                )
            ) : (
                ""
            )}
            <div
                className={`user_label ${isUser ? "mx-2" : isPriority ? "ms-2" : ""}`}
            >
                {option.label || option.name}
            </div>
            {!isSingle &&
                <TagDeselectIcon
                    onChange={onChange}
                    value={value}
                    backgroundColor={isUser ? option.profile_color : option.color}
                    index={index}
                />}
        </div>
    );
};
export default CustomAutoCompleteTag