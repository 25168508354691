import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import CustomAvatar from "../Task/CustomAvatar";
const CustomAutoCompleteMenu = ({
    option,
    selected,
    onChange,
    value,
    props,
    isUser,
    isSingle
}) => {
    return (
        <li {...props}>
            {isUser ? (
                option.image ? (
                    <img
                        src={option.image}
                        alt=""
                        className={`userImages ${option.label !== "Unassigned" ? "avatar__border" : ""
                            } me-2`}
                    />
                ) : (
                    <span className="me-2">
                        <CustomAvatar
                            name={option.label || ""}
                            backgroundColor={option.profile_color || ""}
                        />
                    </span>
                )
            ) : option.imageSrc ? (
                <img src={option.imageSrc} alt="" className="me-2" />
            ) : (
                ""
            )}
            <ListItemText
                style={{ color: option.color || option.profile_color }}
                className="text-capitalize"
                primary={option.label}
            />
            {!isSingle && <Checkbox
                checked={selected}
                onChange={() => {
                    const currentIndex = value.findIndex(
                        (val) => val.value === option.value
                    );
                    if (currentIndex === -1) {
                        onChange([...value, option]);
                    } else {
                        const updatedValue = [...value];
                        updatedValue.splice(currentIndex, 1);
                        onChange(updatedValue);
                    }
                }}
                className="ms-auto"
            />}
        </li>
    );
};

export default CustomAutoCompleteMenu