import React, { useEffect } from "react";
import All from "../../../assets/images/conversation2.png";
import { useInView } from "react-intersection-observer";


export default function Communication() {
  const { ref: parent1Ref, inView: parent1InView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const { ref: parent2Ref, inView: parent2InView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  const { ref: parent3Ref, inView: parent3InView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  useEffect(() => {
    if (parent1InView || parent2InView || parent3InView) {
      document.body.classList.add("hide-scrollbar");
    } else {
      document.body.classList.remove("hide-scrollbar");
    }

    return () => {
      document.body.classList.remove("hide-scrollbar");
    };
  }, [parent1InView, parent2InView, parent3InView]);
  return (
    <div>
      <section className="project decrease__padding collaboration-div">
        <div className="container__fearter">
          <div className="text-center" ref={parent1Ref}>
            <h3 className="mian-text mani-com responsive_heading ">
              Collaboration & Communication
            </h3>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6 cads__width">
              <div className={`cads reporting_card ${parent1InView ? "animate__animated animate__fadeInLeft" : ""}`} style={{ visibility: parent1InView ? 'visible' : 'hidden', opacity: parent1InView ? 1 : 0 }}>
                <p className="paragraph ">
                  <b className="responsive_small_heading">Video Sharing:</b>
                </p>
                <p className="pargraph-body mt-2 responsive_text ">
                  Record audio and video messages within tasks to share updates,
                  tasks details and seek feedback from the team
                </p>
              </div>
              <br />
              <div className={`cads cads__center ${parent2InView ? "animate__animated animate__fadeInLeft" : ""}`} ref={parent2Ref} style={{ visibility: parent2InView ? 'visible' : 'hidden', opacity: parent2InView ? 1 : 0 }}>
                <p className="paragraph">
                  <b className="responsive_small_heading">Teams Management:</b>
                </p>
                <p className="pargraph-body mt-2 responsive_text">
                  Organize team members into groups and manage permissions. Add
                  team members in projects, assign tasks and keep track of their
                  velocity.
                </p>
              </div>
              <br />
              <div className={`cads ${parent3InView ? "animate__animated animate__fadeInLeft" : ""}`} ref={parent3Ref} style={{ visibility: parent3InView ? 'visible' : 'hidden', opacity: parent3InView ? 1 : 0 }}>
                <p className="paragraph">
                  <b className="responsive_small_heading">Files:</b>
                </p>
                <p className="pargraph-body mt-2 responsive_text">
                  Effortlessly create and maintain important project
                  documentation, files, and team guidelines at one place for
                  easy reference and knowledge sharing.
                </p>
              </div>
            </div>
            <div className={`col-md-6 cads__colmn features-collaboration ${parent3InView || parent1InView ? "animate__animated animate__fadeInRight" : ""}`} style={{ visibility: parent3InView || parent1InView ? 'visible' : 'hidden', opacity: parent3InView || parent1InView ? 1 : 0 }}>
              <div className="crop-container">
                <img
                  src={All}
                  alt="Cropped Image"
                  className="cropped-image height-issue"
                />
                {/* <div className="image"></div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
