import React, { useRef, useEffect } from "react";
import BlogNavigation from "../../../componets/Blogs/BlogNavigation";
import BlogContent from "../../../componets/Blogs/BlogContent";
import { Link } from "react-router-dom";
import { scrollToTop } from "../../../Utils/helpers";

export default function SprintPlaning() {
  const scrollUp = () => {
    scrollToTop();
  };
  useEffect(() => {
    scrollUp();
  }, []);

  const sections = [
    {
      id: "section1",
      title: "Sprint Planning: A Comprehensive Guide",
      content: (
        <>
          <p class="padding-top-19">
            Sprint planning is a crucial event in Scrum methodology in which
            teams come together to plan and commit the work they will do in a
            sprint. It is a collaborative effort of the Development Team, Scrum
            Master, and Product Owner. In a sprint planning the whole scrum team
            works together to define the sprint goal, select the work to be
            done, and create a plan for delivering it.
          </p>
        </>
      ),
    },
    {
      id: "section2",
      title: "How to Conduct a Sprint Planning Meeting?",
      content: (
        <>
          <p class="padding-top-19">
            A sprint planning meeting is a time-boxed event that typically lasts
            4-8 hours for a two-week sprint. The duration of the meeting may
            vary in accordance with the duration of the sprint. The meeting
            involves the Development Team, Scrum Master, and Product Owner. The
            goal of the meeting is to select the work that needs to be done in
            the upcoming sprint.
          </p>
          <p>
            During the sprint planning meeting, the Product Owner presents the
            top items in the product backlog to the Development Team. The team
            then discusses each item and estimates the effort required to
            complete it. The team also identifies any dependencies or risks
            associated with each item.
          </p>
          <p>
            Once the team has a clear understanding of the work to be done, they
            create a sprint backlog. The sprint backlog is a list of the items
            that the team has committed to delivering in the sprint. The team
            also creates a plan for delivering the work, including identifying
            tasks, assigning team members, and estimating the time required to
            complete each task.
          </p>
        </>
      ),
    },
    {
      id: "section3",
      title: "Why is it Important to Have a Sprint Planning Meeting",
      content: (
        <>
          <p class="padding-top-19">
            By having a sprint planning meeting, the team can select the work
            that is most important and valuable to the customer. This helps to
            ensure that the team is working on the right things and that their
            efforts are aligned with the overall product vision. The meeting
            also allows the team to identify any dependencies or risks
            associated with the work, which helps to prevent issues from arising
            during the sprint.
          </p>
        </>
      ),
    },
    {
      id: "section4",
      title: "Things to Figure Out Before Sprint Planning Meeting",
      content: (
        <>
          <h4 class="mb-3 f-s-20 padding-top-19">
            Prioritize the Product Backlog
          </h4>
          <p>
            The Product Owner should ensure that the product backlog is
            up-to-date and that the items at the top of the backlog are ready
            for development. This involves ensuring that the items are
            well-defined, acceptance criteria are clear, and dependencies or
            risks associated with the items have been identified and addressed.
          </p>
          <h4 class="mb-3 f-s-20">Determine Team Capacity</h4>
          <p>
            This involves considering the availability of team members, the
            skills and resources required to deliver the work, and any external
            factors that may impact the team's ability to deliver the work. By
            determining team capacity, the team is able to make informed
            decisions about how much work they can realistically commit to
            delivering in the sprint.
          </p>
          <h4 class="mb-3 f-s-20">Identify Dependencies and Risks</h4>
          <p>
            Before the sprint planning meeting, the team should identify any
            dependencies or risks associated with the work to be done. This
            involves considering any external factors that may impact the team's
            ability to deliver the work, such as dependencies on other teams or
            external vendors.
          </p>
          <h4 class="mb-3 f-s-20">Gather User Persona</h4>
          <p>
            This may involve gathering data on user behavior, market trends, or
            technical requirements. By gathering necessary information, the team
            is able to make data-driven decisions about the work to be done and
            ensure that it is aligned with the overall product vision.
          </p>
        </>
      ),
    },
    {
      id: "section5",
      title: "Conclusion",
      content: (
        <>
          <p class="padding-top-19">
            Agile sprint planning meetings help the team to align their efforts
            towards achieving a common goal, select the most important work, and
            identify potential issues before they arise. Understanding the
            purpose of each meeting in Scrum is crucial for effective practice,
            and sprint planning is no exception.
          </p>

          <p>
            In{" "}
            <Link to="/" onClick={scrollUp}>
              Scrum Slate
            </Link>{" "}
            teams can efficiently plan their sprints. With{" "}
            <Link to="/" onClick={scrollUp}>
              Scrum Slate
            </Link>
            , teams can organize work based on priority, figure out dependencies
            using task relationships, and manage work in progress. By using{" "}
            <Link to="/" onClick={scrollUp}>
              Scrum Slate
            </Link>
            , teams can streamline their sprint planning process and ensure that
            they are aligned towards achieving their common goal.
          </p>
        </>
      ),
    },
  ];

  const scrollRef = useRef();

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <div className="aboutus_page padding-b-blog">
        <div className="container__land">
          <div className="text-center top-text mt-5">
            <h1>Sprint Planning: A Comprehensive Guide</h1>
          </div>
        </div>
      </div>

      <div className="agile_card_section mb-2">
        <div className="container__land">
          <div className="meta-cards row mb-5">
            <div className="col-md-3">
              <h4>Table of Content</h4>
              <hr />
              <BlogNavigation sections={sections} handleScroll={handleScroll} />
            </div>
            <div className="col-md-9 agile__content" ref={scrollRef}>
              {sections.map((section, index) => (
                <BlogContent
                  key={index}
                  id={section.id}
                  title={section.title}
                  content={section.content}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
