import moment from "moment";
import { api } from "../../../customAxios";
import { toast } from "react-toastify";
import axios from "axios";
import MuxPlayer from "@mux/mux-player-react";
export const OpenMediaFileDocument = (
  file,
  type,
  setFileTitle,
  setTranscript,
  setProgress,
  setOpenViewFile,
  setFileUrl
) => {
  if (type !== "audioVideo") {
    setProgress(30);
    axios
      .get(file?.file_metadata?.text_track)
      .then((response) => {
        setProgress(50);
        setTranscript(response?.data);
        setFileTitle(file?.file_title);
        setOpenViewFile(true);
      })
      .catch((error) => {
        toast.error(error?.message);
      })
      .finally(() => {
        setProgress(100);
      });
  } else {
    setFileUrl(file.playback_id);
    setFileTitle(file?.file_title);
    setTranscript("");
    setOpenViewFile(true);
  }
};

export const refreshFiles = (action, fileData, renderType, setFiles, files) => {
  if (action === "delete") {
    let updatedFiles;
    if (renderType === "byDate") {
      updatedFiles = Object.keys(files).reduce((acc, date) => {
        const filteredFiles = files[date].filter(
          (file) => file.id !== fileData
        );
        if (filteredFiles.length > 0) {
          acc[date] = filteredFiles;
        }
        return acc;
      }, {});
    } else {
      updatedFiles = files.filter(
        (selectedFile) => selectedFile.id !== fileData
      );
    }
    setFiles(updatedFiles);
  } else if (action === "add") {
    if (renderType === "byDate") {
      setFiles((previousFiles) => {
        const allFiles = Object.values(previousFiles).flat().concat(fileData);
        const sortedFiles = allFiles.slice().sort((a, b) => {
          const dateA = new Date(a.file_created_at || a.created_at);
          const dateB = new Date(b.file_created_at || b.created_at);
          return dateB - dateA;
        });
        const groupedFiles = groupFilesByDate(sortedFiles);
        return groupedFiles;
      });
    } else {
      setFiles([...files, fileData]);
    }
  }
};
export const handleDeleteMediaFile = (
  setOpen,
  setProgress,
  refreshMediaFiles,
  fileUrl,
  isS3File,
  fileId
) => {
  setOpen(false);
  setProgress(30);
  const apiUrl = isS3File
    ? `/delete-s3-file?file_name=${fileUrl?.name}`
    : `/files/${fileId}`;

  api
    .delete(apiUrl)
    .then((res) => {
      localStorage.setItem('permissions', JSON.stringify(res.data.permissions))
      setProgress(50);
      refreshMediaFiles("delete", isS3File ? fileUrl.id : fileId);
      toast.success("File deleted successfully!");
    })
    .catch((error) => {
      toast.error(error?.message);
    })
    .finally(() => {
      setProgress(100);
    });
};
export const groupFilesByDate = (files) => {
  const groupedFiles = {};

  files.forEach((file) => {
    const date = moment(file.file_created_at || file.created_at).format(
      "MMMM DD, YYYY"
    );
    if (!groupedFiles[date]) {
      groupedFiles[date] = [];
    }

    groupedFiles[date].push(file);
  });

  return groupedFiles;
};
export const handleUploadAttachment = async (
  event,
  attachmentRef,
  setProgress,
  refreshMediaFiles,
  modelId,
  modelName
) => {
  setProgress(30);
  var file = event.target.files[0];
  if (file) {
    const maxSize = 2 * 1024 * 1024;
    if (file.size > maxSize) {
      toast.error("File size exceeds the limit of 2 MB");
      event.target.value = "";
      setProgress(100);
      return;
    }
    const formData = new FormData();
    formData.append("model_id", modelId);
    formData.append("model_name", modelName);
    formData.append("file", file);
    formData.append("file_title", file.name);
    api.post(`/files`, formData).then((action) => {
      if (action?.status === 200) {
        setProgress(60);
        const newFile = action?.data;
        refreshMediaFiles("add", newFile);
        setProgress(100);
        toast.success("File Added Successfully!");
        if (attachmentRef.current) {
          attachmentRef.current.value = null;
        }
      } else {
        toast.error("An error occurred. Try Again!");
        setProgress(100);
        if (attachmentRef.current) {
          attachmentRef.current.value = null;
        }
      }
    });
  }
};
export const formattedMediaFileTitle = (fileTitle, delimiter = "-") => {
  const parts = fileTitle.split(delimiter);
  return (
    <p className="d-flex mb-0">
      <span style={{ maxWidth: "350px" }} className="textEllipse">
        {parts[0]}
      </span>
      <span>{parts[1]}</span>
    </p>
  );
};
export const MediaFilePlayer = (playbackId, fileTitle) => {
  return (
    <>
      <MuxPlayer
        playbackId={playbackId}
        metadata={{
          video_title: fileTitle,
        }}
        primaryColor="#fff"
        accentColor="#0052cc"
      />
    </>
  );
};
