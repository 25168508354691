import React, { useState, useRef } from "react";
import Logo from "../../../assets/images/Login1.svg";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { api } from "../../../customAxios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";

function ResetPassword() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const schema = Yup.object().shape({
    password: Yup.string()
      .required("Please enter your password")
      .min(6, "The password must be at least 6 characters"),
    password_confirmation: Yup.string()
      .required("Please confirm your password")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {},
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleResetPassword = (data) => {
    setIsLoading(true);
    const payload = {
      token: queryParams.get("token"),
      email: queryParams.get("email").replace(/ /g, '+'),
      password: data.password,
      password_confirmation: data.password_confirmation,
    };
    api
      .post("/auth/reset-password", payload, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((res) => {
        setIsLoading(false);
        toast.success(res?.data?.message);
        navigate("/signin");
      })
      .catch((error) => {
        console.error("Login failed:", error);
        toast.error(
          error?.response?.data?.message === "Invalid token"
            ? "Your token is expired."
            : error?.response?.data?.message
        );
        setIsLoading(false);
      });
  };
  return (
    <div className="login__card">
      <h4 className="text-center mb-3">Set New Password</h4>
      <form className="form" onSubmit={handleSubmit(handleResetPassword)}>
        <div className="password_input">
          <label className="form-label form-label__email">Password</label>
          <input
            type={showPassword ? "text" : "password"}
            className={`form-control form-control__input f-12 w-100 ${
              errors.password ? "is-invalid_boder" : ""
            }`}
            placeholder="Enter password"
            {...register("password")}
          />
          <button
            type="button"
            className="password-toggle-button"
            onClick={() => setShowPassword(!showPassword)}
          >
            <FontAwesomeIcon
              icon={`fas ${showPassword ? "fa-eye" : "fa-eye-slash"}`}
            />
          </button>
          <span className="danger-color error-msg">
            <span>{errors.password && errors.password.message}</span>
          </span>
        </div>
        <div className="password_input">
          <label className="form-label form-label__email">
            Confirm Password
          </label>
          <input
            type={showConfirmPassword ? "text" : "password"}
            className={`form-control form-control__input f-12 w-100 ${
              errors.password_confirmation ? "is-invalid_boder" : ""
            }`}
            placeholder={` ${
              errors.password_confirmation?.message
                ? errors.password_confirmation?.message
                : "Password confirmation"
            }`}
            {...register("password_confirmation", {
              required: "Password Confirmation is required",
            })}
          />
          <button
            type="button"
            className="password-toggle-button"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          >
            <FontAwesomeIcon
              icon={`fas ${showConfirmPassword ? "fa-eye" : "fa-eye-slash"}`}
            />
          </button>
          <span className="danger-color error-msg">
            <span>
              {errors.password_confirmation &&
                errors.password_confirmation.message}
            </span>
          </span>
        </div>
        <button
          disabled={!isDirty || !isValid || isLoading}
          type="submit"
          className={`btn btn-primary all-btn-padding mt-3 pt-3 pb-3 w-100 ${
            isLoading ? "reset-password-cliper-pd" : ""
          }`}
        >
          {isLoading ? (
            <ClipLoader color="#fff" loading={isLoading} size={20} />
          ) : (
            "Reset Password"
          )}
        </button>
      </form>
    </div>
  );
}

export default ResetPassword;
