import React from "react";
import {
  Step,
  StepLabel,
  StepContent,
  Typography,
  Grid,
  Box,
  Alert,
} from "@mui/material";
import moment from "moment";
import PanoramaFishEye from "@mui/icons-material/PanoramaFishEye";
import ClipLoader from "react-spinners/ClipLoader";
import { formateDate, getCreatedTime } from "../../../../Utils/helpers";

function TaskHistory(props) {
  const { taskHistory, isLoadingHistory } = props;

  const lastUpdatedTimestamp = (timeStamp) => {
    return (
      <Typography
        className="mr-20 mt-2"
        style={{ minWidth: "13.5%", fontSize: "0.9rem" }}
      >
        <Box className="align-right">{formateDate(timeStamp)}</Box>
        <Box className="align-right">{getCreatedTime(timeStamp)}</Box>
      </Typography>
    );
  };
  const priorityArray = [
    { priority: "High", color: "red" },
    { priority: "Medium", color: "yellow" },
    { priority: "Low", color: "lightblue" },
    { priority: "None", color: "gray" },
  ];

  const formatLogDescription = (logDesc) => {
    const dateRegex = /\b\d{4}-\d{2}-\d{2}\b|\b00:00:00\b/g;
    const formattedDesc = logDesc.replace(dateRegex, (match) => {
      const priority = priorityArray.find(
        (item) => item.priority.toLowerCase() === match.toLowerCase()
      );
      if (priority) {
        return `<strong style="color: ${priority.color};">${priority.priority}</strong>`;
      } else if (match === "00:00:00") {
        return `<strong>not set</strong>`;
      } else {
        const formattedDate = moment(match).format("MMM DD");
        return `<strong>${formattedDate}</strong>`;
      }
    });

    return formattedDesc;
  };
  return (
    <>
      {isLoadingHistory ? (
        <div className="w-100 text-center p-3">
          <ClipLoader color="#0052cc" loading={isLoadingHistory} size={20} />
        </div>
      ) : (
        <Grid
          spacing={1}
          item
          lg={12}
          md={12}
          xs={12}
          className="time-line-border "
        >
          {" "}
          {taskHistory && (
            <Alert
              icon={false}
              severity="success"
              className="history_description"
            >
              {taskHistory && taskHistory?.length === 0
                ? "There are no changes made in the last 7 days"
                : "Displaying changes made in the last 7 days"}
            </Alert>
          )}
          <Box
            className={`time-lineStepper p-30 ${taskHistory?.length === 0 ? "" : "mt-20"
              }`}
          >
            {taskHistory?.map((history, index) => (
              <Grid className="d-flex pl-20 " key={index + 1}>
                {lastUpdatedTimestamp(history.log_created_at)}
                <Step expanded className="ml-5 history-content">
                  <StepLabel className="f-16 font-weifgt-600">
                    <span className="p-absolute timeline-img">
                      <PanoramaFishEye
                        sx={{
                          color: "#0052cc",
                          marginRight: "10px",
                          fontSize: "16px",
                        }}
                      />
                    </span>
                    <span className="f-16 font-weight-600 m-40">
                      <span className="font-weight-600 ms-2">
                        {history?.event_created_by_name}
                      </span>
                    </span>
                  </StepLabel>
                  <StepContent>
                    <Typography
                      className="f-14 gray7 font-weight-400 ml-10 history_description "
                      dangerouslySetInnerHTML={{
                        __html:
                          history?.log_event && history?.log_desc
                            ? history?.log_event +
                            " " +
                            formatLogDescription(history?.log_desc)
                            : "",
                      }}
                    ></Typography>
                  </StepContent>
                </Step>
              </Grid>
            ))}
          </Box>
        </Grid>
      )}
    </>
  );
}

export default TaskHistory;
