import React from 'react';

const BlogContent = ({ id, title, content }) => {
  return (
    <section id={id} className='mb-4'>
      <h4>{title}</h4>
      {/* <div dangerouslySetInnerHTML={{ __html: content }} /> */}

      <div>
        {content}
      </div>
    </section>
  );
};

export default BlogContent;
