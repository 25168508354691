import React, { useState, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import constants from "../../../Utils/constants";
import { ClipLoader } from "react-spinners";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { api } from "../../../customAxios";
import masterCardIcon from "../../../assets/images/master-card-icon.svg";
import visaCardIcon from "../../../assets/images/visa-card-icon.svg";
import axios from "axios";
const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: "16px",
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    []
  );

  return options;
};

function TrialSignUp() {
  const [isChecked, setIsChecked] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [isContinue, setIsContinue] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCoupon, setIsLoadingCoupon] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [couponMessage, setCouponMessage] = useState("");
  const [couponCode, setCouponCode] = useState();
  const goToLoginPage = () => {
    navigate("/signin");
  };
  const userDetailValidationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Please enter valid name")
      .matches(/^\w+/, "Please enter valid name")
      .max(128, "The name cannot be more than 128 characters"),
    email: Yup.string()
      .required("Please enter valid email address")
      .matches(constants.EMAIL_REGEX, "Please enter valid email address"),
    password: Yup.string()
      .required("Please enter your password")
      .min(6, "The password must be at least 6 characters"),
    password_confirmation: Yup.string()
      .required("Please confirm your password")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
    company_name: Yup.string()
      .required("Please enter your company name")
      .max(128, "Exceeded maximum character length of 128"),
  });

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(userDetailValidationSchema),
    mode: "onChange",
    defaultValues: {},
  });
  const watchFields = watch(["coupon"]);
  const handleUserDetailsSubmit = () => {
    if (!isChecked) {
      setErrorMessage("Please accept terms and conditions");
      return;
    }
    if (isValid) {
      setIsContinue(true);
    }
  };

  const handleCardDetailsSubmit = async () => {
    setIsLoading(true);
    if (!stripe || !elements) {
      setIsLoading(false);
      return;
    }
    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) {
      setIsLoading(false);
      return;
    }
    const { error: tokenError, token } = await stripe.createToken(cardElement);
    if (tokenError) {
      toast.error(tokenError.message);
      setIsLoading(false);
      return;
    }
    const formValues = getValues();
    const formData = new FormData();
    formData.append("name", formValues.name);
    formData.append("email", formValues.email);
    formData.append("password", formValues.password);
    formData.append("password_confirmation", formValues.password_confirmation);
    formData.append("company_name", formValues.company_name);
    if (couponCode) {
      formData.append("coupon_code", couponCode);
    }
    formData.append("token", token.id);
    formData.append("type", 0);
    try {
      const apiResponse = await api.post(`/auth/trial-signup`, formData);
      toast.success(apiResponse?.data?.message);
      reset();
      goToLoginPage();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setErrorMessage("");
  };
  const handleCouponValidation = async (e) => {
    e.preventDefault();
    setIsLoadingCoupon(true);
    const formValues = getValues();
    const formData = new FormData();
    formData.append("code", formValues.coupon);
    formData.append("type", 0);
    try {
      const apiResponse = await api.post(`/validate-coupon`, formData);
      setCouponMessage(apiResponse?.data?.error || apiResponse?.data?.success);
      if (apiResponse?.data?.success) {
        setCouponCode(formValues.coupon);
      }
    } catch (error) {
      toast.error("Oops! Something went wrong.");
    } finally {
      setIsLoadingCoupon(false);
    }
  };
  return (
    <div className="login__card">
      <h4 className="text-center mb-2">
        {isContinue ? "Credit Card Details" : "Start Your Trial"}
      </h4>
      {isContinue ? (
        <>
          <div className="d-flex mb_12">
            <img src={masterCardIcon} alt="" />
            <img src={visaCardIcon} alt="" className="ms-2" />
          </div>
          <div>
            <label className="form-label form-label__email">Card Number</label>
            <CardNumberElement options={options} />
          </div>
          <div className="d-flex gap-2">
            <div className="w-50">
              <label className="form-label form-label__email">
                Expiration Year
              </label>
              <CardExpiryElement options={options} />
            </div>
            <div className="w-50">
              <label className="form-label form-label__email">CVC</label>
              <CardCvcElement options={options} />
            </div>
          </div>
          <div>
            <label className="form-label form-label__email">Coupon Code</label>
            <div className={`d-flex gap-2  ${couponMessage ? "mb_15" : ""}`}>
              <div className="w-75">
                <input
                  type="text"
                  className={`form-control form-control__input card-detail-input f-16 w-100 `}
                  placeholder="Coupon code"
                  {...register("coupon")}
                />
              </div>
              <button
                type="button"
                className={`btn btn-primary w-25`}
                onClick={handleCouponValidation}
                disabled={isLoadingCoupon || !watchFields[0]}
              >
                {isLoadingCoupon ? (
                  <ClipLoader color="#fff" size={20} />
                ) : (
                  "Apply"
                )}
              </button>
            </div>
          </div>
          {couponMessage && (
            <span
              className={`${
                couponMessage === "Coupon not valid."
                  ? "danger-color"
                  : "success-color"
              } error-msg mb-0 `}
            >
              <span>{couponMessage}</span>
            </span>
          )}
          <p className="mb-0 f-14 mt-10">
            Start your trial today! Enjoy 15 days of full access to all Pro Plan
            features.
          </p>
          <div className="d-flex justify-content-between">
            <button
              type="button"
              onClick={() => setIsContinue(false)}
              className="btn btn-cancel btn-sm mt-3"
              disabled={isLoading}
            >
              Back
            </button>
            <button
              type="button"
              className={`btn btn-primary btn-sm mt-3 ${
                isLoading ? "load-submit-btn" : ""
              }`}
              onClick={handleCardDetailsSubmit}
              disabled={isLoading}
            >
              {isLoading ? (
                <ClipLoader color="#fff" size={20} />
              ) : (
                "Start Trial"
              )}
            </button>
          </div>
        </>
      ) : (
        <form className="form">
          <div>
            <label className="form-label form-label__email">Name</label>
            <input
              type="text"
              className={`form-control form-control__input f-12 w-100 ${
                errors.name ? "is-invalid_boder" : ""
              }`}
              placeholder="Enter name"
              {...register("name")}
            />
            <span className="danger-color error-msg">
              <span>{errors.name && errors.name.message}</span>
            </span>
          </div>
          <div>
            <label className="form-label form-label__email">Email</label>
            <input
              type="text"
              className={`form-control form-control__input f-12 w-100 ${
                errors.email ? "is-invalid_boder" : ""
              }`}
              placeholder="Enter email address"
              {...register("email")}
            />
            <span className="danger-color error-msg">
              <span>{errors.email && errors.email.message}</span>
            </span>
          </div>
          <div className="password_input">
            <label className="form-label form-label__email">Password</label>
            <input
              type={showPassword ? "text" : "password"}
              className={`form-control form-control__input f-12 w-100 ${
                errors.password ? "is-invalid_boder" : ""
              }`}
              placeholder="Enter password"
              {...register("password")}
            />
            <button
              type="button"
              className="password-toggle-button"
              onClick={() => setShowPassword(!showPassword)}
            >
              <FontAwesomeIcon
                icon={`fas ${showPassword ? "fa-eye" : "fa-eye-slash"}`}
              />
            </button>
            <span className="danger-color error-msg">
              <span>{errors.password && errors.password.message}</span>
            </span>
          </div>
          <div className="password_input">
            <label className="form-label form-label__email">
              Confirm Password
            </label>
            <input
              type={showConfirmPassword ? "text" : "password"}
              className={`form-control form-control__input f-12 w-100 ${
                errors.password_confirmation ? "is-invalid_boder" : ""
              }`}
              placeholder={`${
                errors.password_confirmation?.message
                  ? errors.password_confirmation?.message
                  : "Password confirmation"
              }`}
              {...register("password_confirmation")}
            />
            <button
              type="button"
              className="password-toggle-button"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              <FontAwesomeIcon
                icon={`fas ${showConfirmPassword ? "fa-eye" : "fa-eye-slash"}`}
              />
            </button>
            <span className="danger-color error-msg">
              <span>
                {errors.password_confirmation &&
                  errors.password_confirmation.message}
              </span>
            </span>
          </div>
          <div>
            <label className="form-label form-label__name">Company Name</label>
            <input
              type="text"
              className={`form-control form-control__input f-12 w-100 ${
                errors.company_name ? "is-invalid_boder" : ""
              }`}
              placeholder="Enter company name"
              {...register("company_name")}
            />
            <span className="danger-color error-msg">
              <span>{errors.company_name && errors.company_name.message}</span>
            </span>
          </div>
          <div className="mb-0 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <label
              className="form-check-label custom-font-size-12"
              htmlFor="exampleCheck1"
            >
              I agree to your <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">terms</Link> and{" "}
              <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">privacy policy</Link>
            </label>
          </div>
          <span className="danger-color small-text-font">{errorMessage}</span>
          <button
            type="button"
            onClick={handleSubmit(handleUserDetailsSubmit)}
            className="btn btn-primary all-btn-padding mt-3 w-100"
          >
            Continue
          </button>
          <p
            onClick={goToLoginPage}
            className="text-center custom-font-size-14 mt-4"
          >
            Already have an account? <a href="#">Sign In</a>
          </p>
        </form>
      )}
    </div>
  );
}

export default TrialSignUp;
