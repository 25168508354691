import React, { useEffect } from "react";
import Zakria from "../../../assets/images/Zakria.png";
import AwaisA from "../../../assets/images/AwaisA.png";
import Faisal from "../../../assets/images/Faisal.png";
import Cap from "../../../assets/images/Cap.png";
import Twitter from "../../../assets/images/twitter.svg";
import LinkedIn from "../../../assets/images/linkedin.svg";
import Pintrest from "../../../assets/images/pinterest.svg";
import Facebook from "../../../assets/images/facebook.svg";
import { useInView } from "react-intersection-observer";

export default function AboutContent() {
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  useEffect(() => {
    if (inView) {
      document.body.classList.add("hide-scrollbar");
    } else {
      document.body.classList.remove("hide-scrollbar");
    }

    return () => {
      document.body.classList.remove("hide-scrollbar");
    };
  }, [inView]);

  return (
    <div className="about-content">
      <h2 className="text-center responsive_heading">About Us</h2>
      <br />

      <div className="container__land">
        <div className={`row ${inView ? "animate__animated animate__fadeInUp" : ""}`} ref={ref}>
          <div className="col-md-1"></div>
          <div className="col-md-10">
            <p className="big-para text-center responsive_text">
              At Scrum Slate, our mission is to develop a tool that is
              specifically dedicated to Agile Project Management teams. We
              understand it is harder to achieve the target goals when you don’t
              have the right tool. With Scrum Slate you get the right features
              that support sprint planning, backlog management, bug tracking and
              iterative development cycles to ensure your team stays on track.
            </p>
            <p className="big-para short-para text-center responsive_text">
              Moreover, we understand every team has unique needs and his own
              set of challenges. That’s why we have made Scrum Slate highly
              customizable, allowing the team to adapt the tool according to
              their workspace and preferences. That’s not all, our advanced
              algorithms analyze user inputs and generate concise, accurate, AI
              based descriptions, saving valuable time and ensuring clarity
              throughout the project lifecycle.
            </p>
          </div>
          <div className="col-md-1"></div>
          <br />

          {/* <div className="col-md-3 mt-5">
            <div class="card pb-4 barder">
              <img src={Zakria} className="images" />
              <div class="card-body text-center cad-paras">
                <h5 class="card-title">Liaqat Ali</h5>
                <p class="card-text paras">Back-end Developer</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mt-5">
            <div class="card pb-4 barder">
              <img src={AwaisA} className="images" />
              <div class="card-body text-center cad-paras">
                <h5 class="card-title">Hussnain Ali</h5>
                <p class="card-text paras">Graphic Designer</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mt-5">
            <div class="card pb-4 barder">
              <img src={Faisal} className="images" />
              <div class="card-body text-center cad-paras">
                <h5 class="card-title">Nauman Ali</h5>
                <p class="card-text paras">Frontend Developer</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mt-5">
            <div class="card pb-4 barder">
              <img src={Cap} className="images" />
              <div class="card-body text-center cad-paras">
                <h5 class="card-title">Zakriya</h5>
                <p class="card-text paras">Frontend Developer</p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
