import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, LinearProgress } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import AudioRecorder from "../../componets/comman/AudioRecorder";
import ScreenRecorder from "../../componets/comman/ScreenRecorder";
import { api } from "../../customAxios";
import ConfirmDialog from "./ConfirmDialog";
function MediaRecordingModalWindow(props) {
  const {
    openRecordModal,
    setOpenRecordModal,
    mediaType,
    isGlobal,
    setFileTitle,
    fileTitle,
    taskDetail,
    refreshMediaFiles,
    loggedUserId,
  } = props;
  const [apiProgress, setApiProgress] = useState(0);
  const [audioVideoData, setAudioVideoData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [waitUpload, setWaitUpload] = useState(false);
  const [stopRecording, setStopRecording] = useState(false);
  const handleCloseRecordModal = () => {
    if (isLoading) {
      setStopRecording(true);
    } else {
      setAudioVideoData(null);
      setOpenRecordModal(false);
    }
  };
  const handleGetAudio = (data) => {
    setAudioVideoData(data);
  };
  const confirmTheFileUpload = (formData) => {
    api
      .post("/confirm-the-file-upload", formData)
      .then((resp) => {
        localStorage.setItem('permissions', JSON.stringify(resp.data.permissions))
        if (resp?.data?.file) {
          const dateModifiedFile = {
            ...resp.data.file,
            file_created_at: resp.data.file.created_at,
          };
          refreshMediaFiles(
            "add",
            isGlobal ? dateModifiedFile : resp.data.file
          );
        }
        toast.success("File uploaded successfully!");
        setIsLoading(false);
        setWaitUpload(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setWaitUpload(false);
      });
  };
  const handleSaveAudioVideo = async () => {
    setWaitUpload(true);
    setIsLoading(true);
    let formData = new FormData();
    formData.append("file_name", audioVideoData.name);
    formData.append("file_title", fileTitle);
    formData.append("model_id", isGlobal ? loggedUserId : taskDetail.task_id);
    formData.append("model_name", isGlobal ? "User" : "Task");
    formData.append("file_type", audioVideoData?.name?.split(".").pop());
    const axiosInstance = axios.create();
    axiosInstance.interceptors.request.use((config) => {
      config.onUploadProgress = (progressEvent) => {
        const progressPercentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setApiProgress(progressPercentage);
      };
      return config;
    });

    try {
      const response = await api.post("/upload-file", formData);
      await axiosInstance.put(response.data.upload_url, audioVideoData, {
        headers: {
          "Content-Type": audioVideoData.type,
        },
      });
      localStorage.setItem('permissions', JSON.stringify(response?.data?.permissions))

      await confirmTheFileUpload(formData);
      setFileTitle("");
      setApiProgress(0);
    } catch (error) {
      console.error("Error during file upload:", error);
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        console.error("Failed to upload file:", error);
      }
    } finally {
      setWaitUpload(false);
      setOpenRecordModal(false);
      setIsLoading(false);
    }
  };

  const handleStopRecording = () => {
    setAudioVideoData(null);
    setOpenRecordModal(false);
    setStopRecording(false);
    setIsLoading(false);
  };
  return (
    <>
      <Modal
        size="lg"
        show={openRecordModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="pt-5 pl-5 add-member_modal"
        style={{ zIndex: "1500" }}
      >
        <div className="container">
          <div className="main-modal">
            <Modal.Header className="project-modal_header">
              <Modal.Title>
                {mediaType === "audio"
                  ? "Record voice message!"
                  : "Record video message!"}
              </Modal.Title>
              <FontAwesomeIcon
                icon={faClose}
                onClick={handleCloseRecordModal}
              />
            </Modal.Header>
            <Modal.Body className="project-modal_body">
              <div className="fields">
                <div className="row">
                  {mediaType === "audio" ? (
                    <AudioRecorder
                      handleGetAudio={handleGetAudio}
                      setIsLoading={setIsLoading}
                      setFileTitle={setFileTitle}
                      fileTitle={fileTitle}
                    />
                  ) : (
                    <ScreenRecorder
                      handleGetAudio={handleGetAudio}
                      setIsLoading={setIsLoading}
                      setFileTitle={setFileTitle}
                      fileTitle={fileTitle}
                    />
                  )}
                </div>
                {apiProgress > 0 && (
                  <Box className="linearProgress">
                    <LinearProgress variant="determinate" value={apiProgress} />
                  </Box>
                )}

                <div className="actions">
                  <button
                    className="btn btn-cancel btn-sm"
                    onClick={handleCloseRecordModal}
                  >
                    Cancel
                  </button>
                  <button
                    className={`btn btn-primary btn-sm ${isLoading ? "pd-edit-sprint-load" : ""
                      }`}
                    disabled={!isLoading || waitUpload}
                    onClick={handleSaveAudioVideo}
                  >
                    {waitUpload ? (
                      <ClipLoader color="#ffffff" size={20} />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </Modal.Body>
          </div>
        </div>
      </Modal>
      <ConfirmDialog
        open={stopRecording}
        setOpen={setStopRecording}
        dialogTitle="Are you sure you?"
        handleConfirmYes={handleStopRecording}
      />
    </>
  );
}

export default MediaRecordingModalWindow;
