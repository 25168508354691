import React, { useEffect } from "react";
import Task from "../../../assets/images/feature-backlog-img.svg";
import Team from "../../../assets/images/Screen-sahring-img.svg";
import Conversation from "../../../assets/images/feature-delivery-img.svg";
import Bug from "../../../assets/images/Bug.png";
import { useInView } from "react-intersection-observer";

export default function Features() {
  const { ref, inView } = useInView({
    threshold: 0.4,
    triggerOnce: true
  });

  return (
    <div>
      <section className="features">
        <div className="container__land">
          <h2 className="text-center responsive-heading responsive_heading">Our Features</h2>
          <div className={`row landing_page_row`} ref={ref} style={{ visibility: inView ? 'visible' : 'hidden', opacity: inView ? 1 : 0 }}>
            <div className={`col-md-3 col-sm-6 col-sx-12 mb-3 ${inView ? "animate__animated animate__fadeInLeft" : ""}`}>
              <div className="card feature-cards ">
                <div className="feature_card_image text-center mt-4">
                  <img src={Task} className="images" alt="Task" />
                </div>
                <div className="card-body text-center">
                  <h5 className="card-title responsive_small_heading">Backlog Management</h5>
                  <p className="card-text paragraph responsive_text">
                    Create and prioritize features and enhancements for upcoming
                    sprints
                  </p>
                </div>
              </div>
            </div>
            <div className={`col-md-3 col-sm-6 col-sx-12 mb-3 ${inView ? "animate__animated animate__fadeInLeft" : ""}`}>
              <div className="card feature-cards carding ">
                <div className="feature_card_image colrd text-center mt-4 screen-sharing-bg">
                  <img src={Team} className="images" alt="Team" />
                </div>
                <div className="card-body text-center">
                  <h5 className="card-title responsive_small_heading">Screen Sharing</h5>
                  <p className="card-text paragraph responsive_text">
                    Effortlessly record video messages and share with your team
                  </p>
                </div>
              </div>
            </div>
            <div className={`col-md-3 col-sm-6 col-sx-12 mb-3 ${inView ? "animate__animated animate__fadeInRight" : ""}`}>
              <div className="card feature-cards ">
                <div className="feature_card_image text-center mt-4">
                  <img
                    src={Conversation}
                    className="images"
                    alt="Conversation"
                  />
                </div>
                <div className="card-body text-center">
                  <h5 className="card-title responsive_small_heading">Delivery Tracking</h5>
                  <p className="card-text paragraph responsive_text">
                    Set goals, track team velocity, predict the release and see
                    if you’re on track or not
                  </p>
                </div>
              </div>
            </div>
            <div className={`col-md-3 col-sm-6 col-sx-12 mb-3 ${inView ? "animate__animated animate__fadeInRight" : ""}`}>
              <div className="card feature-cards bugs ">
                <div className="feature_card_image clor text-center mt-4">
                  <img src={Bug} className="images" alt="Bug" />
                </div>
                <div className="card-body text-center">
                  <h5 className="card-title responsive_small_heading">Bug Reporting</h5>
                  <p className="card-text paragraph responsive_text">
                    Quickly identify, prioritize and document relative bugs
                    within the tasks
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
