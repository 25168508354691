import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { toast } from "react-toastify";
import {
  getFileName,
  getTimeAgo,
  handleDownloadDocument,
  renderFileIcon,
  shortenFileName,
} from "../../../../Utils/helpers";
import "../../../../assets/scss/Dashboard.scss";
import "../../../../assets/scss/Modal.scss";
import {
  handleDeleteMediaFile,
  handleUploadAttachment,
  refreshFiles,
} from "../../../../componets/comman/CommonFunction/MediaFileCommonFunction";
import {
  closeLightBox,
  launchLightBox,
} from "../../../../componets/comman/CommonFunction/TaskFunctions";
import FileViewBox from "../../../../componets/comman/Projects/FileLightBox";
import ConfirmDialog from "../../../../componets/shared/ConfirmDialog";
import { api } from "../../../../customAxios";

function TaskAttachments(props) {
  const { taskDetail, isSubTask, attachmentFile, setProgress } = props;
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [openLightBox, setOpenLightBox] = useState(false);
  const [file, setFile] = useState({});
  const [lightBoxImages, setLightBoxImages] = useState([]);
  const attachmentRef = useRef();

  useEffect(() => {
    if (taskDetail?.task_id) {
      getAttachmentFiles();
    }
  }, []);

  useEffect(() => {
    if (attachmentFile) {
      setAttachmentFiles((prevAttachmentFiles) => [
        ...prevAttachmentFiles,
        attachmentFile,
      ]);
    }
  }, [attachmentFile]);

  const handleOpenLightBox = (file) => {
    launchLightBox(file, attachmentFiles, setOpenLightBox, setLightBoxImages);
  };

  const handleCloseLightBox = () => {
    closeLightBox(setLightBoxImages, setOpenLightBox);
  };

  const getAttachmentFiles = async () => {
    const resp = await api.get(`/tasks/${taskDetail?.task_id}/files`);
    setAttachmentFiles(resp.data);
  };

  const refreshMediaFiles = (action, fileData) => {
    refreshFiles(
      action,
      fileData,
      "general",
      setAttachmentFiles,
      attachmentFiles
    );
  };

  const ValidateSingleInput = async (event) => {
    handleUploadAttachment(
      event,
      attachmentRef,
      setProgress,
      refreshMediaFiles,
      taskDetail?.task_id,
      "Task"
    );
  };

  const handleOpenDeleteFile = (file) => {
    setFile(file);
    setOpen(true);
  };

  const handleDeleteFile = () => {
    handleDeleteMediaFile(
      setOpen,
      setProgress,
      refreshMediaFiles,
      null,
      null,
      file?.id
    );
  };

  const downloadAttachDocument = async (file) => {
    handleDownloadDocument(file);
    toast.success("File downloaded successfully!");
  };

  return (
    <div className="box" id="bx">
      <div className="">
        <div className="box-head zack mt-4">
          <h5 className="text-secondary">Attachments</h5>
          <label
            htmlFor={isSubTask ? "subTaskFileInput" : "fileInput"}
            className={`text-primary fs-12 ${taskDetail?.userPermission?.can_i_edit
              ? "cursor_pointer"
              : "cursor_not_allowed"
              }`}
            style={{
              color: taskDetail?.userPermission?.can_i_edit ? "blue" : "gray",
            }}
          >
            + Add Files
            {taskDetail?.userPermission?.can_i_edit && (
              <input
                id={isSubTask ? "subTaskFileInput" : "fileInput"}
                type="file"
                name="file"
                multiple
                style={{ display: "none" }}
                onChange={ValidateSingleInput}
                ref={attachmentRef}
              />
            )}
          </label>
        </div>

        {attachmentFiles && attachmentFiles.length > 0 ? (
          <div className="file_row  mt-3">
            {attachmentFiles
              ?.slice()
              .reverse()
              .map((file, i) => (
                <div className="file_divs viewtask-files mb-3" key={i}>
                  <div className="file_main_section">
                    <div
                      className="mic-vid-icon add_cursor"
                      style={{ marginTop: "10px !important" }}
                      onClick={() => handleOpenLightBox(file)}
                    >
                      <div className="imgs p-2 h-100">
                        {renderFileIcon(file, true)}
                      </div>
                    </div>

                    <div className="file_main_section_left">
                      <p
                        className="m-b-10 add_cursor"
                        onClick={() => handleOpenLightBox(file)}
                      >
                        {shortenFileName(getFileName(file?.name))}
                      </p>
                      <div className="d-flex justify-content-between mt-4px">
                        <div className="d-flex">
                          <p className="time mt-2">
                            {" "}
                            {getTimeAgo(file.created_at)}{" "}
                          </p>
                        </div>
                        <Dropdown>
                          <div className="option-icon">
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              className="button_style"
                            >
                              <FontAwesomeIcon icon={faEllipsisV} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => downloadAttachDocument(file)}
                              >
                                Download
                              </Dropdown.Item>
                              {taskDetail?.userPermission?.can_i_delete && (
                                <Dropdown.Item
                                  onClick={() => handleOpenDeleteFile(file)}
                                >
                                  Delete
                                </Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </div>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <p className="text-muted fs-6 mt-2 mb-2">No attachments.</p>
        )}
        <FileViewBox
          images={lightBoxImages}
          isOpen={openLightBox}
          onClose={handleCloseLightBox}
        />
      </div>
      <ConfirmDialog
        open={open}
        setOpen={setOpen}
        dialogTitle="Are you sure?"
        handleConfirmYes={handleDeleteFile}
      />
    </div>
  );
}

export default TaskAttachments;
