import React from "react";
const TestCaseStatus = ({
  isView,
  status,
}) => {
  const getStatusClass = () => {
    const statusName = status || "NA";
    if (statusName !== "NA") {
      return `case-${statusName.toLowerCase()}`;
    } else {
      return "case-blocked";
    }
  };

  return (
    <div className={`${isView ? "" : "task_items_progress"} testCase-status`}>
      <span
        className={`task_items_list  ${getStatusClass()} ${isView ? "custom_select_view status" : ""
          }`}
      >
        {status || "NA"}
      </span>
    </div>
  );
};

export default TestCaseStatus;
