import React from "react";
import { Link, useLocation } from "react-router-dom";
import { scrollToTop } from "../../Utils/helpers";
import Facebook from "../../assets/images/facebook.svg";
import Level from "../../assets/images/level.svg";
import LinkedIn from "../../assets/images/linkedin.svg";
import Privacy from "../../assets/images/privacy.svg";
import Traning from "../../assets/images/traning.svg";
export default function Footer(props) {
  const location = useLocation();
  const isExtention = location.pathname === "/extensions";
  const getExtainshion = () => {
    window.open('https://chromewebstore.google.com/detail/scrum-slate-%E2%80%93-screen-reco/fieiojigoecblnfonmifhapdmihdcdjo?hl=en-GB&utm_source=ext_sidebar')
  }
  return (
    <div className={`${isExtention ? "extendedfooter" : ""}`}>
      {isExtention && (
        <div className="pt-5 pb-3">
          <div className="container__land extension_footer_container">
            <div className="row ">
              <div className="col-md-4 col-12">
                <div className="service_box">
                  <img src={Traning} alt="free training" className="me-1" />
                  <span className="services_text"><span className="fw-bolder">Free training</span> & 24-hours support </span>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="service_box">
                  <img src={Privacy} alt="security & privacy" className="me-1" />
                  <span className="services_text">Serious about <span className="fw-bolder">security & privacy </span> </span>
                </div>
              </div>
              <div className="col-md-4 col-12 ">
                <div className="service_box">
                  <img src={Level} alt="Highest level" className="me-1 level_img" />
                  <span className="services_text"><span className="fw-bolder">Highest levels of uptime </span> the last 12 months</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
      }
      <div
        className={`footer_wrapper linear-bg py-5 ${isExtention ? "bg_unset" : ""
          }`}
      >
        <div className="footer_content_wrapper">
          <div className="prob_padding">
            <div className="container__land">
              <div className="row ">
                <div className="col-md-3 col-sm-3 col-sx-12">
                  <img className="cursor_pointer" src={process.env.REACT_APP_BRAND_LOGO} />
                  <p className="logo_para responsive_text mt-4">
                    Discover a tool designed to transform your vision into
                    reality, one step at a time.
                  </p>
                  <div className="d-flex mt-4 ad-mrgin">
                    <a
                      href="https://www.facebook.com/scrumslate1/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn zack mx-1"
                    >
                      <img className="mx-1" src={Facebook} alt="Facebook" />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/scrum-slate/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn zack mx-1"
                    >
                      <img className="mx-1" src={LinkedIn} alt="LinkedIn" />
                    </a>
                  </div>
                </div>
                <div className="col-md-3 col-sm-3 col-sx-12 footer_col_four">
                  <h4 className="responsive_small_heading">Links</h4>
                  <ul className="list-unstyled">
                    <li className="responsive_text">
                      <Link to="/" onClick={scrollToTop} className="homes">
                        Home
                      </Link>
                    </li>
                    <li className="responsive_text">
                      <Link to="/features">Features</Link>
                    </li>
                    <li className="responsive_text">
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li className="responsive_text" onClick={getExtainshion}>
                      <Link to="#">
                        Extension
                      </Link>
                    </li>
                    <li className="responsive_text">
                      <a href="#">Mobile App</a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-3 col-sm-3 col-sx-12 footer_col_four fits">
                  <h4 className="responsive_small_heading">Go Agile</h4>
                  <ul className="list-unstyled">
                    <li className="responsive_text">
                      <Link to="/what-is-agile">What is Agile?</Link>
                    </li>
                    <li className="responsive_text">
                      <Link to="/scrum-vs-kanban">Scrum VS Kanban</Link>
                    </li>
                    <li className="responsive_text">
                      <Link to="/backlog-refinement">Backlog Refinement</Link>
                    </li>
                    <li className="responsive_text">
                      <Link to="/sprint-planing">Sprint Planing</Link>
                    </li>
                    <li className="responsive_text">
                      <Link to="./bug-tracking">Bug Tracking</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-3 col-sm-3  col-sx-12 footer_col_four rutes">
                  <h4 className="responsive_small_heading">Resources</h4>
                  <ul className="list-unstyled">
                    <li className="responsive_text">
                      <Link to="/contactus">Contact Us</Link>
                    </li>
                    <li className="responsive_text">
                      <Link to="/latest-update">Latest Updates</Link>
                    </li>
                    <li className="responsive_text">
                      <a href="#">Help Doc</a>
                    </li>
                    <li className="responsive_text">
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li className="responsive_text">
                      <Link to="/pricing">Pricing</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}
