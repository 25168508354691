import React, { useEffect } from "react";
import All from "../../../assets/images/people11.png";
import { useInView } from "react-intersection-observer";

export default function Reporting() {

  const { ref: parent1Ref, inView: parent1InView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const { ref: parent2Ref, inView: parent2InView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  const { ref: parent3Ref, inView: parent3InView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  useEffect(() => {
    if (parent1InView || parent2InView || parent3InView) {
      document.body.classList.add("hide-scrollbar");
    } else {
      document.body.classList.remove("hide-scrollbar");
    }

    return () => {
      document.body.classList.remove("hide-scrollbar");
    };
  }, [parent1InView, parent2InView, parent3InView]);

  return (
    <div>
      <section className="project bgs mb-5 mt-0 feature-Smart-div">
        <div className="container__fearter container-m">
          <div className="text-center" ref={parent1Ref}>
            <h3 className="reports mian-text responsive_heading">Smart Tools & Integrations</h3>
          </div>
          <div className="row mt-5 reporting_row align-items-center">
            <div className={`col-md-6 cads__colmn feature-Smart-T ${parent3InView || parent1InView ? "animate__animated animate__fadeInLeft" : ""}`}>
              <div className="crop-container img-iss">
                <img
                  src={All}
                  alt="Cropped Image"
                  className="cropped-image mt-2"
                />
                {/* <div className="image"></div> */}
              </div>
            </div>

            <div className="col-md-6 cads__width">
              <div className={`cads new ${parent1InView ? "animate__animated animate__fadeInRight" : ""}`} style={{ visibility: parent1InView ? 'visible' : 'hidden', opacity: parent1InView ? 1 : 0 }}>
                <p className="paragraph responsive_small_heading">
                  <b>AI:</b>
                </p>
                <p className="pargraph-body responsive_text mt-2">
                  Use AI to generate tasks from audio and video messages. Refine
                  task detail and generate description with title.
                </p>
              </div>
              <br />
              <div className={`cads reporting_card ${parent2InView ? "animate__animated animate__fadeInRight" : ""}`} ref={parent2Ref} style={{ visibility: parent2InView ? 'visible' : 'hidden', opacity: parent2InView ? 1 : 0 }}>
                <p className="paragraph responsive_small_heading">
                  <b>Calendar:</b>
                </p>
                <p className="pargraph-body responsive_text mt-2">
                  Keep track of important dates, such as sprint start and end
                  dates, task due dates, and team meetings for better project
                  planning and coordination.
                </p>
              </div>
              <br />
              <div className={`cads new ${parent3InView ? "animate__animated animate__fadeInRight" : ""}`} ref={parent3Ref} style={{ visibility: parent3InView ? 'visible' : 'hidden', opacity: parent3InView ? 1 : 0 }}>
                <p className="paragraph responsive_small_heading">
                  <b>Kanban Boards:</b>
                </p>
                <p className="pargraph-body responsive_text mt-2">
                  Organize tasks into columns representing different stages of
                  progress, such as "To Do," "In Progress," and "Done," for
                  improved progress tracking.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
