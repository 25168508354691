import React, { useEffect } from "react";
import Graf from "../../../assets/images/graph.png";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";

export default function Graph() {
  const navigate = useNavigate();

  function signUp() {
    window.scrollTo(0, 0);
    navigate("/signup");
  }

  const { ref: parent1Ref, inView: parent1InView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const { ref: parent2Ref, inView: parent2InView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const { ref: parent3Ref, inView: parent3InView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  useEffect(() => {
    if (parent1InView || parent2InView || parent3InView) {
      document.body.classList.add("hide-scrollbar");
    } else {
      document.body.classList.remove("hide-scrollbar");
    }

    return () => {
      document.body.classList.remove("hide-scrollbar");
    };
  }, [parent1InView, parent2InView, parent3InView]);

  return (
    <div>
      <section className={`project_manage_section pb-5`}>
        <div className="container__land mb-5">
          <div className="row g-3 align-items-center landing_page_row">
            <div className="col-md-6 col-sm-12 col-xs-12 image_column">
              <div className="project_manage_image_section">
                <img src={Graf} className="graph_img" />
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12 manage_project_column" ref={parent1Ref}>
              <div className="project_manage_contact_section">
                <h3 className="project_manage_heading responsive_heading">
                  Perfect Management Tool For Agile Teams
                </h3>
                <p className={`project_manage_paragraph mt-3 responsive_text ${parent1InView ? "animate__animated animate__fadeInRight" : ""}`}>
                  Scrumslate enables you to focus on your project instead of getting lost in a complex tool with a lot of features. Discover the features that are designed to effortlessly plan, manage, track and release your products.
                </p>
                <br />
                <div className="project_details_lists" ref={parent2Ref} style={{ visibility: parent2InView ? 'visible' : 'hidden', opacity: parent2InView ? 1 : 0 }}>
                  <div className="row landing_page_row">
                    <div className={`col-md-6 col-sm-6 col-xs-6 ${parent2InView ? "animate__animated animate__fadeInLeft" : ""}`}>
                      <div className="list_contant">
                        <h5 className="responsive_text">Overview</h5>
                        <span className="text-paragraph responsive_text">Get quick access to recent activities in the project</span>
                      </div>
                    </div>
                    <div className={`col-md-6 col-sm-6 col-xs-6 ${parent2InView ? "animate__animated animate__fadeInRight" : ""}`}>
                      <div className="list_contant">
                        <h5 className="responsive_text">Kanban Boards</h5>
                        <span className="text-paragraph responsive_text">Custom boards to view tasks progress more comprehensively</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="project_details_lists" ref={parent3Ref} style={{ visibility: parent3InView ? 'visible' : 'hidden', opacity: parent3InView ? 1 : 0 }}>
                  <div className="row landing_page_row">
                    <div className={`col-md-6 col-sm-6 col-xs-6 ${parent3InView ? "animate__animated animate__fadeInLeft" : ""}`}>
                      <div className="list_contant">
                        <h5 className="responsive_text">Sprints</h5>
                        <span className="text-paragraph responsive_text">Effortlessly create, plan, and review sprints</span>
                      </div>
                    </div>
                    <div className={`col-md-6 col-sm-6 col-xs-6 ${parent3InView ? "animate__animated animate__fadeInRight" : ""}`}>
                      <div className="list_contant">
                        <h5 className="responsive_text">Reports</h5>
                        <span className="text-paragraph responsive_text">Track your team's progress with insightful reports</span>
                      </div>
                    </div>
                  </div>
                </div>
                <button className="btn btn-primary fix-buton mt-2 manage_project_button" onClick={signUp}>
                  Get Started
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
