import { Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import {
  formatName,
  fullDateFormate,
  scrollToTop,
} from "../../../Utils/helpers";
import CustomAvatar from "../../../componets/comman/Task/CustomAvatar";
import { api } from "../../../customAxios";
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function FileViewCommentBox({ fileName, fileComments }) {
  const [isLoading, setIsLoading] = useState(false);
  const [commentMessage, setCommentMessage] = useState("");
  const commentBoxRef = useRef();
  const [comments, setComments] = useState([]);
  const [errors, setErrors] = useState({});
  const [expandedComments, setExpandedComments] = useState({});
  useEffect(() => {
    setComments(fileComments);
  }, [fileComments]);
  const toggleShowMore = (index) => {
    setExpandedComments((prevExpandedComments) => ({
      ...prevExpandedComments,
      [index]: !prevExpandedComments[index],
    }));
  };
  const handleCommentData = () => {
    if (!commentMessage) {
      setErrors({ message: "Message is required" });
      return;
    }
    setIsLoading(true);
    const isLoggedIn = localStorage.getItem("token");
    const newMessage = {
      message: commentMessage,
      file_name: fileName,
      ...(!isLoggedIn && { guest: 1 }),
    };
    api
      .post(`/add-comments`, newMessage)
      .then((resp) => {
        setComments([...comments, resp?.data?.comment]);
        toast.success("Comment posted successfully!");
        scrollToTop();
        setCommentMessage("");
      })
      .catch(() => {
        toast.error("Oops! something went wrong.");
      })
      .finally(() => {
        setIsLoading(false);
        if (commentBoxRef.current) {
          commentBoxRef.current.scrollTop = 0;
        }
      });
  };

  const FileComment = ({ comment, index }) => {
    const isExpanded = expandedComments[index];
    return (
      <div className="file_comment" key={index}>
        <div className="file_comment_user">
          {comment.created_by_image ? (
            <div
              className="size_cover user_comment"
              style={{
                backgroundImage: `url('${comment.created_by_image}')`,
              }}
            ></div>
          ) : (
            <CustomAvatar
              name={comment.created_by_name || ""}
              backgroundColor={comment.profile_color || "#005323"}
              borderRadius={6}
            />
          )}
        </div>
        <div className="file_comment_detail ms-2">
          <div className="d-flex align-items-center">
            <Tooltip
              title={capitalizeFirstLetter(comment.created_by_name)}
              arrow
              placement="top"
            >
              <span className="file_comment_userName textEllipse text-capitalize f-14 add_cursor">
                {formatName(comment.created_by_name)}
              </span>
            </Tooltip>
            <span className="file_comment_time ms-3 f-12">
              {fullDateFormate(comment.created_at)}
            </span>
          </div>
          <p className="file_comment_text mb-0 capitalize-first f-14">
            {isExpanded
              ? comment.message
              : comment.message.slice(0, 100) +
                (comment.message.length > 100 ? "..." : "")}
          </p>
          {comment.message.length > 100 && (
            <span
              className="link-color cursor-hover text-decoration-none d-block f-14 font-weight-600"
              onClick={() => toggleShowMore(index)}
            >
              {isExpanded ? "Show less" : "Show more"}
            </span>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="file-main-comment-box fileViewBoxesCommonStyle br_10">
        <h5>Comments</h5>
        <div
          className={`file-main-comment-box_body add_scroll `}
          ref={commentBoxRef}
        >
          {comments
            ?.slice()
            .reverse()
            .map((comment, index) => (
              <FileComment comment={comment} index={index} />
            ))}
        </div>
        <div className="file_comment_field_box ">
          <h5 className="text-secondary py-2">Post a comment</h5>
          <textarea
            className={`form-control form-control__input file_comment_field ${
              errors.message ? "is-invalid_boder" : "mb-3"
            }`}
            name="message"
            placeholder="Write your comment here ..."
            value={commentMessage}
            onChange={(e) => {
              setCommentMessage(e.target.value);
              if (e.target.value) {
                setErrors({});
              }
            }}
          ></textarea>
          {errors.message && (
            <span className="danger-color error-msg ">{errors.message}</span>
          )}
          <div className="file_comment_btn_box ">
            <button
              className={`btn btn-primary btn-sm ms-auto ${
                isLoading ? "pd-post-comment-load" : ""
              }`}
              disabled={isLoading}
              onClick={handleCommentData}
            >
              {isLoading ? (
                <ClipLoader color="#ffffff" loading={isLoading} size={20} />
              ) : (
                "Post Comment"
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default FileViewCommentBox;
