import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import {
  handleCopyFilePath,
  handleDownloadDocument,
} from "../../../Utils/helpers";
import CopyLinkIcon from "../../../assets/images/copylink.svg";
import DownloadIcon from "../../../assets/images/downloadIcon.svg";
import Footer from "../../../componets/MainLayout/Footer";
import LandingNavbar from "../../../componets/MainLayout/LandingNavbar";
import { MediaFilePlayer } from "../../../componets/comman/CommonFunction/MediaFileCommonFunction";
import FileViewLoader from "../../../componets/pageLoaders/publicPageLoaders/FileViewLoader";
import { api } from "../../../customAxios";
import FileViewCommentBox from "./FileViewCommentBox";
export default function FileView(props) {
  const { progress, setProgress } = props;
  const [file, setFile] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [copyIconTitle, setCopyIconTitle] = useState("Copy link");
  const [fileUrl, setFileUrl] = useState();
  let { id } = useParams();

  useEffect(() => {
    setIsLoading(true);
    const filename = atob(id);
    api
      .get(
        `/get-s3-file?file_name=${filename}&file_type=${filename
          ?.split(".")
          .pop()}`
      )
      .then((response) => {
        setFile(response?.data);
        setFileUrl(response?.data?.signed_url);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error getting signed URL:", error);
        setIsLoading(false);
      });
  }, [id]);

  const handleDownloadClick = () => {
    const fileToDownload = { name: fileUrl };
    handleDownloadDocument(fileToDownload, setProgress);
  };
  const handleCopyLink = () => {
    const fileToCopy = { name: atob(id) };
    handleCopyFilePath(fileToCopy);
    setCopyIconTitle("Copied");
    setTimeout(() => {
      setCopyIconTitle("Copy link");
    }, 2000);
  };
  return (
    <>
      <LoadingBar
        color="#0052cc"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        height={5}
      />
      <LandingNavbar />
      {isLoading ? (
        <FileViewLoader />
      ) : (
        <div className="file_view_main_container pb-5">
          <div className="file-view-content-box container__land">
            <div className="file-view-content fileViewBoxesCommonStyle br_10">
              <div className="file-view-content-head">
                <h4 className="textEllipse capitalize-first file_content_heading">{` ${file?.file_title}`}</h4>
                <div>
                  <Tooltip title={copyIconTitle} arrow placement="top">
                    <img
                      src={CopyLinkIcon}
                      className="add_cursor me-3"
                      onClick={handleCopyLink}
                      alt=""
                    />
                  </Tooltip>
                  <Tooltip title="Download" arrow placement="top">
                    <img
                      src={DownloadIcon}
                      className="add_cursor"
                      onClick={handleDownloadClick}
                      alt=""
                    />
                  </Tooltip>
                </div>
              </div>
              <div className="file_play_box">
                {MediaFilePlayer(file.playback_id, file.name)}
              </div>
            </div>
            <FileViewCommentBox
              fileName={atob(id)}
              fileComments={file?.file_comments}
            />
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}
