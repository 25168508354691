import React, { useEffect, useState } from "react";
import { editTask } from "../../Redux/Slice/Task";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

function TaskStoryPoint(props) {
  const {
    taskDetail,
    islist,
    refreshStorypoint,
    taskListId,
    sprintId,
    isSubtaskview,
    refreshPage,
    projectId,
    setProgress,
    isUserProfile,
  } = props;
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [task, setTask] = useState();
  const [storyPoint, setStoryPoint] = useState("");
  const [prevStoryPoint, setPrevStoryPoint] = useState("");

  useEffect(() => {
    setStoryPoint(taskDetail.task_story_point);
    setTask(taskDetail);
    setPrevStoryPoint(taskDetail.task_story_point);
  }, [taskDetail]);

  const handleEditClick = () => {
    if (taskDetail?.userPermission?.can_i_edit === false) {
      toast.info("You don't have access to update task", { position: "bottom-right" });
      return;
    } else {
      setIsEditing(true);
    }
  };

  const handleUpdateTask = (value) => {
    if (value === "" || task.task_story_point === value) {
      return;
    } else if (value >= 0 && value <= 10) {
      setProgress(30);
      const taskId = taskDetail.task_id;
      const payload = new FormData();
      payload.append(`story_point`, value);
      dispatch(editTask({ taskId, payload })).then((resp) => {
        setProgress(60);
        if (!isSubtaskview) {
          refreshPage(
            taskId,
            projectId,
            null,
            null,
            sprintId,
            taskListId,
            resp?.payload?.task,
            "added change inline"
          );
        } else {
          refreshPage(task?.status_id, resp?.payload?.task);
        }
        setProgress(100);
        toast.success("Task points updated successfully!");
        setTimeout(() => {
          setTask({
            task_story_point: value,
          });
        }, [500]);
      });
    } else {
      setStoryPoint(prevStoryPoint);
    }
  };

  const handleInputChange = (e) => {
    setStoryPoint(e.target.value);
  };

  const handleInputBlur = () => {
    setIsEditing(false);
    if (storyPoint === "") {
      setStoryPoint(prevStoryPoint);
    } else {
      handleUpdateTask(storyPoint);
      setPrevStoryPoint(storyPoint);
    }
  };

  const onEnterKey = (e) => {
    const value = e.target.value;
    if (e.key === "Enter") {
      handleUpdateTask(value);
      setIsEditing(false);
    }
  };

  return (
    <div
      className={`task_items_list w-30 ${isSubtaskview ? "sub_task_points" : ""
        } ${islist ? "s-thing" : "table_story_point_bg add_cursor"} ${taskDetail?.userPermission?.can_i_edit === false ? "not-allowed-disable" : ''}`}
    >
      {isEditing ? (
        <input
          type="number"
          value={storyPoint}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyDown={onEnterKey}
          className="hide-spinner"
          autoFocus={isEditing}
          style={{ outline: "none", border: "none", width: "100%" }}
        />
      ) : (
        <p
          onClick={isUserProfile ? undefined : handleEditClick}
          className={`mb-0 ${taskDetail?.userPermission?.can_i_edit === false ? "cursor-not-allowed" : isUserProfile ? "cursor_default" : 'add_cursor'}`}
        >
          {storyPoint || 0}
        </p>
      )}
    </div>
  );
}

export default TaskStoryPoint;
