import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { api } from "../../../customAxios";
function VerifyEmail() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  useEffect(() => {
    const payload = {
      email: queryParams.get("email"),
      token: queryParams.get("token"),
    };
    api
      .post("/auth/verify-email", payload, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((res) => {
        toast.success(res?.data?.message);
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        console.error("Login failed:", error);
      });
  }, []);
  return (
    <div className="verify_email_box">
      <div className="login__card verify_card">
        <h4 className="text-center mb-4">Account is verified</h4>
        <p className=" custom-font-size-14 text-center mt-4">
          Click <Link to="/signin">here</Link> to Sign in
        </p>
      </div>
    </div>
  );
}

export default VerifyEmail;
