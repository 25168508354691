import { faStop, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

function ScreenRecorder(props) {
  const [screenStream, setScreenStream] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [recording, setRecording] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [error, setError] = useState(false);
  const [notificationPermission, setNotificationPermission] = useState('default');
  const [timer, setTimer] = useState(null); // Track timer

  useEffect(() => {
    setElapsedTime(0);
    checkNotificationPermission();
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, []);

  const checkNotificationPermission = async () => {
    const permission = await Notification.requestPermission();
    setNotificationPermission(permission);
  };

  const startCapture = async () => {
    try {
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      const screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
      });
      const combinedStream = new MediaStream([
        ...screenStream.getTracks(),
        ...audioStream.getTracks(),
      ]);
      setScreenStream(combinedStream);
      setRecordedChunks([]);
      setMediaRecorder(null);
      setRecording(false);
      setElapsedTime(0);
      setError(false);
    } catch (error) {
      console.error("Error capturing screen:", error);
    }
  };

  const startRecording = () => {
    try {
      const recorder = new MediaRecorder(screenStream);
      const chunks = [];
      props.setIsLoading(false);

      recorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.push(e.data);
        }
      };

      recorder.onstop = () => {
        const audioBlob = new Blob(chunks, { type: "video/webm" });
        var video_file = new File(
          [audioBlob],
          uuidv4("video_file_" + Math.floor(Math.random() * 10000)) + ".mp4",
          { type: "video/mp4" }
        );
        props.setIsLoading(true);
        props.handleGetAudio(video_file);
        setRecordedChunks(chunks);
      };

      recorder.start();
      setMediaRecorder(recorder);
      setRecording(true);
      const newTimer = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1);
      }, 1000);
      setTimer(newTimer);

      setTimeout(() => {
        clearInterval(newTimer);
        stopRecording();
        setError(true);
        if (notificationPermission === 'granted') {
          new Notification('Recording stopped', {
            body: 'Recording stopped due to time limit reached.',
          });
        } else if (notificationPermission === 'default') {
          alert('Recording stopped due to time limit reached.');
        } else {
          alert('Recording stopped due to time limit reached.');
        }
      }, 600000);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const stopRecording = () => {
    try {
      if (mediaRecorder) {
        mediaRecorder.stop();

        props.handleGetAudio(recordedChunks);
        props.setIsLoading(false);
        setRecording(false);

        if (screenStream) {
          screenStream.getTracks().forEach((track) => track.stop());
          setScreenStream(null);
        }

        if (timer) {
          clearInterval(timer);
          setTimer(null);
        }
      }
    } catch (error) {
      console.error("Error stopping recording:", error);
    }
  };

  useEffect(() => {
    if (elapsedTime === 600) {
      if (mediaRecorder) {
        mediaRecorder.stop();

        props.handleGetAudio(recordedChunks);
        props.setIsLoading(false);
        setRecording(false);

        if (screenStream) {
          screenStream.getTracks().forEach((track) => track.stop());
          setScreenStream(null);
        }

        if (timer) {
          clearInterval(timer);
          setTimer(null);
        }
      }
    }
  }, [elapsedTime]);

  useEffect(() => {
    // Start recording automatically when screenStream is available
    if (screenStream && !recording) {
      startRecording();
    }
  }, [screenStream, recording]);

  const downloadRecording = () => {
    if (recordedChunks.length === 0) {
      console.warn("No recorded data available for download.");
      return;
    }

    const recordedBlob = new Blob(recordedChunks, { type: "video/webm" });
    const downloadUrl = URL.createObjectURL(recordedBlob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = downloadUrl;
    a.download = "recorded-video.mp4";
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(downloadUrl);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {!screenStream && !recordedChunks.length && (
          <button onClick={startCapture} className="btn btn-primary btn-sm">
            <FontAwesomeIcon icon={faVideo} style={{ marginRight: "5px" }} />
            Start Recording
          </button>
        )}
        {screenStream && !recording && !recordedChunks.length && (
          <button onClick={startRecording} className="btn btn-primary btn-sm">
            <FontAwesomeIcon icon={faVideo} style={{ marginRight: "5px" }} />
            Start Recording
          </button>
        )}
        {mediaRecorder && recording && !recordedChunks.length && (
          <button onClick={stopRecording} className="btn btn-danger btn-sm">
            <FontAwesomeIcon icon={faStop} style={{ marginRight: "5px" }} />
            Stop Recording
          </button>
        )}
      </div>

      {recordedChunks.length > 0 && (
        <>
          <div className="col-md-6 border-black">
            <h5>Title</h5>
            <input
              type="text"
              name="name"
              value={props.fileTitle}
              onChange={(e) => props.setFileTitle(e.target.value)}
              className={`form-control form-control__input`}
            />
          </div>
          {error && (
            <div className="alert alert-danger" role="alert">
              Recording stopped due to time limit reached.
            </div>
          )}
          <video controls style={{ marginTop: "20px", width: "100%" }}>
            {recordedChunks.map((chunk, index) => (
              <source
                key={index}
                src={URL.createObjectURL(chunk)}
                type="video/webm"
              />
            ))}
          </video>
        </>
      )}
    </div>
  );
}

export default ScreenRecorder;
