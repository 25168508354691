import React from "react";

function CustomAvatar(props) {
  const {
    name,
    width,
    height,
    backgroundColor,
    fontSize,
    color,
    borderRadius,
    isMarginAuto,
    isTask,
    shadow,
    border,
    number,
    divOpacity,
    justifyContent,
    display,
    lineHeight,
    cursor,
    avatarClasses,
  } = props;

  if (!name || (typeof name !== "string" && typeof name !== "number")) {
    return null;
  }

  const [firstName, lastName] =
    typeof name === "string" ? name.split(" ") : ["", ""];
  const initials =
    typeof name === "string"
      ? `${firstName.charAt(0).toUpperCase()}${
          lastName ? lastName.charAt(0).toUpperCase() : ""
        }`
      : name;

  const avatarStyle = {
    width: width ? `${width}px` : "24px",
    height: height ? `${height}px` : "24px",
    backgroundColor: backgroundColor
      ? backgroundColor
      : "rgba(189, 189, 189, 0.6)",
    color: color ? color : "#fff",
    textAlign: "center",
    display: display ? display : "flex",
    justifyContent: justifyContent ? justifyContent : "center",
    alignItems: "center",
    lineHeight: lineHeight ? `${lineHeight}px` : `${height}px`,
    fontSize: fontSize ? `${fontSize}px` : "12px",
    fontWeight: "500",
    borderRadius: borderRadius ? `${borderRadius}px` : "6px",
    boxShadow: shadow ? "0 1px 6px 0 #20212447" : "",
    border: border ? `${border}px solid #fff` : "",
    opacity: divOpacity ? divOpacity : "",
    margin: isMarginAuto ? "auto" : "",
    cursor: cursor ? cursor : "pointer",
  };

  const textStyle = {
    marginBlock: "0",
    fontWeight: number ? "500" : "400",
    marginTop: number ? "0" : "-1px",
    marginRight: number ? "8px" : "0",
  };

  return (
    <div
      style={avatarStyle}
      className={`text-capitalize ${avatarClasses ? avatarClasses : ""}`}
      title={typeof name === "string" ? name : ""}
    >
      {isTask || number ? (
        <p className="text-capitalize" style={textStyle}>
          {`+${initials}`}
        </p>
      ) : (
        `${initials}`
      )}
    </div>
  );
}

export default CustomAvatar;
