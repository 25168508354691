import projectSlice from "./Slice/Projects";
import taskSlice from "./Slice/Task";
import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const rootReducers = combineReducers({
  task: taskSlice,
  project: projectSlice,
});
const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducers);
export default persistedReducer;
