import React, { useRef, useEffect } from 'react';
import BlogNavigation from '../../../componets/Blogs/BlogNavigation';
import BlogContent from '../../../componets/Blogs/BlogContent';


export default function ScrumVSKanban() {
      
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

const sections = [
  {
      id: 'section1',
      title: 'Introduction',
      content: (
          <>
              <p class="padding-top-19">
                  In project management, two methodologies that have gained significant popularity are Scrum and Kanban. Both are part of Agile methodologies, which focus on continuous improvement, flexibility, and customer satisfaction but work with different approaches.
              </p>
          </>
      ),
  },
  {
      id: 'section2',
      title: 'Scrum: A Sprint-Based Approach',
      content: (
          <>
              <p class="padding-top-19">
                  Scrum is a framework that helps teams work together to deliver complex products in iterative increments. It is characterized by its iterative nature, where work is broken down into manageable chunks called "sprints." Each sprint typically lasts 1-4 weeks and ends with a potentially shippable product increment.
              </p>
              <p>
                  In Scrum, roles are clearly defined. The Product Owner is responsible for the product backlog, which is a prioritized list of features or tasks. The Development Team, consisting of 3-9 members, is responsible for delivering the product increment. The Scrum Master facilitates the process, ensuring that Scrum principles are being followed and that the team is working effectively.
              </p>
              <p>
                  Scrum meetings include the daily stand-up, sprint planning, sprint review, and sprint retrospective. These meetings help the team stay aligned, inspect and adapt their progress, and continuously improve their process.
              </p>
          </>
      ),
  },
  {
      id: 'section3',
      title: 'Kanban: A Flow-Based Approach',
      content: (
          <>
              <p class="padding-top-19">
                  Kanban, on the other hand, is a visual system for managing work. It focuses on continuous delivery and improving the flow of work. In Kanban, work is visualized on a board, with columns representing different stages of work, such as "To Do," "In Progress," and "Done."
              </p>
              <p>
                  Unlike Scrum, Kanban does not have defined roles or time-boxed iterations. Instead, it limits the amount of work in progress (WIP), which helps to prevent bottlenecks and improve the team's focus and efficiency.
              </p>
              <p>
                  In Kanban, the team pulls work from the "To Do" column when they have capacity, rather than committing to a set amount of work at the beginning of a sprint. This allows for greater flexibility and adaptability, as the team can respond to changes and new priorities as they arise.
              </p>
          </>
      ),
  },
  {
      id: 'section4',
      title: 'Scrum vs Kanban: Key Differences',
      content: (
          <>
              <ol class="refinement padding-top-19">
                  <li><b>Sprints vs Continuous Flow:</b> Scrum uses time-boxed iterations called sprints, while Kanban focuses on continuous flow of work.</li>
                  <li><b>Roles:</b> Scrum has clearly defined roles, while Kanban does not.</li>
                  <li><b>Workload:</b> In Scrum, the team commits to a set amount of work at the beginning of a sprint. In Kanban, the team pulls work when they have capacity.</li>
                  <li><b>Meetings:</b> Scrum has several defined meetings, while Kanban has fewer, more flexible meetings.</li>
              </ol>
          </>
      ),
  },
  {
      id: 'section5',
      title: 'Scrum vs Kanban: Which One to Choose?',
      content: (
          <>
              <p class="padding-top-19">
                  The choice between Scrum and Kanban depends on the specific needs of the team and context of the project. Scrum is often preferred for projects with a clear end goal and a defined set of requirements. It works well for teams that thrive on structure and predictability.
              </p>
              <p>
                  Kanban, on the other hand, is often preferred for projects with ongoing or evolving requirements. It works well for teams that need to manage a high volume of work or respond quickly to changing priorities.
              </p>
              <p>
                  In some cases, teams may choose to use a hybrid approach, combining elements of both Scrum and Kanban. This can provide the benefits of both frameworks and allow the team to tailor their process to their specific needs.
              </p>
              <p>
                  In conclusion, both Scrum and Kanban are powerful tools in the Agile toolkit. They each have their own strengths and are best suited to different types of projects and teams. By understanding the key differences between Scrum and Kanban, teams can make an informed decision about which framework to use, or how to combine elements of both to create a process that works best for them.
              </p>
          </>
      ),
  },
];

    
      const scrollRef = useRef();
    
      const handleScroll = (id) => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };
  return (
    <>
      <div className="aboutus_page padding-b-blog">
        <div className="container__land">
           <div className="text-center top-text mt-5">
           <h1>Scrum Vs Kanban</h1>
            </div> 
        </div>
      </div>

      <div className="agile_card_section mb-2">
        <div className="container__land">
          <div className="meta-cards row mb-5">
            <div className="col-md-3">
            <h4>Table of Content</h4>
              <hr/>
              <BlogNavigation sections={sections} handleScroll={handleScroll} />
            </div>
            <div className="col-md-9 agile__content" ref={scrollRef}>
              
              {sections.map((section, index) => (
                <BlogContent key={index} id={section.id} title={section.title} content={section.content} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
