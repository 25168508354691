import React from 'react'
function LinkItem() {
    const items = [
        {
            id: "we4-4",
            title: "Implement user authentication module",
            type: "Is Blocked By"
        },
        {
            id: "we4-5",
            title: "Optimize database query performance",
            type: "Is Tested By"
        },
        {
            id: "we4-6",
            title: "Design responsive UI for dashboard",
            type: "Is Related To"
        },
        {
            id: "we4-7",
            title: "Set up continuous integration pipeline",
            type: "Is Blocked By"
        }
    ];
    return (
        <div className='link-items-box box' id="bx">
            <div className='box-head align-items-center mb_30'>
                <h5 className='text-secondary'>Link Items </h5>
                <span className="text-primary  add_cursor f-12" >+ Link Item</span>
            </div>
            {
                items.length > 0 &&
                items.map((item, i) =>
                    <div key={i} className={`d-flex align-items-center gap-2 mb_12`}>
                        <div className="w-20"><span className="span-gray-color f-14 ms-1">{item.type}</span></div>
                        <div className={`testCase-small-card w-80 mb-0`} >
                            <span className="text-primary">{item.id}</span>
                            <span className="ms-3">{item.title}</span>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default LinkItem