import { Alert } from "@mui/material";
import React from "react";
import {
    fullDateFormate,
    getCreatedTime,
    getUserName,
} from "../../../../Utils/helpers";
const ResultComment = ({ results, projectUsers }) => {
    return (
        <>
            {results && results?.length === 0 ? (
                <Alert icon={false} severity="success" className="history_description">
                    {results && results?.length === 0
                        ? "There is no result added for test case"
                        : ""}
                </Alert>
            ) : (
                <table className="result-table">
                    <thead>
                        <tr>
                            <th className="th-results">Results</th>
                            <th className="th-comments">Comments</th>
                        </tr>
                    </thead>
                    <tbody>
                        {results
                            ?.slice()
                            .reverse()
                            .map((result, index) => (
                                <tr key={index}>
                                    <td className="td-result">
                                        <span
                                            className={`status case-${result?.status?.toLowerCase()} capitalize-first`}
                                        >
                                            {result.status}
                                        </span>
                                        <div className="result-date-time">
                                            {fullDateFormate(result?.created_at)} /{" "}
                                            {getCreatedTime(result?.created_at)}
                                        </div>
                                        <div className="result-createdBy">
                                            By{" "}
                                            <span>
                                                {getUserName(projectUsers, result?.executed_by)}
                                            </span>
                                        </div>
                                    </td>
                                    <td className="td-comments capitalize-first">
                                        {result?.comment}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            )}
        </>
    );
};

export default ResultComment;
