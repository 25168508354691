import React from 'react';

const defects = [
    {
        result: 'Failed', date: 'Jan 12, 2023', time: '16 : 12 PM', status: 'In Progress', comments: `Test Case for Burn Up Chart`
    },
    {
        result: 'Retest', date: 'Jan 12, 2023', time: '16 : 12 PM', status: 'Completed', comments: `Test Case for Burn Up Chart`
    },
    {
        result: 'Passed', date: 'Jan 12, 2023', time: '16 : 12 PM', status: 'To Do', comments: `Test Case for Burn Up Chart`
    },
    {
        result: 'Blocked', date: 'Jan 12, 2023', time: '16 : 12 PM', status: 'Ready To Test', comments: `Test Case for Burn Up Chart`
    },
];

const Defects = () => {
    return (
        <table className="defect-table">
            <thead>
                <tr className='defect-row'>
                    <th className='th-results'>Result</th>
                    <th className='th-date-time'>Date & Time</th>
                    <th className='th-defect'>Defect</th>
                    <th className='th-status'>Status</th>
                </tr>
            </thead>
            <tbody>
                {defects.map((defect, index) => (
                    <tr key={index} className='defect-row defect-body-row'>
                        <td className='td-result'>
                            <span className={`test-case case-${defect.result.toLowerCase()}`}>{defect.result}</span>
                        </td>
                        <td className='td-date-time'>{defect.date} / {defect.time}</td>
                        <td className='td-defect'><p>{defect.comments}</p></td>
                        <td className='td-status'>
                            <span className={`status ${defect.status === 'In Progress' ? 'in-progress' : defect.status === 'Completed' ? 'completed' : defect.status === 'To Do' ? 'todo' : defect.status === 'Ready To Test' ? 'testing' : ''}`}>{defect.status}</span>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default Defects;
