import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
// import Aws from "../../../assets/images/aws.png";
import Zakria from "../../../assets/images/Zakria.png";
import AwaisA from "../../../assets/images/AwaisA.png";
import Faisal from "../../../assets/images/Faisal.png";

import Overview from "../../../assets/images/Overview.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Carousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderKey, setSliderKey] = useState(0); // Use a key to force re-render on resize

  const testimonials = [
    {
      id: 1,
      content:
        "This tool is incredibly straightforward and effortless to use for agile teams. The user interface is clean, making it easy for teams to navigate and find the resources they need. Features such as backlog and sprint make planning user stories more efficient for the team.",
      name: "Eric Hilliard",
      rating: 5,
      image: Zakria,
    },
    {
      id: 2,
      content:
        "It is one of the best and straightforward scrum tools that I have ever seen. It makes project management a breeze. I would highly recommend it to other teams who follow scrum and are looking for the tool to manage the development process.",
      name: "Callum Parks",
      rating: 5,
      image: AwaisA,
    },
    {
      id: 3,
      content:
        "Scrum Slate has significantly simplified project management. With innovative features like built-in audio and video message recording, along with task creation directly from these messages make it the ideal tool for efficiently managing complex projects within teams.",
      name: "Robert Peters",
      rating: 3,
      image: Faisal,
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    prevArrow: (
      <button className="slick-prev" aria-label="Previous" type="button">
        <ChevronLeft style={{ color: "#000" }} fontSize="large" />
      </button>
    ),
    nextArrow: (
      <button className="slick-next" aria-label="Next" type="button">
        <ChevronRight style={{ color: "#000" }} fontSize="large" />
      </button>
    ),
    beforeChange: (current, next) => setCurrentSlide(next),
    responsive: [
      {
        breakpoint: 924,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    // Function to handle window resize
    const handleResize = () => {
      setSliderKey((prevKey) => prevKey + 1); // Force re-render by updating key
      setCurrentSlide(0); // Reset current slide to 0
    };

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <section className="carousel landing__carousel   responsive_carousel">
        <div className="container__land">
          <h2 className="text-center pt-5  responsive_heading">User Testimonials</h2>
          <div className="row d-flex justify-content-center carousel_box ">
            <Slider key={sliderKey} {...settings}>
              {testimonials.map((testimonial) => (
                <div key={testimonial.id} className="custom-carousel-item">
                  <div className="col-md-12">
                    <div className="card cading">
                      <div className="card-body">
                        <div className="mikw">
                          <div className="d-flex">
                            <img
                              src={testimonial.image}
                              className="card-img-top image"
                              alt="Testimonial"
                            />
                            <div className="name">
                              <h5 className="responsive_small_heading">{testimonial.name}</h5>
                              {[...Array(testimonial.rating)].map((_, i) => (
                                <FontAwesomeIcon
                                  key={i}
                                  icon={faStar}
                                  className="icons"
                                />
                              ))}
                            </div>
                          </div>
                          <div >
                            <img src={Overview} alt="" className="img-tested" />
                          </div>
                        </div>
                        <p className="card-text">{testimonial.content}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Carousel;
