import React from "react";
import projectIcon from "../../../assets/images/extension-page/projectIcon.svg";
import taskIcon from "../../../assets/images/extension-page/taskIcon.svg";
import recordingIcon from "../../../assets/images/extension-page/recordingIcon.svg";
import titleIcon from "../../../assets/images/extension-page/titleIcon.svg";
import saveIcon from "../../../assets/images/extension-page/saveIcon.svg";

function ExtensionSelectCard(props) {
  const { setSelectedId, selectedId } = props;
  const handleSelectOption = (optionId) => {
    setSelectedId(optionId);
  };
  const extensionOptions = [
    {
      id: "project",
      title: "Select A Project",
      image: projectIcon,
    },
    {
      id: "task",

      title: "Create A Task",
      image: taskIcon,
    },
    {
      id: "record",
      title: "Record Screen",
      image: recordingIcon,
    },
    {
      id: "addTitle",
      title: "Add Title",
      image: titleIcon,
    },
    {
      id: "saveIt",
      title: "Save It",
      image: saveIcon,
    },
  ];

  return (
    <div className="d-flex extention_icon_section">
      {extensionOptions.map((option, index) => (
        <div
          key={index}
          id={option.id}
          className={`extention_icon_div ${selectedId === option.id ? "selected" : ""
            }`}
          onClick={() => handleSelectOption(option.id)}
        >
          <div className="text-center">
            <img src={option.image} className="" alt={option.title} />
            <h5 className="title">{option.title}</h5>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ExtensionSelectCard;
