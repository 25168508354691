import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import LandingNavbar from "../componets/MainLayout/LandingNavbar";
import Footer from "../componets/MainLayout/Footer";
import { Helmet } from "react-helmet";

export default function MainLayout() {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== '/' && location.pathname !== '/features' && location.pathname !== '/about-us' && location.pathname !== '/chrome-extension' && location.pathname !== '/contactus') {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  const getTitle = () => {
    switch (location.pathname) {
      case "/":
        return "Home";
      case "/features":
        return "Feature";
      case "/about-us":
        return "About us";
      case "/contactus":
        return "Contact us";
      case "/latest-update":
        return "Latest Update";
      case "/bug-tracking":
        return "Bug Tracking";
      case "/what-is-agile":
        return "What Is Agile";
      case "/scrum-vs-kanban":
        return "Scrum vs Kanban";
      case "/backlog-refinement":
        return "Backlog Refinement";
      case "/sprint-planing":
        return "Sprint Planing";
      case "/chrome-extension":
        return "Extensions";
      case "/privacy-policy":
        return "Privacy Policy";
      default:
        return "Default Title";
    }
  };
  return (
    <>
      <Helmet>
        <title>{` ${getTitle()} | ${process.env.REACT_APP_PROJECT_NAME
          }`}</title>
      </Helmet>
      <LandingNavbar />
      <Outlet />
      <Footer />
    </>
  );
}
