import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from '../../../componets/MainLayout/Footer';
import LandingNavbar from '../../../componets/MainLayout/LandingNavbar';
import { api } from '../../../customAxios';

export default function FileViewMuxUpload() {
    const [uploadUrl, setUploadUrl] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => { // TODO: PUT IT IN NPM INSTAL
        const script = document.createElement('script');
        script.src = 'https://cdn.jsdelivr.net/npm/@mux/mux-uploader@1.0.0-beta.6';
        script.async = true;
    
        document.head.appendChild(script);
    
        return () => {
          document.head.removeChild(script);
        };
    }, []); 

    useEffect(() => {
        api.post(`/get-mux-upload-url`)
            .then((response) => {
                setUploadUrl(response?.data.data.url);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error getting signed URL:', error);
                setIsLoading(false);
            });
    }, []);

    return (
        <>
            <LandingNavbar />
            <div className='about-content'>
                <h2 className='text-center'>{`${process.env.REACT_APP_PROJECT_NAME}`}</h2>

                <div className="container__land" style={{marginBottom:'50px'}}>
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-10 mt-20" style={{textAlign: 'center'}}>
                        <mux-uploader endpoint={uploadUrl}>

                        </mux-uploader>
                        </div>

                    </div>
                </div>

            </div>
            <Footer />

        </>
    )
}
