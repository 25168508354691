import React from "react";
import { Grid, Typography } from "@mui/material";

function ViewTaskLoader({ isTestCase }) {
  return (
    <div>
      {" "}
      <Grid container spacing={1} lg={12} md={12} xs={12}>
        <Grid item lg={12} md={12} xs={12} className="d-flex">
          <Grid item lg={8} md={8} xs={12} sm={12} className="px-10">
            <Grid className="pt-15 pl-0 pt-0 bg-white">
              <Typography className="display-flex ">
                <Grid className="w-100">
                  {[...Array(1)].map((e, i) => (
                    <Typography
                      key={i}
                      className="display-flex align-center mb-2"
                    >
                      <div className="lazy-loader2 pl-5 box-s-n">
                        <div className="ml-20">
                          <div className="d-flex">
                            <div className="shimmerBG div1"></div>
                            <div className="shimmerBG content-line loading_heading_long h_26 br_6 ms-2 pw-100"></div>
                          </div>
                          <div className="shimmerBG content-line loading_heading_long h_26 br_6 mt-3"></div>
                          <div className="shimmerBG h_26 br_6 w-100 mt-3 h-60"></div>
                          <div className="d-flex justify-content-between mt-5">
                            <div className="shimmerBG title-line br_6 w-40"></div>
                            <div className="shimmerBG title-line br_6 div3"></div>
                          </div>

                          <div className="d-flex justify-content-between mt-3">
                            <div className="shimmerBG title-line br_6 w-220"></div>
                            <div className="shimmerBG title-line br_6 div5"></div>
                          </div>

                          <div className="d-flex justify-content-between mt-3">
                            <div className="shimmerBG title-line br_6 w-220"></div>
                            <div className="shimmerBG title-line br_6 div3"></div>
                          </div>

                          <div className="shimmerBG content-line loading_heading_long h_26 br_6 mt-5"></div>

                          <div className="d-flex mt-5">
                            <div className="shimmerBG content-line loading_heading_long h_26 br_6  h_40"></div>
                            <div className="shimmerBG content-line loading_heading_long h_26 br_6 ms-2  h_40"></div>
                          </div>

                          <div className="d-flex mt-3">
                            <div className="shimmerBG content-line loading_heading_long h_26 br_6"></div>
                            <div className="shimmerBG content-line loading_heading_long h_26 br_6 ms-2"></div>
                            <div className="shimmerBG content-line loading_heading_long h_26 br_6 ms-2"></div>
                          </div>
                          <div className="shimmerBG content-line loading_heading_long h_26 br_6 mt-3"></div>

                          <div className="shimmerBG content-line br_6 h-120"></div>

                          <div className="d-flex mt-3 justify-content-between">
                            <div className="shimmerBG content-line loading_heading_long h_26 br_6 h_40 w-120"></div>
                            <div className="d-flex">
                              <div className="shimmerBG content-line loading_heading_long h_26 br_6 ms-2 h_40 w-120"></div>
                              <div className="shimmerBG content-line loading_heading_long h_26 br_6 ms-2 h_40 w-120"></div>
                            </div>
                          </div>


                          {/* <div className="shimmerBG content-line"></div>{" "}
                          <div className="shimmerBG title-line end"></div>
                          <div className="shimmerBG title-line-small"></div> */}
                        </div>
                      </div>
                    </Typography>
                  ))}
                </Grid>
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4} xs={12} sm={12}>
            <Typography className="display-flex align-center">
              <div className={`lazy-loader br_10 ${isTestCase ? "h-25" : "height-570"}`}>
                {isTestCase &&
                  <div className="shimmerBG buttonShimmer br_6 w-100 mt-3"></div>
                }
                {[...Array(2)].map((e, i) => (
                  <div className={`d-flex justify-content-between ${isTestCase && i === 0 ? "mt-30px" : "mt-3"}`}>
                    <div className="shimmerBG title-line br_6 w-40"></div>
                    <div className="shimmerBG title-line br_6 w-40"></div>
                  </div>
                ))}
                <div className="d-flex justify-content-between mt-3">
                  <div className="shimmerBG title-line br_6 w-40"></div>
                  <div className="shimmerBG title-line br_6  w-25"></div>
                </div>

                {!isTestCase &&
                  <>
                    <div className="d-flex justify-content-between mt-3">
                      <div className="shimmerBG title-line br_6 w-25"></div>
                      <div className="shimmerBG title-line br_6 w-50"></div>
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <div className="shimmerBG title-line br_6 w-40"></div>
                      <div className="shimmerBG title-line br_6 w-25"></div>
                    </div>
                    {[...Array(5)].map((e, i) => (
                      <div className="d-flex justify-content-between mt-3">
                        <div className="shimmerBG title-line br_6 w-25"></div>
                        <div className="shimmerBG title-line br_6 w-50"></div>
                      </div>
                    ))}
                  </>}
              </div>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default ViewTaskLoader;
