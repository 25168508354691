import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import { handleSpaces } from '../../Utils/helpers';
import { toast } from "react-toastify";

const labelsList = ["Label1", "Label2", "Label3", "Label4", "Label5"];

const AutocompleteLabels = ({ selectedLabels, onSelectedLabelsChange, projectLabels, borderNone, taskDetail }) => {
  const [inputValue, setInputValue] = React.useState('');

  const handleChipDelete = (index) => {
    const newLabels = [...selectedLabels];
    newLabels.splice(index, 1);
    onSelectedLabelsChange(newLabels);
    toast.success('Task label removed successfully');
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      const trimmedLabel = inputValue.trim();

      if (!selectedLabels.includes(trimmedLabel)) {
        const newSelectedLabels = [...selectedLabels, trimmedLabel];
        onSelectedLabelsChange(newSelectedLabels);
        setInputValue('');
        toast.success('Task label added successfully');
      }
    }
  }

  return (
    <div style={{ width: "100%", position: "relative" }}>
      {/* {taskDetail?.userPermission?.can_i_edit === true && ( */}
      <Autocomplete
        multiple
        id="tags-filled"
        options={projectLabels}
        freeSolo
        value={selectedLabels}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onChange={(event, newValue) => {
          onSelectedLabelsChange(newValue);
        }}
        onKeyDown={handleKeyDown}
        clearIcon={null} // Hide the clear icon
        renderTags={() => null} // Hide selected values inside the Autocomplete
        renderInput={(params) => (
          <TextField
            {...params}
            label="Enter labels"
            // variant="outlined"
            onKeyPress={handleSpaces}
            className="autocomplete-text-area"
            style={{ outline: 'none', padding: "3px !important", border: borderNone ? 'none !important' : '', visibility: taskDetail?.userPermission?.can_i_edit === false && 'hidden' }}
          />
        )}
      />
      {/* )} */}

      <div className='selected-labels'>
        {selectedLabels.map((option, index) => (
          <Chip
            key={index}
            label={option}
            onDelete={() => handleChipDelete(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default AutocompleteLabels;