import React from "react";
import Icon from '../../../assets/images/Recording1.svg'
import Icon2 from '../../../assets/images/Extenn.svg'
import Image from '../../../assets/images/extension-page/extentionSmall.png'
import Image2 from '../../../assets/images/extension-page/recordinglatest.png'

function LatestUpdate() {
    const getExtainshion = () => {
        window.open('https://chromewebstore.google.com/detail/scrum-slate-%E2%80%93-screen-reco/fieiojigoecblnfonmifhapdmihdcdjo?hl=en-GB&utm_source=ext_sidebar')
    }
    return (
        <>
            <div className="extension_header latest-update">
                <div className="container__land">
                    <div className="text-center top-text top-text-features">
                        <h1 className="feature_page_heading responsive_heading pb-2">
                            Latest Update: Exciting New Features!
                        </h1>
                        <div className="w-50 m-auto">
                            <p className="mt-1 color_unset ">
                                We are thrilled to announce the latest updates to our platform, designed to enhance your experience and streamline your workflow. Here’s a rundown of what’s new:
                            </p>
                        </div>
                        <br />
                    </div>
                </div>
            </div>
            <div className="latest-update_cards">
                <div className="container-latest-update container__land">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="image">
                                    <img src={Icon2} alt="" />
                                </div>
                                <div className="heading">
                                    <h1>Extension</h1>
                                    <p>Effortlessly record your screen, explain issues and ideas, and create tasks with just one click. Save time with Scrum Slate Chrome extension and make project management easier and straightforward. <a className="see-more" onClick={getExtainshion}>Learn more</a></p>
                                </div>
                                <div className="bottom-img h-304">
                                    <img src={Image} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="image">
                                    <img src={Icon} alt="" />
                                </div>
                                <div className="heading">
                                    <h1>Recordings</h1>
                                    <p>Use voice notes and the screen recorder to explain complex issues, demonstrate features, and share ideas with your team and stakeholders. With the Scrum Slate, there's no need for Loom or any other third-party tool to record audio and videos for your project.</p>
                                </div>
                                <div className="bottom-img">
                                    <img src={Image2} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LatestUpdate;
