import React from "react";
import Modal from "react-bootstrap/Modal";

function ConfirmDialog({
  open,
  setOpen,
  dialogTitle,
  handleConfirmYes,
  disableAction,
  subtitle,
  setSubtitle,
}) {
  const handleClose = (event, reason) => {
    setOpen(false);
    if (subtitle) {
      setSubtitle("");
    }
  };

  return (
    <Modal
      size="md"
      show={open}
      // onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="pt-5 pl-5 add-member_modal alert-modal_window"
      style={{ zIndex: "2000" }}
    >
      <div className="container">
        <div className="main-modal">
          <Modal.Body className="project-modal_body">
            <div className="fields">
              <div className="row">
                <div className="col-md-12">
                  <div className={!subtitle && "project-name"}>
                    <h4 className="text-center">{dialogTitle}</h4>
                    {subtitle && <p className="text-center">{subtitle}</p>}
                  </div>
                </div>
              </div>
              <div className="actions">
                <button
                  className="btn btn-cancel btn-sm"
                  onClick={handleClose}
                  disabled={disableAction}
                >
                  No
                </button>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={handleConfirmYes}
                  disabled={disableAction}
                >
                  Yes
                </button>
              </div>
            </div>
          </Modal.Body>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmDialog;
