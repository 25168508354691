import { faCopy, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Typography } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import { editTask } from "../../Redux/Slice/Task";
import { handleCopyText } from "../../Utils/helpers";
import AI from "../../assets/images/ai.svg";
import ViewTaskLoader from "../../componets/pageLoaders/projectLoaders/ViewTaskLoader";
import { api } from "../../customAxios";
import AddSubBug from "../../pages/Private/Projects/Tasks/AddSubBug";
import AddSubTask from "../../pages/Private/Projects/Tasks/AddSubTask";
import TaskAttachments from "../../pages/Private/Projects/Tasks/TaskAttachments";
import TaskComments from "../../pages/Private/Projects/Tasks/TaskComments";
import TaskHistory from "../../pages/Private/Projects/Tasks/TaskHistory";
import TaskMediaRecorder from "../../pages/Private/Projects/Tasks/TaskMediaRecorder";
import TaskProgress from "../../pages/Private/Projects/Tasks/TaskProgress";
import ViewTaskSidebar from "../../pages/Private/Projects/Tasks/ViewTaskSidebar";
import AiModalWindow from "./AI/AiModalWindow";
import CustomTextEditor from "./CustomTextEditor";
import FileViewBox from "./Projects/FileLightBox";
function TaskViewSideDrawer(props) {
  const { setTaskId, setProgress } = props;
  const [isTaskNameEditing, setIsTaskNameEditing] = useState(false);
  const [isDescription, setIsDescription] = useState(false);
  const dispatch = useDispatch();
  const { id, taskId, projectId } = useParams();
  const [projectLabels, setProjectLabels] = useState([]);
  const [progress, setTaskProgress] = useState();
  const [taskDetail, setTaskDetail] = useState({});
  const [projectDetails, setProjectDetails] = useState([]);
  const [task_description, setTask_description] = useState("");
  const [prevTitle, setPrevTitle] = useState("");
  const [editTitle, setEditTitle] = useState(false);
  const [editParagraph, setEditParagraph] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingLoader, setIsLoadingLoader] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [projectSprints, setProjectSprints] = useState([]);
  const [projectUsers, setProjectUsers] = useState([]);
  const [isEditDescription, setIsEditDescription] = useState(false);
  const [taskHistory, setTaskHistory] = useState([]);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const currentTaskList = JSON.parse(localStorage.getItem("currentTaskList"));
  let currentOpenedTask;
  if (currentTaskList?.includes(Number(taskId))) {
    currentOpenedTask = currentTaskList.indexOf(Number(taskId));
    localStorage.removeItem("currentTaskIndex")
  } else {
    const lastOpenedTaskOfActiveList = JSON.parse(localStorage.getItem("currentTaskIndex"));
    currentOpenedTask = currentTaskList.indexOf(Number(lastOpenedTaskOfActiveList))
  }
  const [attachmentFile, setAttachmentFile] = useState();
  const headingRef = useRef(null);
  const [state, setState] = useState({
    open: false,
    type: "",
    desc: "",
    newDesc: "",
  });

  useEffect(() => {
    if (isTaskNameEditing && headingRef.current) {
      headingRef.current.focus();
      document.execCommand("selectAll", false, null);
      document.getSelection().collapseToEnd();
    }
  }, [isTaskNameEditing]);
  useEffect(() => {
    setIsLoading(true);
    api
      .get(`/tasks/${taskId}/edit`)
      .then((response) => {
        setTaskDetail(response?.data?.task);
        setProjectDetails(response?.data?.projectStatuses);
        setProjectUsers(response?.data?.projectUsers);
        setTask_description(taskDetail?.task_desc);
        setPrevTitle(response?.data?.task?.task_name);
        api.get(`/project/${id ? id : projectId}/labels`).then((response) => {
          setProjectLabels(response.data);
        });
        api.get(`project/${id ? id : projectId}/sprints`).then((resp) => {
          const filteredSprint = resp.data.sprints.filter(
            (sprint) => sprint.status !== "completed"
          );
          setProjectSprints(filteredSprint);
        });

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [taskId]);
  const handleGetTaskId = (id) => {
    setTaskId(id);
  };
  const handleSwitchTask = (direction) => {
    const currentUrl = location.pathname;
    const urlParts = currentUrl.split('/');
    const taskId = urlParts[urlParts.length - 2]; // Task ID is the second last part
    // Ensure currentOpenedTask is valid
    if (currentOpenedTask === -1) {
      return;
    }

    let newIndex;
    if (direction === 'prev') {
      if (currentOpenedTask === 0) {
        return; // Already at the first task, do nothing
      } else {
        newIndex = currentOpenedTask - 1;
      }
    } else if (direction === 'next') {
      if (currentOpenedTask === currentTaskList?.length - 1) {
        return; // Already at the last task, do nothing
      } else {
        newIndex = currentOpenedTask + 1;
      }
    }

    const newTaskId = currentTaskList[newIndex];
    const newUrl = currentUrl.replace(`/panel/${taskId}`, `/panel/${newTaskId}`);

    navigate(newUrl);
  };

  const handleUpdateTaskName = (e) => {
    const newHeading = e.target.textContent.trim();
    const newHeadingWords = newHeading.split(/\s+/);
    const prevTitleWords = prevTitle.split(/\s+/);
    const isDifferent = newHeadingWords.some((word, index) => {
      return word !== prevTitleWords[index];
    });

    if (newHeading === "" || !isDifferent) {
      setIsTaskNameEditing(false);
      e.target.textContent = prevTitle;
      return;
    }

    setTaskDetail((prevValues) => ({
      ...prevValues,
      task_name: newHeading,
    }));
    handleUpdateTask(newHeading);
  };

  const handleBlur = (e) => {
    handleUpdateTaskName(e);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleUpdateTaskName(e);
    }
  };

  const handleUpdateTask = (value) => {
    setProgress(30);
    var payload = {};
    if (isTaskNameEditing) {
      payload.name = value;
    } else if (isDescription) {
      setIsLoadingLoader(true);
      payload.desc = task_description?.task_description;
    }

    dispatch(editTask({ taskId, payload }))
      .then((response) => {
        setProgress(60);
        if (isTaskNameEditing) {
          toast.success("Task name changed successfully");
        } else if (isDescription) {
          toast.success("Task description changed successfully");
        }
        const sprintId =
          taskDetail.task_parent_id === null
            ? taskDetail?.sprintName?.sprint_id ??
            taskDetail.parentTask?.sprintName?.sprint_id
            : taskDetail.parentTask?.sprintName?.sprint_id;
        const data = {
          taskId: taskId,
          projectId: projectId,
          updateTask: response?.payload?.task,
          sprintId: sprintId,
        };
        navigate(location.pathname, { state: { data } });
        setTaskDetail((prevValues) => ({
          ...prevValues,
          task_desc: isDescription
            ? task_description.task_description
            : task_description?.task_description || taskDetail?.task_desc,
          task_name: response?.payload?.task?.task_name,
        }));
        setPrevTitle(response?.payload?.task?.task_name);
        setEditParagraph(true);
        setEditTitle(false);
        setIsDescription(false);
        setIsTaskNameEditing(false);
      })
      .finally(() => {
        setIsLoadingLoader(false);
        setProgress(100);
      });
  };

  const closeTitle = () => {
    setIsTaskNameEditing(false);
  };
  const closeDescription = () => {
    setIsDescription(false);
  };

  const handleOpenEditor = () => {
    setIsDescription(true);
    setIsEditDescription(true);
  };
  const handleTitle = (event) => {
    const newText = event.target.innerHTML;
    setTaskDetail((prevValues) => ({
      ...prevValues,
      task_name: newText,
    }));
  };

  const handleDoubleClick = () => {
    setPrevTitle(taskDetail?.task_name);
    setIsTaskNameEditing(true);
    setEditTitle(false);
  };

  const handleCloseDrawer = () => {
    const currentUrl = location.pathname;
    const newUrl = currentUrl.replace(`/panel/${taskId}/${projectId}`, "");
    navigate(newUrl);
    closeTitle();
    closeDescription();
    localStorage.removeItem("currentTaskList")
    localStorage.removeItem("currentTaskIndex")
  };

  const handleGetEditorState = (currentEditorState) => {
    setTask_description((prevValues) => ({
      ...prevValues,
      task_description: currentEditorState,
    }));
  };

  const handleTaskHistory = () => {
    setIsLoadingHistory(true);
    api
      .get(`/task/${taskId}/history`)
      .then((response) => {
        const filteredHistory = response?.data?.activities?.filter(
          (history) => history.log_desc !== ""
        );
        setTaskHistory(filteredHistory);
        setIsLoadingHistory(false);
      })
      .catch((error) => {
        setIsLoadingHistory(false);
      });
  };
  const handleGetFile = (file) => {
    setAttachmentFile(file);
  };
  const [isOpenLightbox, setIsOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState([]);
  const handleCloseLightbox = () => {
    setIsOpenLightbox(false);
    setSelectedImage([]);
  };
  const handleImageClick = (file) => {
    const viewFile = {
      src: file,
      alt: file,
    };
    setSelectedImage([viewFile]);
    setIsOpenLightbox(true);
  };

  const handleHTMLContentClick = (event) => {
    event.preventDefault();
    const target = event.target;
    target.style.cursor = "pointer";
    if (target.tagName === "IMG") {
      handleImageClick(target.src);
    }
    if (target.tagName === "A") {
      target.setAttribute("target", "_blank");
      window.open(target.href, "_blank");
    }
  };
  const handleOpen = (type, desc) => {
    if (!desc) {
      toast.error("Please add description in task first.");
      return;
    }
    setState((preState) => ({
      ...preState,
      type: type,
      open: true,
      desc: desc,
    }));
  };
  return (
    <>
      <Drawer
        transitionDuration={{ enter: 500, exit: 500 }}
        variant="temporary"
        anchor="right"
        className="sub-task-drawer"
        open={taskId ? true : false}
        onClose={isOpenLightbox ? null : handleCloseDrawer}
        style={{ zIndex: "1000" }}
      >
        <div className="sub-task">
          <Grid className="d-flex align-center ml-20 mt-20 close-drawer">
            <Typography
              onClick={handleCloseDrawer}
              onKeyPress={handleCloseDrawer}
              tabIndex={0}
              className="d-flex align-center cursor-hover"
            >
              <svg
                width="18px"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#808191"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            </Typography>
            <Typography
              className="f-20 font-weight-600"
              onClick={handleCloseDrawer}
            >
              Close
            </Typography>
            <span className="task-drawer-control-btn">
              <span
                className={`${currentOpenedTask === 0 ? "disable-control-btn" : "default-control-btn"
                  }`}
                onClick={
                  isLoading || currentOpenedTask === 0
                    ? null
                    : () => handleSwitchTask("prev")
                }
              >
                {"<< Prev"}
              </span>
              <span
                className={`${currentOpenedTask === currentTaskList?.length - 1
                  ? "disable-control-btn"
                  : "default-control-btn"
                  }`}
                onClick={
                  isLoading || currentOpenedTask === currentTaskList?.length - 1
                    ? null
                    : () => handleSwitchTask("next")
                }
              >
                {"Next >>"}
              </span>
            </span>
          </Grid>
          {isLoading ? (
            <ViewTaskLoader />
          ) : (
            <div className="pt-2">
              <div className="container-fluid pr-0">
                <div
                  className="row responsive__row viewTaskDrwar"
                  style={{ width: "100%" }}
                >
                  <div className="resposive__col-8 col-md-8">
                    <div className="sub-task-content-top">
                      <div className="heading view-task">
                        <div
                          className="d-flex mt-2 hover_head"
                          onMouseOver={() => {
                            if (!isTaskNameEditing) setEditTitle(true);
                          }}
                          onMouseLeave={() => {
                            if (!isTaskNameEditing) setEditTitle(false);
                          }}
                        >
                          <div
                            className={`view_task_icon ${taskDetail?.task_type === "T" &&
                              taskDetail?.task_parent_id === null
                              ? "task-done-blue"
                              : taskDetail?.task_type === "B" &&
                                taskDetail?.task_parent_id === null
                                ? "bug"
                                : taskDetail?.task_type === "T" &&
                                  taskDetail.task_parent_id !== null
                                  ? "task-done-yellow"
                                  : taskDetail?.task_type === "E"
                                    ? "userStory"
                                    : "bug"
                              }`}
                          ></div>
                          <h4
                            ref={headingRef}
                            contentEditable={isTaskNameEditing}
                            suppressContentEditableWarning={true}
                            onBlur={handleBlur}
                            onKeyDown={handleKeyDown}
                            className={`capitalize-first w_96 ${isTaskNameEditing ? "editable_heading" : ""
                              }`}
                          >
                            {prevTitle}
                            {editTitle && (
                              <>
                                {taskDetail?.userPermission?.can_i_edit && (
                                  <FontAwesomeIcon
                                    icon={faPencil}
                                    color="gray"
                                    className="action_cursor_head ms-2 fs-16 top-96"
                                    onClick={handleDoubleClick}
                                  />
                                )}
                                <FontAwesomeIcon
                                  icon={faCopy}
                                  color="gray"
                                  className="action_cursor_head ms-2 fs-16 top-96 copy"
                                  onClick={() =>
                                    handleCopyText(
                                      taskDetail.task_name,
                                      "Task Name"
                                    )
                                  }
                                />
                              </>
                            )}
                          </h4>
                        </div>
                        <div className="main-description">
                          {isDescription ? (
                            <div className="description_head">
                              <h4 className="text-capitalize desc_heading">
                                Description
                              </h4>
                              <div className="edit__wiki__box_2 p-0 text-task editorviewtask">
                                <CustomTextEditor
                                  handleGetEditorState={handleGetEditorState}
                                  description={taskDetail.task_desc}
                                  taskId={taskId}
                                  isEditDescription={isEditDescription}
                                  placeholder={"Enter task detail"}
                                  isUploadTaskView={true}
                                  projectUser={projectUsers}
                                  handleGetFile={handleGetFile}
                                  setProgress={setProgress}
                                />
                              </div>
                              <div className="description_control_btn ">
                                <button
                                  className="btn btn-cancel btn-sm"
                                  onClick={closeDescription}
                                >
                                  Cancel
                                </button>
                                <button
                                  disabled={isLoadingLoader}
                                  className={`btn btn-primary btn-sm ms-3 ${isLoadingLoader ? "pd-save-desc-load" : ""
                                    }`}
                                  onClick={handleUpdateTask}
                                >
                                  {isLoadingLoader ? (
                                    <ClipLoader
                                      color="#ffffff"
                                      loading={isLoadingLoader}
                                      size={20}
                                    />
                                  ) : (
                                    "Save"
                                  )}
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="text-div"
                              onMouseOver={() => setEditParagraph(true)}
                              onMouseLeave={() => setEditParagraph(true)}
                            >
                              <div className="d-flex align-items-center description_head hover_head">
                                <h4 className="text-capitalize desc_heading">
                                  Description
                                </h4>
                                {editParagraph && (
                                  <div className="d-flex align-items-center">
                                    {taskDetail?.userPermission?.can_i_edit ? (
                                      <>
                                        <Dropdown className="desc-ai">
                                          <Dropdown.Toggle>
                                            <img src={AI} />
                                            {/* <div className="img"></div>&nbsp;<span></span> */}
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <Dropdown.Item
                                              onClick={() =>
                                                handleOpen(
                                                  "low",
                                                  taskDetail.task_desc
                                                )
                                              }
                                            >
                                              Low Details
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              onClick={() =>
                                                handleOpen(
                                                  "medium",
                                                  taskDetail.task_desc
                                                )
                                              }
                                            >
                                              Medium Details
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              onClick={() =>
                                                handleOpen(
                                                  "high",
                                                  taskDetail.task_desc
                                                )
                                              }
                                            >
                                              High Details
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                        <FontAwesomeIcon
                                          icon={faPencil}
                                          color="gray"
                                          className="action_cursor fs-16"
                                          onClick={handleOpenEditor}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <Dropdown
                                          className={`desc-ai ${taskDetail?.userPermission
                                            ?.can_i_edit === false &&
                                            "not-allowed-disable sprint-action"
                                            }`}
                                        >
                                          <Dropdown.Toggle
                                            className={`cursor-not-allowed`}
                                          >
                                            <img src={AI} />
                                          </Dropdown.Toggle>
                                        </Dropdown>
                                        <FontAwesomeIcon
                                          icon={faPencil}
                                          color="lightgray"
                                          className="fs-16"
                                          style={{ cursor: "not-allowed" }}
                                        />
                                      </>
                                    )}
                                  </div>
                                )}
                              </div>
                              <div
                                className={`desc-b add-cursor-to-image ${!taskDetail.task_desc ? "pd-bottom" : ""
                                  }`}
                              >
                                {showMore ||
                                  taskDetail.task_desc?.length <= 1000 ? (
                                  <span
                                    className={`font-weight-400 description-preview ${!taskDetail.task_desc
                                      ? "no-description"
                                      : ""
                                      }`}
                                    style={{
                                      whiteSpace: "initial",
                                      color: "#000000",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        taskDetail.task_desc &&
                                          taskDetail.task_desc[8]
                                          ? taskDetail.task_desc
                                          : "No description",
                                    }}
                                    onClick={handleHTMLContentClick}
                                  />
                                ) : (
                                  <span
                                    className={`font-weight-400 description-preview ${!taskDetail.task_desc
                                      ? "no-description"
                                      : ""
                                      }`}
                                    style={{ whiteSpace: "initial" }}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        taskDetail.task_desc &&
                                          taskDetail.task_desc[8]
                                          ? taskDetail.task_desc.substr(
                                            0,
                                            1000
                                          ) + "..."
                                          : "No description",
                                    }}
                                    onClick={handleHTMLContentClick}
                                  />
                                )}

                                {(taskDetail.task_desc
                                  ? taskDetail.task_desc.length
                                  : 0) > 1000 && (
                                    <span
                                      tabIndex={0}
                                      onKeyPress={() => setShowMore(!showMore)}
                                      className="link-color cursor-hover text-decoration-none d-block f-14 font-weight-600"
                                      onClick={() => setShowMore(!showMore)}
                                    >
                                      {" "}
                                      {showMore ? "Show less" : "Show more"}
                                    </span>
                                  )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="m-content">
                      <TaskAttachments
                        taskDetail={taskDetail}
                        setTaskDetail={setTaskDetail}
                        isSubTask={true}
                        projectUser={projectUsers}
                        attachmentFile={attachmentFile}
                        setProgress={setProgress}
                      />
                      {(taskDetail?.task_type === "T" ||
                        taskDetail?.task_type === "E" ||
                        taskDetail?.task_type === "B") &&
                        taskDetail && (
                          <AddSubTask
                            taskDetail={taskDetail}
                            setTaskDetail={setTaskDetail}
                            taskId={taskId}
                            handleGetTaskId={handleGetTaskId}
                            handleUpdateTask={handleUpdateTask}
                            projectDetails={projectDetails}
                            setProgress={setProgress}
                            projectUser={projectUsers}
                          />
                        )}
                      {(taskDetail?.task_type === "T" ||
                        taskDetail?.task_type === "E") &&
                        taskDetail.task_parent_id === null && (
                          <AddSubBug
                            taskDetail={taskDetail}
                            setTaskDetail={setTaskDetail}
                            taskId={taskId}
                            handleGetTaskId={handleGetTaskId}
                            handleUpdateTask={handleUpdateTask}
                            projectDetails={projectDetails}
                            projectUser={projectUsers}
                            setProgress={setProgress}
                          />
                        )}
                      <TaskMediaRecorder
                        taskDetail={taskDetail}
                        setProgress={setProgress}
                      />
                      {/* <br /> */}
                      <div className="mt-4 mb-5">
                        <div className="row">
                          <div className="col-md-12">
                            <nav className="nav_prob">
                              <div
                                className="nav nav-tabs"
                                id="nav-tab"
                                role="tablist"
                              >
                                <button
                                  className="nav-link active"
                                  id="nav-home-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#nav-home"
                                  type="button"
                                  role="tab"
                                  aria-controls="nav-home"
                                  aria-selected="true"
                                >
                                  <b>Comments</b>
                                </button>
                                <button
                                  className="nav-link"
                                  id="nav-profile-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#nav-profile"
                                  type="button"
                                  role="tab"
                                  aria-controls="nav-profile"
                                  aria-selected="false"
                                  onClick={handleTaskHistory}
                                >
                                  <b>History</b>
                                </button>
                                <button
                                  className="nav-link mr-0"
                                  id="nav-contact-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#nav-contact"
                                  type="button"
                                  role="tab"
                                  aria-controls="nav-contact"
                                  aria-selected="false"
                                >
                                  <b>Lifecycle Monitor</b>
                                </button>
                              </div>
                            </nav>
                            <div className="tab-content" id="nav-tabContent">
                              <div
                                className="tab-pane fade show active pt-2 editorviewtask commenteditor"
                                id="nav-home"
                                role="tabpanel"
                                aria-labelledby="nav-home-tab"
                              >
                                <TaskComments
                                  taskDetail={taskDetail}
                                  projectUser={projectUsers}
                                  progress={progress}
                                  setProgress={setProgress}
                                />
                              </div>
                              <div
                                className="tab-pane fade"
                                id="nav-profile"
                                role="tabpanel"
                                aria-labelledby="nav-profile-tab"
                              >
                                <TaskHistory
                                  taskHistory={taskHistory}
                                  isLoadingHistory={isLoadingHistory}
                                  projectUser={projectUsers}
                                  projectStatuses={projectDetails}
                                />
                              </div>
                              <div
                                className="tab-pane fade mt-3"
                                id="nav-contact"
                                role="tabpanel"
                                aria-labelledby="nav-contact-tab"
                              >
                                <TaskProgress
                                  progress={progress}
                                  status_list={projectDetails}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="resposive__col-4 col-md-4">
                    <div className="heading view-task">
                      <div
                        className="d-flex mt-2 hover_head"
                        onMouseOver={() => {
                          if (!isTaskNameEditing) setEditTitle(true);
                        }}
                        onMouseLeave={() => {
                          if (!isTaskNameEditing) setEditTitle(false);
                        }}
                      >
                        <div
                          className={`view_task_icon ${taskDetail?.task_type === "T" &&
                            taskDetail?.task_parent_id === null
                            ? "task-done-blue"
                            : taskDetail?.task_type === "B" &&
                              taskDetail?.task_parent_id === null
                              ? "bug"
                              : taskDetail?.task_type === "T" &&
                                taskDetail.task_parent_id !== null
                                ? "task-done-yellow"
                                : taskDetail?.task_type === "E"
                                  ? "userStory"
                                  : "bug"
                            }`}
                        ></div>
                        <h4
                          ref={headingRef}
                          contentEditable={isTaskNameEditing}
                          suppressContentEditableWarning={true}
                          onBlur={handleBlur}
                          onKeyDown={handleKeyDown}
                          className={`capitalize-first w_96 ${isTaskNameEditing ? "editable_heading" : ""
                            }`}
                        >
                          {prevTitle}
                          {editTitle && (
                            <>
                              <FontAwesomeIcon
                                icon={faPencil}
                                color="gray"
                                className="action_cursor_head ms-2 fs-16 top-96"
                                onClick={handleDoubleClick}
                              />
                              <FontAwesomeIcon
                                icon={faCopy}
                                color="gray"
                                className="action_cursor_head ms-2 fs-16 top-96 copy"
                                onClick={() =>
                                  handleCopyText(
                                    taskDetail.task_name,
                                    "Task Name"
                                  )
                                }
                              />
                            </>
                          )}
                        </h4>
                      </div>
                    </div>
                    <ViewTaskSidebar
                      taskDetail={taskDetail}
                      setTaskDetail={setTaskDetail}
                      projectUser={projectUsers}
                      status_list={projectDetails}
                      projectLabels={projectLabels}
                      projectSprints={projectSprints}
                      updateSubTask={props.updateSubTask}
                      setTaskProgress={setTaskProgress}
                      setProgress={setProgress}
                    />

                    {state.open && (
                      <AiModalWindow
                        state={state}
                        setState={setState}
                        setTaskDetail={setTaskDetail}
                        taskDetail={taskDetail}
                      />
                    )}
                  </div>
                </div>

                <FileViewBox
                  images={selectedImage}
                  isOpen={isOpenLightbox}
                  onClose={handleCloseLightbox}
                />
              </div>
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
}
export default TaskViewSideDrawer;
