import React from "react";
import { getStatusColor, getStatusColorStyle, getStatusName } from "../../../../Utils/helpers";

function TaskProgress(props) {
    const { progress, status_list } = props;
    const filteredProgress = progress?.filter(status => status_list?.some(item => item.id === status.id));
    return (
        <>
            {filteredProgress && (
                <>
                    {filteredProgress?.map((status) =>
                        <ProgressItem label={getStatusName(status.id, status_list)} count={status.count?.count || 0} color={getStatusColor(status.id, status_list)} />
                    )}
                </>
            )}
        </>
    );
}

const ProgressItem = ({ className, label, count, color }) => {
    return (
        <p className={`task_progress mt-2 ${className}`} style={getStatusColorStyle(color)} >
            {label}
            <span className="count float-end">
                {count}
            </span>
        </p>)
};

export default TaskProgress;
