import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, { useRef, useState } from "react";
import { Controller } from "react-hook-form";
import CustomAutoCompleteTag from "./CustomAutoCompleteTag";
import CustomAutoCompleteMenu from "./CustomAutoCompleteMenu";

function CustomUserAutoCompleteController(props) {
    const { control, fieldName, allUsers, placeholderText, isSingle } = props;
    const [open, setOpen] = useState(false);
    const textFieldRef = useRef(null);
    return (
        <Controller
            name={fieldName}
            className="inputSelect"
            control={control}
            defaultValue={[]}
            render={({ field: { onChange, value } }) => (
                <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={allUsers?.map((user) => ({
                        value: user.id,
                        label: user.name,
                        image: user.image,
                        profile_color: user.profile_color,
                    }))}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    value={value}
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    onChange={(event, newValue) => {
                        if (isSingle) {
                            onChange(newValue?.length > 0 ? [newValue[newValue?.length - 1]] : []);
                            setOpen(false);
                            if (textFieldRef.current) {
                                textFieldRef.current.blur();
                            }
                        } else {
                            onChange(newValue);
                        }
                    }}
                    isOptionEqualToValue={(option, value) => option?.value == value?.value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={value.length > 0 ? "" : placeholderText}
                            inputRef={textFieldRef}

                        />
                    )}
                    renderTags={(value, getTagProps) => {
                        return value?.map((option, index) => (
                            <CustomAutoCompleteTag
                                key={option.value}
                                option={option}
                                value={value}
                                onChange={onChange}
                                index={index}
                                getTagProps={getTagProps}
                                isUser={true}
                                isSingle={isSingle}
                            />
                        ));
                    }}
                    renderOption={(props, option, { selected }) => (
                        <CustomAutoCompleteMenu
                            option={option}
                            selected={selected}
                            value={value}
                            onChange={onChange}
                            props={props}
                            isUser={true}
                            isSingle={isSingle}
                        />
                    )}
                />
            )}
        />
    );
}

export default CustomUserAutoCompleteController;
